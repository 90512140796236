// @material-ui/icons
import Dashboard from '@material-ui/icons/Dashboard';
import Person from '@material-ui/icons/Person';
import Assignment from '@material-ui/icons/Assignment';
import ShoppingCart from '@material-ui/icons/ShoppingCart';
import ViewCarousel from '@material-ui/icons/ViewCarousel';
import Build from '@material-ui/icons/Build';
import Loyalty from '@material-ui/icons/Loyalty';

// core components/views for Admin layout
import AdminPage from '../pages/Admin';
import DashboardPage from '../pages/Dashboard';
import CategoriesPage from '../pages/Categories';
import CaracteristicasPage from '../pages/Caracteristicas';
import ModoPreparoPage from '../pages/ModoPreparo';

import TextsPage from '../pages/Texts';
import CustomersPage from '../pages/Customers';
import OrdersPage from '../pages/Orders';
import ProductsPage from '../pages/Products';
import EstoquePage from '../pages/Estoque';
import BannersPage from '../pages/Banners';
import ConfigurationsPage from '../pages/Configurations';
import PaymentFormPage from '../pages/PaymentForm';

import CouponsPage from '../pages/Coupons';
import VouchersPage from '../pages/Vouchers';

import StatesPage from '../pages/States';
import CitiesPage from '../pages/Cities';
import NeighborhoodsPage from '../pages/Neighborhoods';
import ZonesPage from '../pages/Zones';

import NoticiasPage from '../pages/Noticias';
import NoAvailabilityPage from '../pages/NoAvailability';
import PickupLocationPage from '../pages/PickupLocation';

import RelatorioProdutosVendidos from '../pages/Relatorio/ProdutosVendidos';
import RelatorioPedidosClientes from '../pages/Relatorio/PedidosClientes';
 
const menuRoutes = [
  {
    path: '/painel',
    name: 'Painel',
    icon: Dashboard,
    component: DashboardPage,
    layout: '/admin'
  },
  {
    path: '/administradores',
    name: 'Administradores',
    icon: Person,
    component: AdminPage,
    layout: '/admin',
  },
  {
    path: '/customers',
    name: 'Clientes',
    icon: Person,
    component: CustomersPage,
    layout: '/admin',
  },
  {
    path: '/orders',
    name: 'Pedidos',
    icon: ShoppingCart,
    component: OrdersPage,
    layout: '/admin',
  },

  {
    path: '/categories',
    name: 'Categorias',
    icon: Assignment,
    component: CategoriesPage,
    layout: '/admin',
  },
  {
    path: '/caracteristicas',
    name: 'Caracteristicas',
    icon: Assignment,
    component: CaracteristicasPage,
    layout: '/admin',
  },
  {
    path: '/modopreparo',
    name: 'Modo Preparo',
    icon: Assignment,
    component: ModoPreparoPage,
    layout: '/admin',
  },
  {
    path: '/products',
    name: 'Produtos',
    icon: Loyalty,
    component: ProductsPage,
    layout: '/admin',
  }, 

  {
    path: '/estoque',
    name: 'Estoque',
    icon:  Assignment,
    component: EstoquePage,
    layout: '/admin',
  }, 

  {
    path: '/banners',
    name: 'Banners Site',
    icon: ViewCarousel,
    component: BannersPage,
    layout: '/admin',
  },
  {
    path: '/blog',
    name: 'Blog',
    icon: Assignment,
    component: NoticiasPage,
    layout: '/admin',
  },
  {
    path: '/texts',
    name: 'Textos Site',
    icon: Assignment,
    component: TextsPage,
    layout: '/admin',
  },
  {
    path: '/states',
    name: 'Estados',
    icon: Assignment,
    component: StatesPage,
    layout: '/admin',
  },
  {
    path: '/cities',
    name: 'Cidades',
    icon: Assignment,
    component: CitiesPage,
    layout: '/admin',
  },
  {
    path: '/neighborhoods',
    name: 'Bairros',
    icon: Assignment,
    component: NeighborhoodsPage,
    layout: '/admin',
  },
  {
    path: '/zones',
    name: 'Zonas',
    icon: Assignment,
    component: ZonesPage,
    layout: '/admin',
  },

  {
    path: '/coupons',
    name: 'Cupons',
    icon: Loyalty,
    component: CouponsPage,
    layout: '/admin',
  },
  {
    path: '/vouchers',
    name: 'Vales Compra',
    icon: Loyalty,
    component: VouchersPage,
    layout: '/admin',
  },
  {
    path: '/noavailability',
    name: 'Sem Disponibilidade',
    icon: Assignment,
    component: NoAvailabilityPage,
    layout: '/admin',
  },
  {
    path: '/configurations',
    name: 'Configuracoes Gerais',
    icon: Build,
    component: ConfigurationsPage,
    layout: '/admin',
  }, 
  {
    path: '/paymentform',
    name: 'Formas de pagamento',
    icon: Assignment,
    component: PaymentFormPage,
    layout: '/admin',
  }, 
  {
    path: '/pickuplocation',
    name: 'Pontos para retirada',
    icon: Assignment,
    component: PickupLocationPage,
    layout: '/admin',
  }, 
  {
    path: '/relatorio_produtosvendidos',
    name: 'Produtos vendidos',
    icon: Assignment,
    component: RelatorioProdutosVendidos,
    layout: '/admin',
  }, 
  {
    path: '/relatorio_pedidosclientes',
    name: 'Pedidos clientes',
    icon: Assignment,
    component: RelatorioPedidosClientes,
    layout: '/admin',
  }, 

  
];

export default menuRoutes;
