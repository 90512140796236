import 'date-fns';
import React, { useState, useEffect, useCallback, useRef } from 'react';
import moment from 'moment';
import { setCookie, parseCookies } from 'nookies'
import { Link, useHistory } from 'react-router-dom';
import InputMask from "react-input-mask";
import { makeStyles } from '@material-ui/core/styles';
import { useToasts } from 'react-toast-notifications'
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'assets/css/react-confirm-alert.css'; // Import css
import Box from '@material-ui/core/Box';
import DateFnsUtils from '@date-io/date-fns';
import ptBRLocale from "date-fns/locale/pt-BR";
import {
  DatePicker,
  TimePicker,
  DateTimePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import { CircularProgress } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';

import OpcoesStatus from 'components/OpcoesStatus';
import OpcoesZonas from 'components/OpcoesZonas';
import OpcoesRetiradas from 'components/OpcoesRetiradas';

import styles from 'assets/jss/material-dashboard-react/views/dashboardStyle.js';
import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import Table from 'components/Table/Table.js';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';
import Card from 'components/Card/Card';
import Button from 'components/CustomButtons/Button.js';
import Checkbox from '@material-ui/core/Checkbox';
import OrderDetail from 'pages/Orders/detail';
import OrderNFCe from 'pages/Orders/nfce';
import soundEffect from '../../assets/audio/sirene.wav';
import api from '../../services/api';

const useStyles = makeStyles(styles);

export default function Orders() {

  let cookies = parseCookies();

  const classes = useStyles();
  const [loading, setLoading] = useState();
  const [data, setData] = useState([]);
  const [total, setTotal] = useState('R$ 0,00');
  const { addToast } = useToasts();
  const [name, setName] = useState('');
  const [largurapx, setLargurapx] = useState('400');
  const [email, setEmail] = useState('');
  const [couponCode, setCouponCode] = useState('');
  const [ordem, setOrdem] = useState('mais_recentes');
  const [order, setOrder] = useState(0);
  const [orderNFCe, setOrderNCFe] = useState(0);
  const [dataEntrega, setDataEntrega] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState([]);
  const [selectedDestino, setSelectedDestino] = useState(['']);
  const [selectedZones, setSelectedZones] = useState([]);
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [selected, setSelected] = useState([]);
  const [viewOrder, setViewOrder] = useState(false);
  const [viewOrderNFCe, setViewOrderNFCe] = useState(false);
  const [id, setId] = useState('');
  const [tocarSom, setTocarSom] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [seekValue, setSeekValue] = useState(0);
  const [update, setUpdate] = useState(false);
  const audioRef = useRef();

  const play = () => {
    audioRef.current.play();
  };

  const stop = () => {
    try {
      if (audioRef) {
        audioRef.current.pause();
        audioRef.current.currentTime = 0;
      }
    }
    catch { }
  };

  const onPlaying = () => {
    setCurrentTime(audioRef.current.currentTime);
    setSeekValue(
      (audioRef.current.currentTime / audioRef.current.duration) * 100
    );
  };


  async function getData() {
    try {

      setLoading(true);

      const filtros = {
        name,
        email,
        ordem: 'mais_recente',
        id,
        dataEntrega: dataEntrega ? moment(dataEntrega).format("YYYY-MM-DD") : null,
        pickupLocations: selectedLocations,
        zones: selectedZones,
        status: selectedStatus,
        destino: selectedDestino,
        couponCode
      };

      const orders = [];
      const response = await api.post("/orders/search", {
        filtros
      });

      let totalv = 0;
      let som = false;
      let ords = [];
      
      response.data.map(o => {


        if (o.som === 1) {
          som = true;
          ords.push(o.id);
        }
        orders.push({
          id: o.id,
          cliente: o.customer.name,
          status: o.status?.name,
          status_pg: o.status_pg,
          formapg: o.paymentform?.name,
          financeiro: o.financeiro,
          destino: o.destino === 'E' ? 'Entrega' : 'Retirada',
          valorpedido: new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          }).format(Number(o.total)),
          valordesconto: new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          }).format(Number(o.valordesconto)),
          total: new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          }).format(Number(o.total) + Number(o.freteValor) - Number(o.valordesconto)),
          frete: new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          }).format(o.freteValor),
          created_at: new Date(o.created_at).toLocaleDateString('pt-br', {
            day: '2-digit',
            month: 'long',
            year: 'numeric'
          })
        })

        totalv += Number(o.total) + Number(o.freteValor) - Number(o.valordesconto);
      })

      setData(orders);
      setTotal(new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      }).format(totalv));

      setLoading(false);

      await api.post("/orders/updateAlert", {
        ords
      });

      if (som) {
        setTocarSom(true);
      }

  
    }
    catch (e) {


      addToast('Ocorreu um erro para atualizar os dados', {
        appearance: 'error',
        autoDismiss: true,
      });

      setLoading(false);

    }
  }

  const handleDelete = useCallback(async (id) => {
    confirmAlert({
      title: 'Confirmação',
      message: 'Tem certeza que deseja remover este registro ?',
      buttons: [
        {
          label: 'Sim',
          onClick: () => postDeleteAction(id)
        },
        {
          label: 'Não'
        }
      ]
    });
  }, [name, email, ordem, id, selectedStatus, selectedLocations, selectedDestino, selectedZones]);

  const postDeleteAction = useCallback(async (id) => {
    try {
      await api.delete(`/orders/${id}`);
      getData();
    }
    catch (e) {
      addToast(e.response.data.message, {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  }, []);

  const handleSelectStatus = useCallback(async (status, event) => {
    if (event.target.checked) {
      setSelectedStatus([...selectedStatus,
        status
      ]);
    }
    else {
      setSelectedStatus(selectedStatus.filter(sl => sl !== status));
    }

  }, [selectedStatus]);

  const handleSelectDestino = useCallback(async (destino, event) => {
    if (event.target.checked) {
      setSelectedDestino([destino]);
    }
    else {
      setSelectedDestino(selectedDestino.filter(dt => dt !== destino));
    }
  }, [selectedDestino]);

  const handleSelectZones = useCallback(async (zone, event) => {

    if (event.target.checked) {
      setSelectedZones([...selectedZones,
        zone
      ]);
    }
    else {
      setSelectedZones(selectedZones.filter(zn => zn !== zone));
    }
  }, [selectedZones]);

  const handleSelectLocations = useCallback(async (location, event) => {
    if (event.target.checked) {
      setSelectedLocations([...selectedLocations,
        location
      ]);
    }
    else {
      setSelectedLocations(selectedLocations.filter(loc => loc !== location));
    }
  }, [selectedLocations]);

  const handleDataEntregaChange = (date) => {
    setDataEntrega(date);
  };

  const handleSelectedOrders = useCallback(async (order, event) => {
    if (event.target.checked) {
      setSelected([...selected,
        order
      ]);
    }
    else {
      setSelected(selected.filter(ord => ord !== order));
    }
  }, [selected]);

  const handleViewOrder = useCallback(async (order, event) => {
    setOrder(order);
  }, []);

  const handleGenerateNFCe = useCallback(async (order) => {
    setOrderNCFe(order);
  }, []);


  const print = useCallback(() => {
    window.open(`${process.env.REACT_APP_API_URL}/orders/print?ords=${JSON.stringify(selected)}&l=${largurapx}&a=${cookies['equilibrae.token']}`, '_blank');
  }, [largurapx, selected]);

  useEffect(() => {
    if (order !== 0) {
      setViewOrder(true);
    }
  }, [order])

  useEffect(() => {
    if (orderNFCe !== 0) {
      setViewOrderNFCe(true);
    }
  }, [orderNFCe])

  useEffect(() => {
    //getData();
  }, [selectedStatus, selectedLocations, selectedDestino, selectedZones]);

  useEffect(() => {
    if (tocarSom) {
      play();
      setTimeout(() => {
        stop();
      }, 3000);
    }
    setTocarSom(false);
  }, [tocarSom]);

  useEffect(() => {
    const update = setInterval(
      () => setUpdate(true),
      15000
    )
    return () => {
      clearInterval(update)
    }
  }, []);

  useEffect(() => {
    if (update) {
      getData();
      setUpdate(false);
    }
  }, [update])

  useEffect(() => {
    getData();
  }, []);

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="primary">
            <h4 className={classes.cardTitleWhite}>Pedidos</h4>
            <p className={classes.cardCategoryWhite}>
              Pedidos cadastrados no site.
            </p>
          </CardHeader>
          <CardBody>

            <h4 style={{ backgroundColor: '#fee', padding: 10 }}>Status de pedido</h4>
            <OpcoesStatus handleSelect={handleSelectStatus} selected={selectedStatus} />

            <h4 style={{ backgroundColor: '#fee', padding: 10 }}>Destino do pedido</h4>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <Checkbox name="Entrega" onChange={(e) => handleSelectDestino('E', e)} checked={selectedDestino.findIndex(sd => sd === 'E') !== -1} />Entrega
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <Checkbox name="Retirada" onChange={(e) => handleSelectDestino('R', e)} checked={selectedDestino.findIndex(sd => sd === 'R') !== -1} />Retirada
              </GridItem>
            </GridContainer>

            <h4 style={{ backgroundColor: '#fee', padding: 10 }}>Zonas / Regiões</h4>
            <OpcoesZonas handleSelect={handleSelectZones} selected={selectedZones} />

            <h4 style={{ backgroundColor: '#fee', padding: 10 }}>Local de Retirada</h4>
            <OpcoesRetiradas handleSelect={handleSelectLocations} selected={selectedLocations} />

            <h4 style={{ backgroundColor: '#fee', padding: 10 }}>Busca</h4>
            <GridContainer>

              <GridItem xs={12} sm={4} md={3}>
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBRLocale}>
                  <DatePicker style={{ marginTop: 15 }} label="Data entrega" value={dataEntrega} onChange={handleDataEntregaChange} format="dd/MM/yyyy" inputVariant="outlined" fullWidth />
                </MuiPickersUtilsProvider>
              </GridItem>

              <GridItem xs={12} sm={4} md={3}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="name"
                  label="Pesquise pelo número do pedido"
                  name="id"
                  value={id}
                  onChange={(e) => setId(e.target.value)}
                />
              </GridItem>


              <GridItem xs={12} sm={4} md={3}>

                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="name"
                  label="Pesquise pelo nome do cliente"
                  name="name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />

              </GridItem>

              <GridItem xs={12} sm={4} md={3}>

                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="name"
                  label="Pesquise pelo email do cliente"
                  name="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />

              </GridItem>

              <GridItem xs={12} sm={4} md={3}>

                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="coupon_code"
                  label="Pesquise pelo código do cupom"
                  name="coupon_code"
                  value={couponCode}
                  onChange={(e) => setCouponCode(e.target.value)}
                />

              </GridItem>
            </GridContainer>

            <audio ref={audioRef} id="audio" src={soundEffect} muted={false} onTimeUpdate={onPlaying} />

            <Button color="primary" type="button" style={{ marginTop: 20 }} onClick={() => getData()}>Filtrar</Button>

            <hr />

            <Link to="/admin/orders/edit?action=new"> <Button color="warning">Adicionar novo pedido</Button></Link>
            <hr />


            <GridContainer>
              <GridItem xs={12} sm={4} md={2}>
                <InputMask mask="9999" maskPlaceholder="" value={largurapx} onChange={(event) => setLargurapx(event.target.value)}>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    id="largurapx"
                    label="Largura em pixels"
                    name="largurapx"
                  />
                </InputMask>
              </GridItem>
              <GridItem xs={12} sm={4} md={3}>
                <Button color="info" type="button" style={{ marginTop: 22 }} onClick={() => print()}>Imprimir Selecionados</Button>
              </GridItem>
            </GridContainer>

             <>
              {data && data.length > 0 ? <Table
                tableHeaderColor="primary"
                tableHead={['', 'No pedido', 'Cliente', 'Destino Pedido', 'Status', 'Forma Pg', 'Valor Pedido', 'Valor Entrega', 'Desconto', 'Total', 'Cadastrado em', '', '']}
                tableColumn={['check', 'id', 'cliente', 'destino', 'status', 'formapg', 'valorpedido', 'frete', 'valordesconto', 'total', 'created_at', 'view', 'delete']}
                tableColumnSize={['1%', '5%', '10%', '10%', '10%', '10%', '10%', '10%', '10%', '10%', '1%', '1%']}
                tableData={data}
                resource="orders"
                onDelete={(id) => handleDelete(id)}
                onView={(id) => handleViewOrder(id)}
                onGenerateNFCe={(id) => handleGenerateNFCe(id)}
                handleSelect={handleSelectedOrders}
                selected={selected}
              /> : <p style={{ marginTop: 20 }}>Nenhum pedido cadastrado no momento.</p>}

              <h3>Total: {total}</h3>
            </>
          </CardBody>
        </Card>
      </GridItem>


      <OrderDetail open={viewOrder} order={order} handleCloseModal={() => { setOrder(0); setViewOrder(false); getData(); }} />
      <OrderNFCe open={viewOrderNFCe} order={orderNFCe} handleCloseModal={() => { setOrderNCFe(0); setViewOrderNFCe(false); }} />

    </GridContainer>
  );
}
