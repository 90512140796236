
import React, { useState, useEffect, useCallback } from 'react';
import 'date-fns';
import ptBRLocale from "date-fns/locale/pt-BR";
import * as moment from 'moment'
import { setCookie, parseCookies } from 'nookies'
import { makeStyles } from '@material-ui/core/styles';
import { useToasts } from 'react-toast-notifications'
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'assets/css/react-confirm-alert.css'; // Import css
import { CircularProgress } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import DateFnsUtils from '@date-io/date-fns';
import { Link } from 'react-router-dom';
import {
  DatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';

import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';

import styles from 'assets/jss/material-dashboard-react/views/dashboardStyle.js';
import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import Table from 'components/Table/Table.js';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';
import Card from 'components/Card/Card';
import Button from 'components/CustomButtons/Button.js';

import api from '../../services/api';

const useStyles = makeStyles(styles);

export default function Customers() {

  let cookies = parseCookies();
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const { addToast } = useToasts()
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [ordem, setOrdem] = useState('ordem_alfabetica');
  const [dataInicial, setDataInicial] = useState();
  const [dataFinal, setDataFinal] = useState(new Date());

  const [filtrarData, setFiltrarData] = useState(false);

  const handleDataInicialChange = (date) => {
    setDataInicial(date);
  };

  const handleDataFinalChange = (date) => {
    setDataFinal(date);
  };

  const handleFiltrarData = () => {
    setFiltrarData(prev => !prev);
  }

  useEffect(() => {
    getData();
  }, []);


  const getData = useCallback(async () => {
    try {
      setLoading(true);

      const response = await api.post("/customers/search", {
        filtros: {
          name,
          email,
          ordem,
          data_inicial: moment(dataInicial).format("YYYY-MM-DD"),
          data_final: moment(dataFinal).format("YYYY-MM-DD"),
          filtrarData
        }
      });

      const result = response.data;
      const customers = [];

      result.map(d => {
        customers.push({
          id: d.id,
          name: d.name,
          type: d.type,
          email: d.email,
          whatsapp: d.whatsapp,
          telefone: d.telefone,
          created_at: new Date(d.created_at).toLocaleDateString('pt-br', {
            day: '2-digit',
            month: 'long',
            year: 'numeric'
          })
        })
      })
      setData(customers);
      setLoading(false);
    }
    catch (e) {
      addToast('Ocorreu um erro para atualizar os dados', {
        appearance: 'error',
        autoDismiss: true,
      });
      setLoading(false);
    }
  }, [name, email, ordem, dataInicial, dataFinal, filtrarData]);

  const handleDelete = useCallback(async (id) => {

    confirmAlert({
      title: 'Confirmação',
      message: 'Tem certeza que deseja remover este registro ?',
      buttons: [
        {
          label: 'Sim',
          onClick: () => postDeleteAction(id)
        },
        {
          label: 'Não'
        }
      ]
    });

  }, []);

  const postDeleteAction = useCallback(async (id) => {
    try {
      await api.delete(`/customers/${id}`);
      setData(data => data.filter(d => d.id != id));
    }
    catch (e) {
      addToast(e.response.data.message, {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  }, []);

  const exportData = () => {
    window.open(`${process.env.REACT_APP_API_URL}/relatorio/clientes?dataInicial=${moment(dataInicial).format("YYYY-MM-DD")}&data_final=${moment(dataFinal).format("YYYY-MM-DD")}&filtrarData=${filtrarData}&name=${name}&email=${email}&a=${cookies['equilibrae.token']}`, '_blank');
  };


  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="primary">
            <h4 className={classes.cardTitleWhite}>Clientes</h4>
            <p className={classes.cardCategoryWhite}>
              Clientes cadastrados no site.
            </p>
          </CardHeader>
          <CardBody>

            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              id="name"
              label="Pesquise pelo nome"
              name="name"
              value={name}
              onChange={(e) => setName(e.target.value)}

            />

            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              id="name"
              label="Pesquise pelo email"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />


            <Checkbox checked={filtrarData}
              onChange={(event) => handleFiltrarData(data, event)} />Filtrar por data de cadastro


            {filtrarData && <>
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBRLocale}>
                <DatePicker style={{ marginTop: 15 }} label="Data Cadastro Inicial" value={dataInicial} onChange={handleDataInicialChange} format="dd/MM/yyyy" inputVariant="outlined" fullWidth />
              </MuiPickersUtilsProvider>

              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBRLocale}>
                <DatePicker style={{ marginTop: 25 }} label="Data Cadastro Final" value={dataFinal} onChange={handleDataFinalChange} format="dd/MM/yyyy" inputVariant="outlined" fullWidth />
              </MuiPickersUtilsProvider>
            </>}

            <FormControl fullWidth variant="outlined" className={classes.formControl} style={{ marginTop: 25 }}>
              <InputLabel id="ordem_label">Ordenar por:</InputLabel>
              <Select
                labelId="ordem"
                label="ordenar por:"
                variant="outlined"
                name="ordem"
                fullWidth
                value={ordem}
                onChange={(e) => setOrdem(e.target.value)}
              >
                <MenuItem value={"ordem_alfabetica"}>
                  <em>Ordem alfabética</em>
                </MenuItem>
                <MenuItem value={"mais_recentes"}>
                  <em>Mais recentes</em>
                </MenuItem>
                <MenuItem value={"mais_antigos"}>
                  <em>Mais antigos</em>
                </MenuItem>

              </Select>
            </FormControl>

            <Button color="primary" type="button" style={{ marginTop: 20 }} onClick={getData}>Filtrar</Button>

            <Button color="info" type="button" style={{ marginTop: 20 }} onClick={exportData}>Exportar dados</Button>

            <Link to="/admin/customers/new"> <Button color="warning" style={{ marginTop: 20 }}>Adicionar novo cliente</Button></Link>

            {loading ? (
              <Box mt={6}>
                <CircularProgress />
              </Box>
            ) : (
              <>
                {data && data.length > 0 ? <Table
                  tableHeaderColor="primary"
                  tableHead={['Nome', 'Tipo', 'Email', 'Whatsapp', 'Telefone', 'Cadastrado em ', '', '']}
                  tableColumn={['name', 'type', 'email', 'whatsapp', 'telefone', 'created_at', 'edit', 'delete']}
                  tableColumnSize={['30%', '10%', '20%', '15%', '20%', '15%', '1%', '1%']}
                  tableData={data}
                  resource="customers"
                  onDelete={(id) => handleDelete(id)} /> : <p style={{ marginTop: 20 }}>Nenhum cliente cadastrado no momento.</p>}
              </>
            )}
          </CardBody>
        </Card>
      </GridItem>

    </GridContainer>
  );
}
