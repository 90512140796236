import React, { useState, useRef, useEffect } from 'react';
import ReactCrop from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'
import { useToasts } from 'react-toast-notifications'
import Button from 'components/CustomButtons/Button.js';

export default function CropImage({width, height, imagem, onChange, name, reset, uploadImage}) {

   const fileInput = useRef();
   const { addToast } = useToasts();
   const [croppedImage, setCroppedImage] = useState();
   const [cropping, setCropping] = useState(false);
   const [crop, setCrop] = useState({
       width: width,
       height: height
   });
   const [croppedImageUrl, setCroppedImageUrl] = useState();
   const [src, setSrc] = useState();
   const [imageRef, setImageRef] = useState();

   useEffect(() => {
       if (imageRef && crop){       
           getCroppedImg(imageRef, crop);  
       }
   },[imageRef]);

   const handleFile = e => {  
       const fileReader = new FileReader()
       fileReader.onloadend = () => {                    
           setSrc(fileReader.result);          
       }   
       fileReader.readAsDataURL(e.target.files[0])
   }

   const onImageLoaded = image => {
    
       if (image.width < crop.width || image.height < crop.height){
           addToast(`A imagem deve possuir no minimo ${crop.width}px (largura) e ${crop.height}px (altura)`, {
            appearance: 'error',
            autoDismiss: true,
           });    
           
           setTimeout(() => {
             onCancelCrop();
           },1000);
           
       }      
       else{
          setImageRef(image);
          setCropping(true); 
       }
                 
   }

   const onCropChange = (crop) => {   
       setCropping(true); 
       setCrop(crop);       
   } 

   const onCropComplete = () => {
      if (imageRef && crop.width && crop.height) {
          getCroppedImg(imageRef, crop);         
      }      
   }

    const onCancelCrop = () => {
        fileInput.current.value = "";
        setSrc(null);
        setImageRef(null);    
        setCroppedImage(null);
        setCroppedImageUrl(null);
        onChange(null);
    }


    const onSaveCrop = () => {
       onCropComplete();
       onCancelCrop();
       setCropping(false);
    }

   const getCroppedImg = (image, crop) => {

 

        const canvas = document.createElement("canvas");
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext("2d");
        
        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height
        )

        const reader = new FileReader()
        canvas.toBlob(blob => {
            reader.readAsDataURL(blob)
            reader.onloadend = () => {
                dataURLtoFile(reader.result, name)
                setCroppedImageUrl(reader.result);
            }
        })

  }

  const dataURLtoFile = (dataurl, filename) => {

    let arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]), 
        n = bstr.length, 
        u8arr = new Uint8Array(n);
            
    while(n--){
        u8arr[n] = bstr.charCodeAt(n);
    }

    let croppedImage = new File([u8arr], filename, {type:mime});
    setCroppedImage(croppedImage) 
    onChange(croppedImage);

  }

   return (
      <>
      <input type='file' id='imagem' onChange={handleFile} ref={fileInput} reset={reset} /><br />
      
      {src && (
        <>
       
        <ReactCrop
          src={src}
          crop={crop}
          minWidth={width}
          minHeight={height}     
          onImageLoaded={onImageLoaded}
          onComplete={onCropComplete}
          onChange={onCropChange}
         /><br />

         {!uploadImage ? <Button color="warning" round onClick={() => onSaveCrop()}>Recortar</Button> : null}
         <Button color="danger" round onClick={() => onCancelCrop()}>Cancelar</Button>  
         {uploadImage ? <Button color="success" round onClick={() => uploadImage()}>Upload Imagem</Button> : null}   
        </>
      )}    
      
       {croppedImageUrl ? <><hr />Imagem: <br /><img src={croppedImageUrl} width="250" /></> : null}
      
       {imagem && !src && !croppedImageUrl ? <><br /><img src={`${imagem}`} width="250" /></> : null}
      
       <br />
       {croppedImageUrl && !src && uploadImage ? <Button color="success" round onClick={() => uploadImage()}>Upload Imagem</Button> : null}
      
      </>
   )
}