import 'date-fns';
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AutoComplete from 'components/AutoComplete/AutoComplete.js';
import FormControl from '@material-ui/core/FormControl';
import moment from 'moment';
import MenuItem from '@material-ui/core/MenuItem';
import ReactLoading from 'react-loading';
import Select from '@material-ui/core/Select';
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import InputLabel from '@material-ui/core/InputLabel';
import TableCell from "@material-ui/core/TableCell";

import { useParams, useHistory } from 'react-router-dom';
import { SubmitHandler, useForm } from 'react-hook-form'
import * as yup from 'yup';
import { useToasts } from 'react-toast-notifications'
import 'assets/css/react-confirm-alert.css'; // Import css
import api from '../../services/api';
import axios from 'axios';
import { v4 as uuidV4 } from "uuid";
import Button from 'components/CustomButtons/Button.js';
import InputMask from "react-input-mask";
import TextField from '@material-ui/core/TextField';
import styles from 'assets/jss/material-dashboard-react/views/dashboardStyle.js';
import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import CardHeader from 'components/Card/CardHeader';
import Agendamento from 'components/Agendamento';
import CardBody from 'components/Card/CardBody';
import Endereco from 'components/Endereco';
import Enderecos from 'components/Enderecos';
import EnderecosRetirada from 'components/EnderecosRetirada';
import Pagamento from 'components/Pagamento';

import Card from 'components/Card/Card';

const useStyles = makeStyles(styles);

export default function OrdersEdit() {
    const history = useHistory();

    const selectInputRef = useRef();
    const classes = useStyles();
    const { addToast } = useToasts();
    const [itens, setItens] = useState([]);
    const [total, setTotal] = useState(0);
    const [cart, setCart] = useState([]);
    const [zone, setZone] = useState();
    const [freteExpresso, setFreteExpresso] = useState(0);
    const [freteNormal, setFreteNormal] = useState(0);
    const [customer, setCustomer] = useState(null);
    const [product, setProduct] = useState(null);
    const [item, setItem] = useState(null);
    const [products, setProducts] = useState([]);
    const [step, setStep] = useState(0);
    const [subtotal, setSubtotal] = useState();
    const [loading, setLoading] = useState(false);
    const [localEntrega, setLocalEntrega] = useState();
    const [loadingDelete, setLoadingDelete] = useState(false);
    const [email, setEmail] = useState(null);
    const [session, setSession] = useState();
    const [diferencaFreteGratis, setDiferencaFreteGratis] = useState(0);
    const [qtde, setQtde] = useState("1");
    const [code, setCode] = useState('');
    const [cep, setCep] = useState('');
    const [enderecoSelecionado, setEnderecoSelecionado] = useState({
        id: 'new'
    });

    const [expressa, setExpressa] = useState(false);
    const [cupom, setCupom] = useState(0);
    const [configuration, setConfiguration] = useState();
    const [frete, setFrete] = useState(0);
    const [freteGratis, setFreteGratis] = useState(false);
    const [destino, setDestino] = useState('E');
    const [enderecos, setEnderecos] = useState([]);
    const [enderecosRetirada, setEnderecosRetirada] = useState([]);

    const { register, handleSubmit, formState, reset, setValue, getValues } = useForm({});

    const novoEndereco = useCallback(async () => {

        setStep(1);
        setEnderecoSelecionado({
            id: 'new'
        });
        setValue('identificacao', '');
        setValue('endereco', '');
        setValue('bairro', '');
        setValue('endereco', '');
        setValue('estado', '');
        setValue('cidade', '');
        setValue('numero', '');
        setValue('cep', '');
        setFrete(0);

    }, []);

    const handlePagamento = async (values) => {


        setLoading(true);

        let principal = false;

        try {

            const response = await api.post('/orders', {
                cep: values.cep,
                endereco: values.endereco,
                numero: values.numero,
                complemento: values.complemento,
                bairro: values.bairro,
                cidade: values.cidade,
                estado: values.estado,
                identificacao: values.identificacao,
                formapg: values.metodo,
                principal,
                customer: customer.id,
                session,
                addressId: enderecoSelecionado.id !== 'new' ? enderecoSelecionado.id : null,
                document: values.document,
                type: customer.type,
                telefone: customer.telefone,
                parcelas: 1,
                name: customer.name,
                email: customer.email,
                frete,
                zone,
                selectedDay: moment(values.selectedDay).format("YYYY-MM-DD"),
                selectedPeriod: values.selectedPeriod,
                expressa,
                destino,
                manual: true
            });


            if (response.data?.error) {
                alert(response.data.error);
                setLoading(false);
                return;
            }
            else {

                history.push('/admin/orders');
            }


            setLoading(false);


        }
        catch (e) {
            alert(e.response.data.message);
            setLoading(false);


        }

    };


    const addItem = useCallback(async () => {
        try {
            const selProduct = products.find(p => p.id === product);
            if (!selProduct) {
                addToast('Selecione o produto', {
                    appearance: 'error',
                    autoDismiss: true,
                });
                return false;
            }
            await api.post('cart', {
                id: uuidV4(),
                product: selProduct.id,
                qtde,
                value: selProduct.valor_por,
                session,
            });

            selectInputRef?.current?.select?.clearValue();
            loadCart();
        }
        catch (e) {
            console.log(e);
            addToast(e.response ? e.response.data.message : 'Ocorreu um erro !', {
                appearance: 'error',
                autoDismiss: true,
            });

        }
    }, [selectInputRef, product, qtde]);

    const getCustomer = useCallback(async () => {
        try {
            setStep(1);
            novoEndereco()
            const response = await api.post("/customers/search", {
                filtros: {
                    email,
                }
            });

            if (response.data.length < 1) {
                setCustomer();
                addToast('Cliente não encontrado', {
                    appearance: 'error',
                    autoDismiss: true,
                });
                return false;
            }

            const d = response.data[0];


            setCustomer({
                id: d.id,
                name: d.name,
                type: d.type,
                email: d.email,
                whatsapp: d.whatsapp,
                telefone: d.telefone,
                created_at: new Date(d.created_at).toLocaleDateString('pt-br', {
                    day: '2-digit',
                    month: 'long',
                    year: 'numeric'
                })
            })


        }
        catch (e) {
            setCustomer();
            addToast('Ocorreu um erro !', {
                appearance: 'error',
                autoDismiss: true,
            });
        }
    }, [email]);

    const loadProducts = useCallback(async () => {
        try {
            const products = [];

            const response = await api.post("/products/search", {
                filtros: {
                    ativo: true,
                    order: 'nome'
                }
            });


            if (response.data) {
                response.data.map(p => {
                    products.push({
                        id: p.id,
                        nome: p.nome,
                        codigo: p.codigo,
                        valor_por: p.valor_por
                    })
                })
            }

            setProducts(products);
        }
        catch (e) {

            addToast('Ocorreu um erro para atualizar os dados', {
                appearance: 'error',
                autoDismiss: true,
            });
        }
    }, []);

    const loadCart = useCallback(async () => {
        try {
            const response = await api.get('/cart', {
                params: {
                    session
                }
            });

            const cartItens = response.data;

            if (cartItens.length > 0) {
                cartItens.map(i => {

                    const image = i.product.images.find(p => p.destaque === true);

                    if (image) {
                        i.image = image.image_name;
                    }
                    else {
                        i.image = i.product.images.length > 0 ? i.product.images[0].image_name : null;
                    }

                    return i;
                })
            }

            setItens(cartItens);

            let totalCart = 0;

            cartItens.map(i => {
                i.subtotal = Intl.NumberFormat('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                }).format(Number(i.qtde * i.value));
                i.discountLabel = Intl.NumberFormat('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                }).format(Number(i.discount));

                totalCart += i.qtde * i.value;


            })

            if (totalCart > configuration.valor_frete_gratis) {
                setFreteGratis(true);
            }


            const coupon_type = cartItens[0]?.coupon?.coupon_type;
            const coupon_value = cartItens[0]?.coupon?.value;

            if (coupon_type && coupon_value) {
                let valorDesconto = 0;
                if (coupon_type === "P") {
                    valorDesconto = (totalCart * coupon_value) / 100;
                }
                else {
                    valorDesconto = coupon_value;
                }
                totalCart = totalCart - valorDesconto;
            }


            setTotal(totalCart);


        }
        catch { }
        finally {
            setLoading(false);
        }

    }, [session]);

    const handleDeleteProduto = useCallback(async (id) => {
        try {
            setLoadingDelete(true);
            setItem(id);
            await api.delete(`${process.env.REACT_APP_API_URL}/cart/${id}`, {});
            loadCart();
        }
        catch (e) {
            setLoadingDelete(false);
        }
    }, [session]);

    const handleSelectedDay = (day) => {

        const selected = moment(day).format("YYYY-MM-DD");
        const hoje = moment().format("YYYY-MM-DD");

        if (destino === "E") {
            if (selected === hoje) {
                setFrete(freteExpresso);
                setExpressa(true);
                setValue('selectedPeriod', '0#4');

            }
            else {
                setFrete(freteNormal);
                setExpressa(false);
            }
        }
        else {
            setFrete(0);
            if (selected === hoje) {
                setExpressa(true);
                setValue('selectedPeriod', '0#5');


            }
            else {
                setExpressa(false);
            }
        }

    }

    const handleChangeQty = useCallback(async (action, index, product) => {

        const itensCarrinho = [...itens];
        let step = 1;
        setItem(itensCarrinho[index].id);

        if (action === 'add') {

            setLoadingDelete(true);

            let qtdeAtual = itensCarrinho[index].qtde;
            let novaQtde = Number(qtdeAtual) + step;

            if (novaQtde > 999) {
                novaQtde = 999;
            }

            await api.post(`${process.env.REACT_APP_API_URL}/cart/${itensCarrinho[index].id}`, {
                action: "add",
                step,
                session
            }).catch(function (e) {
                setLoadingDelete(false);
                if (e.response) {
                    alert(e.response.data.message);
                }
            }).then(() => {
                loadCart();

            });


        }

        if (action === 'remove') {

            setLoadingDelete(true);
            let qtdeAtual = itensCarrinho[index].qtde;
            let novaQtde = qtdeAtual - step;
            if (novaQtde < step) {
                novaQtde = step;
            }

            await api.post(`${process.env.REACT_APP_API_URL}/cart/${itensCarrinho[index].id}`, {
                action: "subtract",
                step,
                session
            }).catch(e => {
                setLoadingDelete(false);
                if (e.response) {
                    alert(e.response.data.message);
                }
            }).then(() => {
                loadCart();

            });

        }



    }, [itens, session])

    const handleCupom = useCallback(async () => {

        try {

            await api.post(`/coupons/use`, {
                session,
                code,
                user: customer?.user?.id
            });

            addToast('Cupom aplicado com sucesso.', {
                appearance: 'success',
                autoDismiss: true,
            });

            setCode('');
            loadCart();


        }
        catch (e) {
            addToast(e.response?.data ? e.response.data.message : 'Ocorreu um erro', {
                appearance: 'error',
                autoDismiss: true,
            });
        }
    }, [itens, session, code]);

    const calculaTotal = useCallback(async () => {

        let subtotal = 0;

        itens.map(i => {
            subtotal += i.qtde * i.value;
        })

        setSubtotal(subtotal);

        if (configuration) {
            const dif = configuration.valor_frete_gratis - subtotal;

            if (dif > 0) {
                setDiferencaFreteGratis(
                    Intl.NumberFormat('pt-BR', {
                        style: 'currency',
                        currency: 'BRL',
                    }).format(Number(dif))
                );
            }
            else {
                setDiferencaFreteGratis(null);
            }

            if (subtotal >= configuration.valor_frete_gratis) {
                setFreteGratis(true);
                setFrete(0);
            }
            else {
                setFreteGratis(false);
                if (localEntrega) {
                    setFrete(Number(localEntrega.zone.valor));
                }
            }
        }
        setLoadingDelete(false);
        setItem(0);
        setCupom(0);

        const coupon_type = itens[0]?.coupon?.coupon_type;
        const coupon_value = itens[0]?.coupon?.value;

        if (coupon_type && coupon_value) {
            let valorDesconto = 0;
            if (coupon_type === "P") {
                valorDesconto = (subtotal * coupon_value) / 100;
            }
            else {
                valorDesconto = coupon_value;
            }
            setCupom(valorDesconto);
        }



    }, [itens, configuration])

    const loadConfiguration = async () => {
        try {
            const response = await api.get(`${process.env.REACT_APP_API_URL}/configurations/098b1f16-0b1e-4073-9772-1acb31167767`);
            setConfiguration(response.data);
        }
        catch (e) {

        }
    }

    const loadEnderecos = useCallback(async () => {

        try {

            const enderecosCadastrados = await api.get(`/customers_address/${customer.id}`);
            setEnderecos(enderecosCadastrados.data);

            const enderecoPadrao = enderecosCadastrados.data.find(a => a.principal === true);
            if (enderecoPadrao) {
                selecionaEndereco(enderecoPadrao);
                setTotalEnderecosRegistrados(enderecosCadastrados.data.length);
            }

        }
        catch (e) { }

    }, [customer]);


    const loadEnderecosRetirada = useCallback(async () => {

        try {
            const enderecosRetiradaCadastrados = await api.get(`/orders/pickuplocation`);
            setEnderecosRetirada(enderecosRetiradaCadastrados.data);
        }
        catch (e) { }

    }, []);

    const handleAgendamento = async (values) => {

        if (destino === 'R' || (destino === 'E' && values.selectedPeriod)) {
            setStep(3);
        }
        else {

            addToast('Informe uma data e periodo para entrega.', {
                appearance: 'error',
                autoDismiss: true,
            });
        }

    };

    const verifyAvailability = useCallback(async (e) => {

        try {

            e.preventDefault();

            const identificacao = e.target.identificacao.value;
            const cep = e.target.cep.value;
            const endereco = e.target.endereco.value;
            const bairro = e.target.bairro.value;
            const cidade = e.target.cidade.value;
            const estado = e.target.estado.value;

            const schema = yup.object().shape({
                identificacao: yup.string().required('Identificação é obrigatória'),
                cep: yup.string().required('Cep é obrigatório'),
                endereco: yup.string().required('Endereço é obrigatório'),
                bairro: yup.string().required('Bairro é obrigatório'),
                cidade: yup.string().required('Cidade é obrigatório'),
                estado: yup.string().required('Estado é obrigatório'),
            });

            await schema.validate({ identificacao, cep, endereco, bairro, cidade, estado }, {
                abortEarly: false
            });

            const cepConsulta = cep.replace(/\D/g, "");
            const cepData = await axios.get(`https://viacep.com.br/ws/${cepConsulta}/json`);

            if (cepData.data.erro) {
                addToast('Cep invalido', {
                    appearance: 'error',
                    autoDismiss: true,
                });
            }

            if (cepData.data) {

                const { logradouro, bairro, localidade, uf } = cepData.data

                const response = await api.post('/shipping/availability', {
                    logradouro,
                    bairro,
                    localidade,
                    uf,
                    cep,
                });

                if (!response.data.availability) {

                    addToast('Sem disponibibilidade para o endereco informado.', {
                        appearance: 'error',
                        autoDismiss: true,
                    });

                }
                else {

                    setZone(response.data.zone);

                    if (freteGratis) {
                        setFreteExpresso(0);
                        setFreteNormal(0);
                        setFrete(0);
                    }
                    else {
                        setFreteExpresso(Number(response.data.zone.valorExpresso));
                        setFreteNormal(Number(response.data.zone.valor));
                        setFrete(Number(response.data.zone.valor));
                    }

                    const end = {
                        id: 'new',
                        identificacao,
                        endereco,
                        bairro,
                        cidade,
                        estado,
                        cep,
                        zone: response.data.zone
                    }

                    if (enderecoSelecionado) {
                        end.id = enderecoSelecionado.id;
                    }

                    setEnderecoSelecionado(end);
                    setStep(2);
                }

            }
        }
        catch (e) {
            console.log(e);
        }

    }, [cep, enderecoSelecionado]);


    const selecionaEndereco = (address) => {
        setStep(1);
        setValue('bairro', address.bairro);
        setValue('complemento', address.complemento);
        setValue('numero', address.numero);
        setValue('endereco', address.endereco);
        setValue('cidade', address.cidade);
        setValue('estado', address.estado);
        setValue('identificacao', address.identificacao);
        setValue('cep', address.cep);
        setCep(address.cep);
        setEnderecoSelecionado(address);
        setValue('selectedDay', null);
        setValue('selectedPeriod', null);
    }

    const selecionaEnderecoRetirada = (address) => {
        setValue('selectedDay', null);
        setValue('selectedPeriod', null);
        setEnderecoSelecionado(address);
        setZone(address.zone.id);
        setFrete(0);
        setStep(2);
    }


    useEffect(() => {
        loadProducts();
        loadConfiguration();
        setSession(uuidV4());
    }, []);

    useEffect(() => {
        calculaTotal();
        if (itens.length > 0) {
            setStep(1);
        }
        else {
            setStep(0);
        }
    }, [itens]);

    useEffect(() => {
        if (customer) {
            loadEnderecos();
            loadEnderecosRetirada();
        }
        else {
            setEnderecos([]);
            setEnderecosRetirada([]);
        }
    }, [customer])

    return (
        <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
                <Card>
                    <CardHeader color="primary">
                        <h4 className={classes.cardTitleWhite}>Pedidos</h4>
                        <p className={classes.cardCategoryWhite}>
                            Cadastrar novo pedido.
                        </p>
                    </CardHeader>
                    <CardBody>

                        <TextField
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            id="name"
                            label="Email do cliente"
                            name="email do cliente"
                            onChange={(e) => setEmail(e.target.value)}
                        />

                        <Button color="warning" type="button" style={{ marginTop: 20 }} onClick={() => getCustomer()}>Buscar</Button>


                        {customer && <>
                            <hr />
                            <p><strong>Cliente: </strong>{customer.name}</p>
                            <p><strong>Email: </strong>{customer.email}</p>
                            <p><strong>Telefone: </strong>{customer.telefone}</p>
                            <p><strong>Whatsapp: </strong>{customer.whatsapp}</p>
                            <hr />


                            <p><strong>ITENS DO PEDIDO</strong></p>
                            {itens && itens.length > 0 && <div className="table-content table-responsive">
                                <Table className={classes.table}>
                                    <TableHead>
                                        <TableRow className={classes.tableHeadRow}>
                                            <TableCell></TableCell>
                                            <TableCell>Produto</TableCell>
                                            <TableCell>Qtde</TableCell>
                                            <TableCell>Valor un.</TableCell>
                                            <TableCell>Valor Total</TableCell>
                                            <TableCell>&nbsp;</TableCell>
                                        </TableRow>
                                    </TableHead>

                                    {itens && itens.map((i, index) => {

                                        let qtde = Number(i.qtde);

                                        return (
                                            <TableRow className={classes.tableHeadRow}>
                                                <TableCell>
                                                    <a href={`/produto/detalhe/${i.product.id}`} >
                                                        {i.image ? <img className="img-fluid" style={{ width: 80 }} src={`https://api.equilibrae.com.br/products/rs/${i.image}`} /> : null}

                                                    </a>
                                                </TableCell>
                                                <TableCell>
                                                    <span className="nome">{i.product.nome}</span>
                                                    <div className="text-left align-middle kit_itens">
                                                        {i.kit_itens && i.kit_itens.map(kt => {
                                                            return (
                                                                <div style={{ fontSize: 10, color: '#818181' }}>{kt.product.nome} - Qtd: {kt.quantidade}</div>
                                                            )
                                                        })}
                                                    </div>
                                                </TableCell>


                                                <TableCell>
                                                    {loadingDelete && item === i.id ? <div className="cart-plus-minus" style={{ margin: '0 auto', padding: '0px 40px' }}><ReactLoading type={'spin'} color={'#000000'} height={20} width={20} /></div> : <div className="cart-plus-minus" style={{ margin: '0 auto' }}>

                                                        <button type="button" style={{ padding: 5 }} className="dec qtybutton" onClick={() => handleChangeQty('remove', index, i.product)} ><strong>-</strong></button>
                                                        <input className="cart-plus-minus-box" step="3" min="3" max="999" type="number" value={qtde} />
                                                        <button type="button" style={{ padding: 5 }} className="inc qtybutton" onClick={() => handleChangeQty('add', index, i.product)}><strong>+</strong></button>
                                                    </div>}
                                                </TableCell>

                                                <TableCell>
                                                    <span className="amount">{Intl.NumberFormat('pt-BR', {
                                                        style: 'currency',
                                                        currency: 'BRL',
                                                    }).format(Number(i.value))}</span>
                                                </TableCell>

                                                <TableCell>
                                                    <span className="amount">{Intl.NumberFormat('pt-BR', {
                                                        style: 'currency',
                                                        currency: 'BRL',
                                                    }).format(Number(i.value * i.qtde))}</span>
                                                </TableCell>

                                                <TableCell>
                                                    {loadingDelete && item === i.id ? <ReactLoading type={'spin'} color={'#000000'} height={20} width={20} /> : <a href="#" onClick={() => handleDeleteProduto(i.id)}>
                                                        Remover
                                                    </a>}
                                                </TableCell>
                                            </TableRow>
                                        )
                                    }
                                    )}
                                </Table>
                                <br />
                                <p><span className="label">Total em Produtos</span> -
                                    <span className="value">{Intl.NumberFormat('pt-BR', {
                                        style: 'currency',
                                        currency: 'BRL',
                                    }).format(Number(subtotal))}</span>
                                </p>

                                <p>
                                    <span className="label">Cupom Aplicado</span> -
                                    <span className="value" style={{ color: '#ff0000' }}> {Intl.NumberFormat('pt-BR', {
                                        style: 'currency',
                                        currency: 'BRL',
                                    }).format(Number(cupom))}</span>
                                </p>

                                <p>
                                    <span className="label">Total - </span>
                                    <span className="value">{Intl.NumberFormat('pt-BR', {
                                        style: 'currency',
                                        currency: 'BRL',
                                    }).format(Number(subtotal) + Number(frete) - Number(cupom))}</span>
                                </p>

                            </div>}

                            {itens.length === 0 && <p>Seu pedido esta vazio.</p>}
                            <hr />

                            <p><strong>Adicionar Item:</strong></p>
                            <FormControl fullWidth variant="outlined" className={classes.formControl} style={{ marginTop: 18 }}>
                                <AutoComplete myRef={selectInputRef} placeholder="Selecione o produto" resource="products/search" handleChange={e => setProduct(e?.id)} />
                            </FormControl>


                            <InputMask mask="9999" maskPlaceholder="" value={qtde} onChange={(event) => setQtde(event.target.value)}>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    id="qtde"
                                    label="Qtde"
                                    name="qtde"
                                />
                            </InputMask>


                            <Button color="warning" type="button" style={{ marginTop: 20 }} onClick={() => addItem()}>ADICIONAR ITEM</Button>

                            <hr />
                            <p>Adicionar Cupom</p>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                id="code"
                                label="Codigo do Cupom"
                                name="code"
                                value={code}
                                onChange={(e) => setCode(e.target.value)}
                            />
                            <Button color="success" type="button" style={{ marginTop: 20 }} onClick={() => handleCupom()}>ADICIONAR CUPOM</Button>


                            <hr />

                            {step >= 1 ? <>
                                <FormControl fullWidth variant="outlined" className={classes.formControl}>
                                    <InputLabel id="type">Destino do pedido</InputLabel>
                                    <Select
                                        labelId="destino"
                                        variant="outlined"
                                        name="destino"
                                        label="Destino do Pedido"
                                        fullWidth
                                        value={destino}
                                        onChange={(e) => setDestino(e.target.value)}

                                    >
                                        <MenuItem key="E" value="E">Entrega do pedido</MenuItem>
                                        <MenuItem key="R" value="R">Retirada</MenuItem>
                                    </Select>
                                </FormControl>

                                {destino === 'E' && <>
                                    <Enderecos enderecos={enderecos} handleSelecionaEndereco={selecionaEndereco} />
                                    <br />
                                    <form onSubmit={verifyAvailability} method="POST" >
                                        <br />
                                        {enderecoSelecionado.id !== 'new' ? <><a href="#" onClick={() => novoEndereco()}>Cadastrar novo endereco</a><br /><br /></> : null}
                                        <Endereco setValue={setValue} register={register} cep={cep} setCep={setCep} />
                                    </form>
                                </>}

                                {destino === 'R' && <>
                                    <EnderecosRetirada enderecos={enderecosRetirada} handleSelecionaEndereco={selecionaEnderecoRetirada} />
                                </>
                                }
                            </> : null}

                            {step >= 2 && enderecoSelecionado && enderecoSelecionado !== 'new' && <form onSubmit={handleSubmit(handleAgendamento)} method="POST" >
                                <Agendamento setValue={setValue} register={register} zone={enderecoSelecionado?.zone?.id} handleStep={(step) => setStep(step)} customer_id={customer.id} session={session} getValues={getValues} total={total} valor={frete} destino={destino} handleSelectedDay={handleSelectedDay} expressa={expressa} />
                            </form>}

                            {step === 3 ? <>
                                <form onSubmit={handleSubmit(handlePagamento)} method="POST">
                                    <Pagamento session={session} setValue={setValue} getValues={getValues} register={register} conf={configuration} total={total} frete={frete} loading={loading} setStep={setStep} />
                                </form>
                            </> : null}
                        </>}

                    </CardBody>
                </Card>
            </GridItem >

        </GridContainer >
    );
}
