import React, { useEffect, useCallback, useState, useRef } from 'react';
import * as Yup from 'yup';
import { useToasts } from 'react-toast-notifications'
import CurrencyTextField from '@unicef/material-ui-currency-textfield'
import { useParams, useHistory } from 'react-router-dom';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';

import { CircularProgress } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';

import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';


// core components

import styles from 'assets/jss/material-dashboard-react/views/dashboardStyle.js';
import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import Button from 'components/CustomButtons/Button.js';
import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardBody from 'components/Card/CardBody.js';
import CardFooter from 'components/Card/CardFooter.js';

import api from '../../services/api';
import getValidationErrors from '../../utils/getValidationErrors';

const useStyles = makeStyles(styles);

export default function EditVouchersPage() {
  const [action, setAction] = useState('create');
  const [isLoading, setLoading] = useState(true);
  const [loaded, setLoaded] = useState(false);
  const [code, setCode] = useState('');
  const [value, setValue] = useState();
  const [pontos, setPontos] = useState('');
  const [ativo, setAtivo] = useState(true);

  const formRef = useRef(null);
  const history = useHistory();
  const { addToast } = useToasts()

  const { id } = useParams();
  const classes = useStyles();

  useEffect(() => {
    if (id != "new") {
      setAction("update");
    }
  }, []);

  useEffect(() => {
    if (action === "update") {
      loadData(id);
    }
    else {
      setLoaded(true);
      setLoading(false);
    }
  }, [action]);

  useEffect(() => {
  }, []);


  const handleSave = useCallback(async (e) => {

    try {

      e.preventDefault();

      const code = e.target.code.value;
      const value = e.target.value.value;
      const pontos = e.target.pontos.value;


      const schema = Yup.object().shape({
        code: Yup.string().required('Código do produto é obrigatório'),
        value: Yup.string().required('Informe o valor'),
        pontos: Yup.string().required('Informe a qtde de pontos'),
      });


      await schema.validate({ code, value, ativo, pontos }, {
        abortEarly: false,
      });

      const postData = {
        code,
        pontos,
        value: value.replace(",", "."),
        ativo
      };


      if (action === 'update') {
        postData.id = id;
      }

      await api.post(`/vouchers`, postData);

      history.push('/admin/vouchers');

    }
    catch (e) {

      setLoading(false);

      if (e instanceof Yup.ValidationError) {
        const errors = getValidationErrors(e);

        for (var [, value] of Object.entries(errors)) {
          addToast(value, {
            appearance: 'error',
            autoDismiss: true,
          });
        }
        return;
      }

      addToast(e.response ? e.response.data.message : 'Ocorreu um erro !', {
        appearance: 'error',
        autoDismiss: true,
      });


    }
  }, [action, ativo]);

  const loadData = useCallback(async () => {

    try {

      const response = await api.get(`/vouchers/${id}`);
      setCode(response.data.code);
      setValue(response.data.value);
      setAtivo(response.data.ativo);
      setPontos(response.data.pontos);

      setLoaded(true);
      setLoading(false);

    }
    catch (e) {


      addToast('Ocorreu um erro !', {
        appearance: 'error',
        autoDismiss: true,
      });
      history.push("/admin/vouchers");

    }
  });


  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>

            <form className={classes.form} ref={formRef} onSubmit={handleSave}>
              <CardHeader color="primary">
                <h4 className={classes.cardTitleWhite}>Dados do vale compra</h4>
                <p className={classes.cardCategoryWhite}>Complete os dados.</p>
              </CardHeader>
              <CardBody>

                {loaded ?
                  <>
                    <TextField
                      variant="outlined"
                      fullWidth
                      id="code"
                      label="Código"
                      name="code"
                      value={code}
                      required
                      onChange={(e) => setCode(e.target.value.toUpperCase())}
                    />

                    <TextField
                      style={{ marginTop: 25 }}
                      variant="outlined"
                      fullWidth
                      id="pontos"
                      label="Pontos"
                      name="pontos"
                      value={pontos}
                      required
                      onChange={(e) => setPontos(e.target.value)}
                    />

                    <CurrencyTextField
                      style={{ marginTop: 25 }}
                      label="Valor"
                      variant="standard"
                      value={value}
                      currencySymbol=""
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      textAlign="left"
                      outputFormat="string"
                      decimalCharacter=","
                      digitGroupSeparator=""
                      name="value"
                      onChange={(event, value) => setValue(value)}
                    />

                    <FormControl fullWidth variant="outlined" className={classes.formControl} style={{ marginTop: 18 }}>
                      <InputLabel id="category">Ativo ?</InputLabel>
                      <Select
                        labelId="ativo"
                        label="ativo no site"
                        variant="outlined"
                        name="ativo"
                        fullWidth
                        value={ativo}
                        onChange={(e) => setAtivo(e.target.value)}
                      >
                        <MenuItem key="A1" value={true}>Sim</MenuItem>
                        <MenuItem key="A2" value={false}>Não</MenuItem>
                      </Select>
                    </FormControl>



                  </> : isLoading ? (
                    <Box mt={6}>
                      <CircularProgress />
                    </Box>
                  ) : null}

              </CardBody>
              {loaded ? <CardFooter>
                <Button color="primary" type="submit">Salvar</Button>
              </CardFooter> : null}
            </form>

          </Card>
        </GridItem>

      </GridContainer>
    </div>
  );
}
