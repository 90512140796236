import React, { useEffect, useState, useCallback } from 'react';
import ReactLoading from "react-loading";

import Button from 'components/CustomButtons/Button.js';

import api from '../../services/api';

export default function Pagamento({ session, setValue, getValues, register, conf, total, frete, loading, setStep }) {

  const [formaspg, setFormaspg] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [formapg, setFormapg] = useState('b725b98b-8a20-4892-aa21-b5fc24e37dae');

  useEffect(() => {
    getFormasPg();
  }, []);

  const getFormasPg = useCallback(async () => {
    try {
      const response = await api.get("/orders/paymentform");
      setFormaspg(response.data);
      setLoaded(true);
    }
    catch (e) {
      console.log(e);
      setLoaded(false);

    }
  }, []);



  if (!loaded) {
    return (
      <div><ReactLoading type={'spin'} color={'#000000'} height={50} width={50} /></div>
    )
  }


  return (
    <>
      <div className="row justify-content-between align-items-center pagamento">
        <br />
        <h4 className="mb-3">Forma de Pagamento</h4>
        <br />
        <div className="my-3">
          {formaspg && formaspg.map(f => {
            return (
              <div className="form-check">
                <input id="credit" name="metodo" value={f.id} onClick={() => { setFormapg(f.id); }} type="radio" className="form-check-input" required {...register(`metodo`)} checked={formapg === f.id} />
                <label htmlFor="credit" className="form-check-label"><span style={{ fontSize: 18 }}> {f.name}</span>

                </label><br /><br />

              </div>
            )
          })}

          <Button color="success" type="submit" style={{ marginTop: 20 }}>CADASTRAR PEDIDO</Button>
        </div>





      </div>

    </>
  )

}
