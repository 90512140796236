import 'date-fns';
import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';


import { makeStyles } from '@material-ui/core/styles';
import { useToasts } from 'react-toast-notifications'
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'assets/css/react-confirm-alert.css'; // Import css
import { CircularProgress } from '@material-ui/core';
import Box from '@material-ui/core/Box';


import styles from 'assets/jss/material-dashboard-react/views/dashboardStyle.js';
import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import Table from 'components/Table/Table.js';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';
import Card from 'components/Card/Card';
import Button from 'components/CustomButtons/Button.js';

import api from '../../services/api';

const useStyles = makeStyles(styles);

export default function Noticias() {
  const classes = useStyles();
  const [loading, setLoading] = useState();
  const [data, setData] = useState([]);
  const { addToast } = useToasts();

  useEffect(() => {
      getData();
  }, []);


  const getData = useCallback(async () => {
    try {
      setLoading(true);

      const noticias = [];
      const response = await api.get("/noticias");
      
       response.data.map(n => {
        noticias.push({
          id: n.id,
          titulo: n.titulo,
          descricao: n.descricao,
          created_at:  new Date(n.created_at).toLocaleDateString('pt-br', {
            day: '2-digit',
            month: 'long',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit'
           })
        })       
        
      })
  
      setData(noticias);
      setLoading(false);

    }
    catch(e){
      addToast('Ocorreu um erro para atualizar os dados', {
        appearance: 'error',
        autoDismiss: true,
      });      
      
      setLoading(false);

    } 
  },[]);

  const handleDelete = useCallback(async (id) => {

    confirmAlert({
      title: 'Confirmação',
      message: 'Tem certeza que deseja remover este registro ?',
      buttons: [
        {
          label: 'Sim',
          onClick: () => postDeleteAction(id)
        },
        {
          label: 'Não'         
        }
      ]
    });

  },[]);

  const postDeleteAction =  useCallback(async (id) => {
      try {
          await api.delete(`/noticias/${id}`);
          getData();
      }
      catch(e){
        addToast(e.response.data.message, {
          appearance: 'error',
          autoDismiss: true,
        });        
      }
  },[]);

  
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card> 
          <CardHeader color="primary">
            <h4 className={classes.cardTitleWhite}>Blog</h4>
            <p className={classes.cardCategoryWhite}>
              Noticias cadastrados no site.
            </p>
          </CardHeader>
          <CardBody>

          <Link to="/admin/blog/new"> <Button color="info" style={{marginTop: 20}}>Adicionar nova noticia</Button></Link>
 

            {loading ? (
            <Box mt={6}>
             <CircularProgress />
            </Box>               
            ) : ( 
             <>     
              {data && data.length > 0 ? <Table
                  tableHeaderColor="primary"
                  tableHead={['Titulo', 'Cadastrado em', '', '']}
                  tableColumn={['titulo', 'created_at', 'edit', 'delete']}
                  tableColumnSize={['60%', '20%', '1%', '1%']}
                  tableData={data} 
                  resource="blog"            
                  onDelete={(id) => handleDelete(id)}              
              /> : <p style={{marginTop: 20}}>Nenhuma noticia cadastrada no momento.</p>}

             </>
            )}
          </CardBody>
        </Card>
      </GridItem>

    </GridContainer>
  );
}
