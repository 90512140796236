import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Link } from 'react-router-dom';
import 'date-fns';
import * as moment from 'moment'
import ptBRLocale from "date-fns/locale/pt-BR";

import { setCookie, parseCookies } from 'nookies'
import { makeStyles } from '@material-ui/core/styles';
import { useToasts } from 'react-toast-notifications'
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'assets/css/react-confirm-alert.css'; // Import css
import { CircularProgress } from '@material-ui/core';
import Box from '@material-ui/core/Box';

import DateFnsUtils from '@date-io/date-fns';
import enLocale from "date-fns/locale/en-US";
import {
  DatePicker,
  TimePicker,
  DateTimePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';


import styles from 'assets/jss/material-dashboard-react/views/dashboardStyle.js';
import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import Table from 'components/Table/Table.js';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';
import Card from 'components/Card/Card';
import Button from 'components/CustomButtons/Button.js';
import AutoComplete from 'components/AutoComplete/AutoComplete.js';

import FormControl from '@material-ui/core/FormControl';

import api from '../../services/api';

const useStyles = makeStyles(styles);

export default function Estoque() {


  let cookies = parseCookies();

  const selectInputRef = useRef();
  const classes = useStyles();
  const [isLoading, setLoading] = useState(true);
  const [itens, setItens] = useState([]);
  const [posicao, setPosicao] = useState();
  const [product, setProduct] = useState('');
  const [since, setSince] = useState(new Date(new Date() - (20 * 86400000)));
  const { addToast } = useToasts()

  useEffect(() => {
    getData();
  }, []);


  const handleSinceChange = (date) => {
    setSince(date);
  };

  const getData = useCallback(async () => {
    try {

      setLoading(true);

      const response = await api.get("/products/stock/history", {
        params: {
          product_id: product ? product.id : '',
          since,
        }
      });


      selectInputRef.current.select.clearValue();

      const itensmov = [];

      if (response.data.posicao) {
        setPosicao(response.data.posicao);
      }
      else {
        setPosicao(null);
      }

      response.data.movimentacao.map(d => {


        itensmov.push({
          data: new Date(d.created).toLocaleDateString('pt-br', {
            day: '2-digit',
            month: 'long',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit'
          }),
          name: d.product.nome,
          acao: d.tipo_nome,
          order: d.order_id,
          quantidade: d.tipo === 1 ? d.quantidade_entrada : d.quantidade_saida,
          custo: new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          }).format(d.custo),
        })
      })
      setItens(itensmov);
      setLoading(false);
    }
    catch (e) {
      addToast('Ocorreu um erro para atualizar os dados', {
        appearance: 'error',
        autoDismiss: true,
      });
      setLoading(false);
    }
  }, [product, since]);

  const handleDelete = useCallback(async (id) => {
    confirmAlert({
      title: 'Confirmação',
      message: 'Tem certeza que deseja remover este registro ?',
      buttons: [
        {
          label: 'Sim',
          onClick: () => postDeleteAction(id)
        },
        {
          label: 'Não',
          onClick: () => { }
        }
      ]
    });
  }, []);

  const postDeleteAction = useCallback(async (id) => {
    try {
      await api.delete(`/modopreparo/${id}`);
      setData(data => data.filter(d => d.id != id));
    }
    catch (e) {
      addToast(e.response.data.message, {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  }, []);



  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="primary">
            <h4 className={classes.cardTitleWhite}>Movimentação de Estoque</h4>
            <p className={classes.cardCategoryWhite}>
              Histórico de movimentações de estoque
            </p>
          </CardHeader>
          <CardBody>

            <FormControl fullWidth variant="outlined" className={classes.formControl} style={{ marginTop: 18 }}>
              <AutoComplete myRef={selectInputRef} placeholder="Selecione o produto" resource="products/search" handleChange={e => setProduct(e)} />
            </FormControl>

            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBRLocale}>
              <DatePicker style={{ marginTop: 15 }} label="Movimentacao a partir de" value={since} onChange={handleSinceChange} format="dd/MM/yyyy" inputVariant="outlined" fullWidth />
            </MuiPickersUtilsProvider>

            <Button color="warning" style={{ marginTop: 20 }} onClick={() => getData()}>Buscar</Button>

            <Link to="/admin/estoque/new"> <Button color="info" style={{ marginTop: 20 }}>Movimentar estoque</Button></Link>

            <a href={`${process.env.REACT_APP_API_URL}/relatorio/estoque?since=${moment(since).format("YYYY-MM-DD")}&product_id=${product ? product.id : ''}&a=${cookies['equilibrae.token']}`}> <Button color="success" style={{ marginTop: 20 }}>Exportar planilha estoque</Button></a>

            <hr />
            {posicao && itens ? <h4>Posição atual do item <strong>{itens[0].name}</strong>: {posicao.quantidade}</h4> : null}
            {itens && itens.length > 0 ? <Table
              tableHeaderColor="primary"
              tableHead={['Data', 'Produto', 'Ação', 'Qtde', 'ID Pedido']}
              tableColumn={['data', 'name', 'acao', 'quantidade', 'order']}
              tableColumnSize={['20%', '20%', '12%', '15%', '15%']}
              tableData={itens}
              resource="estoque"
              onDelete={(id) => handleDelete(id)}

            /> : isLoading ? (
              <Box mt={6}>
                <CircularProgress />
              </Box>
            ) : (
              <p style={{ marginTop: 20 }}>Nenhuma movimentação de estoque realizada.</p>
            )}
          </CardBody>
        </Card>
      </GridItem>

    </GridContainer>
  );
}
