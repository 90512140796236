import React, { useEffect, useState, useCallback, useContext } from 'react';
import ReactLoading from 'react-loading';

import Button from 'components/CustomButtons/Button.js';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import api from '../../services/api';
import Calendar from '../Calendar';

import moment from 'moment';

export default function Agendamento({ customer_id, session, setValue, register, zone, handleStep, getValues, total, valor, handleSelectedDay, destino, expressa }) {

    const [selectedDay, setSelectedDay] = useState('');
    const [selectedDayFormatted, setSelectedDayFormatted] = useState('');
    const [selectedPeriod, setSelectedPeriod] = useState('');
    const [welcomeCoupon, setWelcomeCoupon] = useState(false);
    const [disabledDays, setDisabledDays] = useState([]);
    const [cupomValue, setCupomValue] = useState(0);
    const [availablePeriods, setAvailablePeriods] = useState([]);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {

        setSelectedPeriod('');

        const availablePeriods = [];
        const day = moment(selectedDay).format("YYYY-MM-DD");
        setSelectedDayFormatted(moment(selectedDay).format("DD/MM/YYYY"));
        const days = data.filter(d => d.data === day);

        days.map(d => {
            availablePeriods.push({
                value: `${d.weekdayId}#${d.periodId}`,
                label: `(${d.period})`
            });

            setValue('selectedPeriod', d.weekdayId + '#6');
            setSelectedPeriod(d.weekdayId + '#6');


        })

        setAvailablePeriods(availablePeriods);
        handleSelectedDay(selectedDay);

    }, [selectedDay]);

    const verifyWelcomeCoupon = useCallback(async () => {
        try {
            const response = await api.get(`/coupons/welcome/${session}/${customer_id}`);

            if (response.data.enabled) {

                const type = response.data.type;
                const value = response.data.value;
                let totalValor = Number(total);
                let desconto = 0;
                if (type === 'P') {
                    desconto = (Number(totalValor) * Number(value)) / 100;
                }
                else {
                    desconto = Number(value);
                }
                setWelcomeCoupon(true);
                setCupomValue(desconto);
            }

        }
        catch (e) {
            console.log(e);
        }

    }, [session]);


    const loadAvailability = useCallback(async () => {
        try {

            const response = await api.post('/shipping/list-availability', {
                zone,
                destino
            })

            if (response.data) {

                const disabled = [];
                const days = response.data.disabledDates;
                disabled.push(
                    {
                        after: new Date(response.data.maxdate),
                        before: new Date(response.data.mindate),
                    },
                );

                days.map(d => {

                    const aux = d.split("-");

                    disabled.push(
                        new Date(Number(aux[0]), Number(aux[1]) - 1, Number(aux[2]), 5, 4, 0)
                    );
                    return d;
                });

                setDisabledDays(disabled);
                setData(response.data.datas);

                const day = getValues('selectedDay');
                const period = getValues('selectedPeriod');

                if (day) {
                    setSelectedDay(day);
                }

                if (period) {
                    setSelectedPeriod(period);
                }

                setLoading(false);

            }

        }
        catch (e) {
            setLoading(false);
        }

    }, [destino]);

    useEffect(() => {
        const period = getValues('selectedPeriod');
        if (period) {
            setSelectedPeriod(period);
        }
    }, [availablePeriods]);

    useEffect(() => {
        loadAvailability();
    }, []);


    useEffect(() => {
        if (session) {
            verifyWelcomeCoupon();
        }
    }, [session]);

    if (loading) {
        return (
            <div><ReactLoading type={'spin'} color={'#000000'} height={50} width={50} /></div>
        )
    }

    return (
        <>

            {data && data.length > 0 ?
                <div><br />
                    <label>
                        <img src="/images/agende.svg" alt="" /> Data e período de entrega
                    </label>

                    <div className="row py-100">
                        <div className="col-lg-6">
                            <div className="form-floating mb-3">
                                <Calendar disabledDays={disabledDays} day={selectedDay} handleSelectedDay={(day) => { setSelectedDay(day); setValue('selectedDay', day); }} />
                            </div>
                        </div>
                        <div className="col-lg-6">

                            {selectedDay && valor > 0 && <div className="form-floating mb-3">

                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    InputLabelProps={{ shrink: true }}
                                    label="Taxa entrega"
                                    name="valor"
                                    id="valor"
                                    disabled={true}
                                    className="form-control"
                                    value={Intl.NumberFormat('pt-BR', {
                                        style: 'currency',
                                        currency: 'BRL',
                                    }).format(Number(valor))}
                                    {...register(`valor`)}
                                />



                            </div>}

                            {selectedDay && welcomeCoupon &&
                                <div className="form-floating mb-3">
                                    <p>(-) Cupom de boas vindas (primeira compra):</p>


                                </div>}


                            {selectedDay && !expressa && <div>

                                <FormControl fullWidth variant="outlined" style={{ marginTop: 10 }}>
                                    <InputLabel id="type">Periodo</InputLabel>
                                    <Select
                                        {...register('selectedPeriod')}
                                        labelId="selectedPeriod"
                                        label="Periodo"
                                        variant="outlined"
                                        name="selectedPeriod"
                                        fullWidth
                                        value={selectedPeriod}
                                        onChange={(e) => setSelectedPeriod(e.target.value)}
                                    >
                                        {availablePeriods && availablePeriods.map(o => (
                                            <MenuItem value={o.value}>{o.label}</MenuItem>
                                        ))}

                                    </Select>
                                </FormControl>
                            </div>}

                            {selectedDay && expressa && destino === 'E' && <div className="form-floating mb-3">

                                <FormControl fullWidth variant="outlined" style={{ marginTop: 10 }}>
                                    <InputLabel id="type">Periodo</InputLabel>
                                    <Select
                                        {...register('selectedPeriod')}
                                        labelId="selectedPeriod"
                                        label=">Tipo entrega"
                                        variant="outlined"
                                        name="selectedPeriod"
                                        fullWidth
                                        value={selectedPeriod}
                                        onChange={(e) => setSelectedPeriod(e.target.value)}
                                    >
                                        <MenuItem value="0#4">Entrega Expressa</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>}

                            {selectedDay && expressa && destino === 'R' && <div className="form-floating mb-3">
                                <FormControl fullWidth variant="outlined" style={{ marginTop: 10 }}>
                                    <InputLabel id="type">Periodo</InputLabel>
                                    <Select
                                        {...register('selectedPeriod')}
                                        labelId="selectedPeriod"
                                        label="Tipo entrega"
                                        variant="outlined"
                                        name="selectedPeriod"
                                        fullWidth
                                        value={selectedPeriod}
                                        onChange={(e) => setSelectedPeriod(e.target.value)}
                                    >
                                        <MenuItem value="0#5" selected>Retirada no local</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>}


                            {selectedDay && ((!expressa && selectedPeriod) || (expressa)) && <Button color="warning" type="submit" style={{ marginTop: 20 }}>AVANÇAR</Button>
                            }

                        </div>
                    </div>


                </div> :
                <><p>No momento não temos disponibilidade para entrega neste endereço.</p>
                    <a className="btn btn-primary" onClick={() => handleStep(1)}>Voltar</a>
                </>}
        </>
    )

}
