import 'date-fns';
import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';


import { makeStyles } from '@material-ui/core/styles';
import { useToasts } from 'react-toast-notifications'
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'assets/css/react-confirm-alert.css'; // Import css
import { CircularProgress } from '@material-ui/core';
import Box from '@material-ui/core/Box';


import TextField from '@material-ui/core/TextField';
import styles from 'assets/jss/material-dashboard-react/views/dashboardStyle.js';
import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import Table from 'components/Table/Table.js';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';
import Card from 'components/Card/Card';
import State from 'components/State/State.js';
import City from 'components/City/City.js';
import Button from 'components/CustomButtons/Button.js';
import api from '../../services/api';

const useStyles = makeStyles(styles);

export default function NoAvailability() {
  const classes = useStyles();
  const [state, setState] = useState('');
  const [city, setCity] = useState('');
  const [loading, setLoading] = useState();
  const [data, setData] = useState([]);
  const { addToast } = useToasts();

  useEffect(() => {
      getData();
  }, []);


  const getData = useCallback(async () => {
    try {
      setLoading(true);

      const aval = [];
      const response = await api.post("/shipping/noavailability/search", {
        filtros: {
          city,
          state,
        }
      });
      
       response.data.map(n => {
        aval.push({
          id: n.id,
          name: n.name,
          email: n.email,
          neighborhood: n.neighborhood,
          city: n.city,
          state: n.state,
          zipcode:  n.zipcode,
          created_at:  new Date(n.created_at).toLocaleDateString('pt-br', {
            day: '2-digit',
            month: 'long',
            year: 'numeric'
           })
        });   
      })
  
      setData(aval);
      setLoading(false);

    }
    catch(e){
      addToast('Ocorreu um erro para atualizar os dados', {
        appearance: 'error',
        autoDismiss: true,
      });      
      
      setLoading(false);

    } 
  },[city, state]);

  const handleDelete = useCallback(async (id) => {

    confirmAlert({
      title: 'Confirmação',
      message: 'Tem certeza que deseja remover este registro ?',
      buttons: [
        {
          label: 'Sim',
          onClick: () => postDeleteAction(id)
        },
        {
          label: 'Não'         
        }
      ]
    });

  },[]);

  const postDeleteAction =  useCallback(async (id) => {
      try {
          await api.delete(`/noavailability/${id}`);
          getData();
      }
      catch(e){
        addToast(e.response.data.message, {
          appearance: 'error',
          autoDismiss: true,
        });        
      }
  },[]);

  
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card> 
          <CardHeader color="primary">
            <h4 className={classes.cardTitleWhite}>Sem disponibilidade</h4>
            <p className={classes.cardCategoryWhite}>
              Relação de pessoas que se cadastraram sem disponibilidade para atendimento.
            </p>
          </CardHeader>
          <CardBody>

          <State value={state} handleChangeValue={(value) => setState(value)} marginTop={20} />
          <TextField
            variant="outlined"
            margin="normal"       
            fullWidth
            id="name" 
            label="Cidade"
            name="cidade"
            value={city}
            onChange={(e) => setCity(e.target.value)}        
         /> 
          <Button color="primary" type="button" onClick={getData} style={{marginTop: 20}}>Filtrar</Button>
       

            {loading ? (
            <Box mt={6}>
             <CircularProgress />
            </Box>               
            ) : ( 
             <>     
              {data && data.length > 0 ? <Table
                  tableHeaderColor="primary"
                  tableHead={['Nome', 'Email', 'Bairro', 'Cidade', 'UF', 'Cep', 'Cadastrado em', '']}
                  tableColumn={['name', 'email', 'neighborhood', 'city', 'state', 'zipcode', 'created_at', 'delete']}
                  tableColumnSize={['15%', '15%', '15%', '15%', '15%', '15%', '15%', '1%']}
                  tableData={data} 
                  resource="noavailability"            
                  onDelete={(id) => handleDelete(id)}              
              /> : <p style={{marginTop: 20}}>Nenhuma pessoa cadastrada no momento.</p>}

             </>
            )}
          </CardBody>
        </Card>
      </GridItem>

    </GridContainer>
  );
}
