import React, {useEffect, useCallback, useState, useRef} from 'react';
import * as Yup from 'yup';
import { useToasts } from 'react-toast-notifications'
import { useParams, useHistory } from 'react-router-dom';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';

// core components
import styles from 'assets/jss/material-dashboard-react/views/dashboardStyle.js';
import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import TextField from '@material-ui/core/TextField';
import Button from 'components/CustomButtons/Button.js';
import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardBody from 'components/Card/CardBody.js';
import CardFooter from 'components/Card/CardFooter.js';

import api from '../../services/api';
import getValidationErrors from '../../utils/getValidationErrors';

import { CircularProgress } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import State from 'components/State/State.js';


const useStyles = makeStyles(styles);

export default function EditCategory() {
  const [action, setAction] = useState('create');
  const [name, setName] = useState('');
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState();
  const formRef = useRef(null);
  const history = useHistory();
  const { addToast } = useToasts()

  const { id } = useParams();   
  const classes = useStyles();

  useEffect(() => {
    if (id != "new"){
      setAction("update");
    }
  },[]);

  useEffect(() => {
    if (action === "update"){
      loadData(id);
    }
  },[action]);

  const handleSave = useCallback(async (e) => {

    try { 

      e.preventDefault();    

      setLoading(true);
      
      const nameInput = e.target.name.value;    
      const stateInput = state !== 'T' ? state : '';
          
      const schema = Yup.object().shape({
        nameInput: Yup.string().required('Nome é obrigatório !'),  
        stateInput: Yup.string().required('Estado/UF é obrigatório !'),              
      });         

      await schema.validate({nameInput, stateInput}, {
        abortEarly: false,
      });
      
      let data = {        
        name: nameInput,
        state: stateInput
      }

      if (action === "update"){
        data.id = id;
      }
 
      await api.post('/shipping/city', data);

      setLoading(false);
   
      history.push('/admin/cities');
   
    }
    catch(e){ 

      setLoading(false);
      if (e instanceof Yup.ValidationError) {
        const errors = getValidationErrors(e); 

        for (var [, value] of Object.entries(errors)) {
          addToast(value, {
            appearance: 'error',
            autoDismiss: true,
          });
        }
        return;
      }    

      
      addToast(e.response ? e.response.data.message : 'Ocorreu um erro !', {
        appearance: 'error',
        autoDismiss: true,
      });
          
     
  
    }
  },[action, state]);

  const loadData = useCallback(async () => {    
    try {
      const response = await api.get(`/shipping/city/${id}`);

      setName(response.data.name);    
      setState(response.data.state.id);

    }
    catch(e){

      addToast(e.response.data.message ? e.response.data.message : 'Ocorreu um erro !', {
        appearance: 'error',
        autoDismiss: true,
      });       
      
      history.push('/admin/cities');
    }
  });


  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
          <form className={classes.form} ref={formRef} onSubmit={handleSave}>
            <CardHeader color="primary">
              <h4 className={classes.cardTitleWhite}>Dados da Cidade</h4>
              <p className={classes.cardCategoryWhite}>Complete os dados.</p>
            </CardHeader>
            <CardBody>

              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                   <State value={state} handleChangeValue={(value) => setState(value)} marginTop={20} />
                </GridItem>               
              </GridContainer>
           
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      id="name"
                      label="Nome"
                      name="name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      autoFocus
                 /> 
               </GridItem>               
             </GridContainer>
             
            </CardBody>
            <CardFooter>
              {!loading ? <Button color="primary" type="submit">Salvar</Button> : <Box mt={6}>
             <CircularProgress />
            </Box>}
            </CardFooter>
          </form>
          </Card>
        </GridItem>
        
      </GridContainer>
    </div>
  );
}
