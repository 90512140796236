import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import PerfectScrollbar from 'perfect-scrollbar';
import 'perfect-scrollbar/css/perfect-scrollbar.css';

import { makeStyles } from '@material-ui/core/styles';

import Navbar from 'components/Navbars/Navbar.js';
import Footer from 'components/Footer/Footer.js';
import Sidebar from 'components/Sidebar/Sidebar.js';

import styles from 'assets/jss/material-dashboard-react/layouts/adminStyle.js';

import bgImage from 'assets/img/menu.jpg';
import logo from 'assets/img/logo-branca.png';
import menuRoutes from '../../routes/menu.routes';

import EditBannerPage from '../../pages/Banners/edit';
import EditCategoryPage from '../../pages/Categories/edit';
import EditCaracteristicaPage from '../../pages/Caracteristicas/edit';
import EditModoPreparoPage from '../../pages/ModoPreparo/edit';
import EditEstoquePage from '../../pages/Estoque/edit';

import EditAdminPage from '../../pages/Admin/edit';
import TextEditPage from '../../pages/Texts/edit';
import ProductEditPage from '../../pages/Products/edit';

import EditCouponsPage from '../../pages/Coupons/edit';
import ProductImagesPage from '../../pages/ProductImages';
import EditCustomersPage from '../../pages/Customers/edit';

import EditNoticiasPage from '../../pages/Noticias/edit';

import EditOrdersPage from '../../pages/Orders/edit';

import EditStatePage from '../../pages/States/edit';
import EditCityPage from '../../pages/Cities/edit';
import EditZonePage from '../../pages/Zones/edit';
import EditNeighborhoodPage from '../../pages/Neighborhoods/edit';
import EditVouchersPage from '../../pages/Vouchers/edit';

import EditPaymentFormPage from '../../pages/PaymentForm/edit';

import EditPickupLocationPage from '../../pages/PickupLocation/edit';

let ps;

const switchRoutes = (
  <Switch>
    <Route path="/admin/products/images/:id" isPrivate component={ProductImagesPage} />
    <Route path="/admin/categories/:id" isPrivate component={EditCategoryPage} />
    <Route path="/admin/caracteristicas/:id" isPrivate component={EditCaracteristicaPage} />
    <Route path="/admin/modopreparo/:id" isPrivate component={EditModoPreparoPage} />
    <Route path="/admin/banners/:id" isPrivate component={EditBannerPage} />
    <Route path="/admin/administradores/:id" isPrivate component={EditAdminPage} />
    <Route path="/admin/texts/:id" isPrivate component={TextEditPage} />
    <Route path="/admin/products/:id" isPrivate component={ProductEditPage} />
    <Route path="/admin/estoque/:id" isPrivate component={EditEstoquePage} />
    <Route path="/admin/coupons/:id" isPrivate component={EditCouponsPage} />
    <Route path="/admin/customers/:id" isPrivate component={EditCustomersPage} />
    <Route path="/admin/orders/:id" isPrivate component={EditOrdersPage} />

    <Route path="/admin/blog/:id" isPrivate component={EditNoticiasPage} />

    <Route path="/admin/cities/:id" isPrivate component={EditCityPage} />
    <Route path="/admin/states/:id" isPrivate component={EditStatePage} />
    <Route path="/admin/neighborhoods/:id" isPrivate component={EditNeighborhoodPage} />
    <Route path="/admin/zones/:id" isPrivate component={EditZonePage} />
    <Route path="/admin/vouchers/:id" isPrivate component={EditVouchersPage} />
    <Route path="/admin/coupouns/:id" isPrivate component={EditCouponsPage} />

    <Route path="/admin/paymentform/:id" isPrivate component={EditPaymentFormPage} />

    <Route path="/admin/pickuplocation/:id" isPrivate component={EditPickupLocationPage} />

    {menuRoutes.map((prop, key) => {
      return (
        <Route
          path={prop.layout + prop.path}
          component={prop.component}
          key={key}
        />
      );
    })}

  </Switch>
);

const useStyles = makeStyles(styles);

export default function Admin({ ...rest }) {
  const classes = useStyles();
  const mainPanel = React.createRef();

  const [image, setImage] = React.useState(bgImage);
  const [color, setColor] = React.useState('blue');
  const [fixedClasses, setFixedClasses] = React.useState('dropdown show');
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const getRoute = () => window.location.pathname !== '/admin/maps';
  const resizeFunction = () => {
    if (window.innerWidth >= 960) {
      setMobileOpen(false);
    }
  };

  // initialize and destroy the PerfectScrollbar plugin
  React.useEffect(() => {
    if (navigator.platform.indexOf('Win') > -1) {
      ps = new PerfectScrollbar(mainPanel.current, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
      document.body.style.overflow = 'hidden';
    }
    window.addEventListener('resize', resizeFunction);
    // Specify how to clean up after this effect:
    return function cleanup() {
      if (navigator.platform.indexOf('Win') > -1) {
        ps.destroy();
      }
      window.removeEventListener('resize', resizeFunction);
    };
  }, [mainPanel]);
  return (
    <div className={classes.wrapper}>
      <Sidebar
        routes={menuRoutes}
        logoText="Equilibrae"
        logo={logo}
        image={image}
        handleDrawerToggle={handleDrawerToggle}
        open={mobileOpen}
        color={color}
        {...rest}
      />
      <div className={classes.mainPanel} ref={mainPanel}>
        <Navbar
          routes={menuRoutes}
          handleDrawerToggle={handleDrawerToggle}
          {...rest}
        />

        {getRoute() ? (
          <div className={classes.content}>
            <div className={classes.container}>{switchRoutes}</div>
          </div>
        ) : (
          <div className={classes.map}>{switchRoutes}</div>
        )}
        {getRoute() ? <Footer /> : null}

      </div>
    </div>
  );
}
