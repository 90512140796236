import React, {useEffect, useCallback, useState, useRef} from 'react';
import * as Yup from 'yup';
import { useToasts } from 'react-toast-notifications'
import CurrencyTextField from '@unicef/material-ui-currency-textfield'
import { useParams, useHistory } from 'react-router-dom';
import InputMask from "react-input-mask";
import { debounce } from 'lodash';
import { v4 as uuidV4 } from "uuid";

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import { CircularProgress } from '@material-ui/core';
import Box from '@material-ui/core/Box';

import Editor from 'ckeditor5-custom-build/build/ckeditor';
import { CKEditor } from '@ckeditor/ckeditor5-react'


const editorConfiguration = { 
    toolbar: [ 'bold', 'italic', 'fontFamily', 'fontSize', 'fontColor', 'fontBackgroundColor',  'blockquote', 'link' ] 
}; 

import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';

// co

import AutoComplete from 'components/AutoComplete/AutoComplete.js';

import Table from 'components/Table/Table.js';
import styles from 'assets/jss/material-dashboard-react/views/dashboardStyle.js';
import Category from 'components/Category/Category.js';
import Modopreparo from 'components/Modopreparo/Modopreparo.js';
import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import Button from 'components/CustomButtons/Button.js';
import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardBody from 'components/Card/CardBody.js';
import CardFooter from 'components/Card/CardFooter.js';
import MultipleSelection from 'components/MultipleSelection/MultipleSelection.js';

import api from '../../services/api';
import getValidationErrors from '../../utils/getValidationErrors';

const useStyles = makeStyles(styles);

export default function EditProduct() {
  const selectInputRef = useRef();
  const selectInputRefKit = useRef();
  const [action, setAction] = useState('create');
  const [isLoading, setLoading] = useState(true);
  const [loaded, setLoaded] = useState(false);
  const [codigo, setCodigo] = useState('');
  const [type, setType] = useState('I');
  const [nome, setNome] = useState(''); 
  const [descricao, setDescricao] = useState('');
  const [valorde, setValorde] = useState('');
  const [valorpor, setValorpor] = useState('');
  const [peso, setPeso] = useState('');
  const [ativo, setAtivo] = useState(true);  
  const [destaque, setDestaque] = useState(false);  
  const [porcao, setPorcao] = useState('');
  const [caracteristicas, setCaracteristicas] = useState([]);
  const [modopreparosel, setModoPreparosel] = useState([]);
  const [modopreparo, setModoPreparo] = useState('T');
  const [produtosKit, setProdutosKit] = useState([]);
  const [relacionados, setRelacionados] = useState([]);  
  const [tabelanutricional, setTabelanutricional] = useState([]);  
  const [category, setCategory] = useState('T');
  const [tempopreparo, setTempopreparo] = useState('');
  const [produtoRelacionado, setProdutoRelacionado] = useState('');
  const [info, setInfo] = useState('');
  const [valorinfo, setValorinfo] = useState('');

  const [qtdmin, setQtdMin] = useState();
  const [qtdmax, setQtdMax] = useState();
  const [produtoKit, setProdutoKit] = useState();

  const formRef = useRef(null);
  const history = useHistory();
  const { addToast } = useToasts()

  const { id } = useParams();   
  const classes = useStyles();

  useEffect(() => {
    if (id != "new"){
      setAction("update");
    }
  },[]);

  useEffect(() => {
    if (action === "update"){
      loadData(id);
    }
    else{
      setLoaded(true);
      setLoading(false);
    }
  },[action]);

  const handleChangeCaracteristica = useCallback(async(id, checked) => {
    
    if (checked){
      setCaracteristicas(caracteristicas => [...caracteristicas, id]);
    }
    else{ 
      setCaracteristicas(caracteristicas => caracteristicas.filter(c => c !== id));
    }

  },[caracteristicas]); 

  const handleAdicionaProdutoRelacionado = useCallback(async() => {
   
    if (!produtoRelacionado){
      addToast('Informe o produto', {
        appearance: 'error',
        autoDismiss: true,
      });
      return;
    }

    const jaInserido = relacionados.find(p => p.id === produtoRelacionado.id);

    if (id && produtoRelacionado.id === id){
      selectInputRef.current.select.clearValue();
      addToast('Você não pode relacionar o proprio produto.', {
        appearance: 'error',
        autoDismiss: true,
      });
      return;
    }

    selectInputRef.current.select.clearValue();

    if (!jaInserido){
      setRelacionados([...relacionados, {
        id: produtoRelacionado.id,
        nome: produtoRelacionado.label,       
      }]);  
    }
    else{      
      addToast('Produto já inserido', {
        appearance: 'error',
        autoDismiss: true,
      });
      return;
    } 

  },[produtoRelacionado]);

  const handleDeleteProdutoRelacionado = useCallback(async(id) => {
    setRelacionados(relacionados.filter(p => p.id !== id));

  },[relacionados]);

  const handleDeleteProdutoKit = useCallback(async(id) => {
    setProdutosKit(produtosKit.filter(p => p.id !== id));

  },[produtosKit]);

  const handleAdicionaTabelaNutricional = useCallback(async() => {

    if (!info || !valorinfo){
      addToast('Informe os dados para inclusão de item da tabela nutricional', {
        appearance: 'error',
        autoDismiss: true,
      });
      return;
    }

  
    const jaInserido = tabelanutricional.find(m => m.info === info);

    if (!jaInserido){

      let maxOrdem = 0;

      if (tabelanutricional.length > 0){
        const obj = tabelanutricional.reduce((max, obj) => (max.ordem > obj.ordem) ? max : obj);
        maxOrdem = obj.ordem + 1;
      }
      else{
        maxOrdem = 1;
      }
      setTabelanutricional([...tabelanutricional, {
        id: uuidV4(),
        ordem: maxOrdem,
        titulo: info,
        valor: valorinfo,
      }]);  
    }
    else{
      addToast('Informação já inserido', {
        appearance: 'error',
        autoDismiss: true,
      });
      return;
    }

    setValorinfo('');
    setInfo('');

  },[info,valorinfo]);

  const handleDeleteTabelaNutricional = useCallback(async(id) => {

    setTabelanutricional(tabelanutricional.filter(m => m.id !== id));

  },[tabelanutricional]);

  const handleDeleteModoPreparo = useCallback(async(id) => {

    setModoPreparosel(modopreparosel.filter(m => m.id !== id));

  },[modopreparosel]);

  const handleModificaOrdemTabelaNutricional = useCallback(async() => {
      const items = [...tabelanutricional];
      items.sort((a, b) => Number(a.ordem) - Number(b.ordem));
      setTabelanutricional(items);
  },[tabelanutricional]);

  const handleAdicionaProdutoKit = useCallback(async() => {

    try {
      if (!produtoKit || !qtdmin || !qtdmax){
        addToast('Informe o produto, a qtde máxima e a qtde minima', {
          appearance: 'error',
          autoDismiss: true,
        });
        return;
      }


      const jaInserido = produtosKit.find(p => p.productIncluded === produtoKit.id);

      if (produtoKit.id === id){
        selectInputRefKit.current.select.clearValue();
        addToast('Você não pode adicionar o proprio produto ao kit.', {
          appearance: 'error',
          autoDismiss: true,
        });
        return;
      }


      if (!jaInserido){
        setProdutosKit([...produtosKit, { 
          id: uuidV4(),       
          nome: produtoKit.label,
          productIncluded: produtoKit.id,
          qtdmin,
          qtdmax,   
        }]);  
      }
      else{
        selectInputRefKit.current.select.clearValue();
        addToast('Produto já inserido', {
          appearance: 'error',
          autoDismiss: true,
        });
        return;
      }

      setQtdMin('');
      setQtdMax('');
      selectInputRefKit.current.select.clearValue();
    }
    catch(e){   
         
    }

  },[produtoKit, produtosKit, qtdmin, qtdmax]);


  const handleAdicionaModoPreparo = useCallback(async() => {
  
    if (!modopreparo || modopreparo === 'T'){
      addToast('Informe o modo de preparo', {
        appearance: 'error',
        autoDismiss: true,
      });
      return;
    }

    if (!tempopreparo){
      addToast('Informe o tempo de preparo', {
        appearance: 'error',
        autoDismiss: true,
      });
      return;
    }

    const jaInserido = modopreparosel.find(m => m.id === modopreparo.id);

    if (!jaInserido){
      setModoPreparosel([...modopreparosel, {
        id: uuidV4(),
        modopreparo: modopreparo.id,
        nome: modopreparo.name,
        tempopreparo,
      }]);  
    }
    else{
      addToast('Modo de preparo já inserido', {
        appearance: 'error',
        autoDismiss: true,
      });
      return;
    }

    setModoPreparo('');
    setTempopreparo('');


  },[modopreparosel, modopreparo, tempopreparo]);

  const handleSave = async (e) => {

    try {  

      e.preventDefault();

      const codigo = e.target.codigo.value;
      const nome = e.target.nome.value;
      const category_id = category !== 'T' ? category : '';      
      const peso = e.target.peso ? e.target.peso.value : '0.00';   
      const valor_de = e.target.valorde.value;  
      const valor_por = e.target.valorpor.value;
      const porcao = e.target.porcao.value;   

      const produtosRelacionados = [];
      relacionados.map(p => {
        produtosRelacionados.push(p.id);
      })
     
      const schema = Yup.object().shape({
        codigo: Yup.string().required('Código do produto é obrigatório'),      
        nome: Yup.string().required('Nome do produto é obrigatório'),
        category_id: Yup.string().required('Informe a categoria'),        
        descricao: Yup.string().required('Informe a descrição do produto'),
        valor_de: Yup.string().required('Informe o valor de'),
        valor_por: Yup.string().required('Informe o valor por'),
        peso: Yup.string().required('Informe o peso'),       
        porcao: Yup.string().required('Informe a porcao'),
      });         

      await schema.validate({codigo, nome, category_id, descricao, valor_de, valor_por, peso, porcao}, {
        abortEarly: false
      });
  
      const postData = {          
        type,
        codigo,
        nome,
        category: category_id,
        valor_de: valor_de.replace(",","."),
        valor_por: valor_por.replace(",","."),
        descricao,
        peso: peso.replace(",","."),
        ativo,
        destaque,
        porcao,
        caracteristicas,
        relacionados: produtosRelacionados,
        modopreparo: modopreparosel,
        produtosKit,
        tabelanutricional,
      };
      
       
      if (action === 'update'){
        postData.id = id;
      }
  
      await api.post(`/products`, postData);   

      history.push('/admin/products');
   
    }
    catch(e){ 


      setLoading(false);
      
      if (e instanceof Yup.ValidationError) {
        const errors = getValidationErrors(e); 

        for (var [, value] of Object.entries(errors)) {
          addToast(value, {
            appearance: 'error',
            autoDismiss: true,
          });
        }
        return;
      }    
      
      addToast(e.response ? e.response.data.message : 'Ocorreu um erro !', {
        appearance: 'error',
        autoDismiss: true,
      });
          
  
    }
  };

  const loadData = useCallback(async () => {   

    try {

      setLoading(true);
      
      const response = await api.get(`/products/${id}`);

      setCodigo(response.data.codigo);   
      setType(response.data.type);
      setNome(response.data.nome);      
      setCategory(response.data.category?.id);
      setValorde(response.data.valor_de);
      setValorpor(response.data.valor_por);
      setDescricao(response.data.descricao);
      setPeso(response.data.peso);
      setAtivo(response.data.ativo);
      setDestaque(response.data.destaque);
      setPorcao(response.data.porcao);

      const items = [...response.data.tabelanutricional];
      items.sort((a, b) => Number(a.ordem) - Number(b.ordem));
      setTabelanutricional(items);

      const prodkit = [];

      if (response.data && response.data.produtoskit){
        response.data.produtoskit.map(rl => {   
          
          prodkit.push({
            id: rl.id,            
            nome: `${rl.productIncluded.codigo} - ${rl.productIncluded.nome}`,  
            qtdmin: rl.qtdmin,
            qtdmax: rl.qtdmax          
          })
        });        
      }

      setProdutosKit(prodkit);
        
      const prodrel = [];
      if (response.data && response.data.relacionados){
        response.data.relacionados.map(rl => {      
          prodrel.push({
            id: rl.id,            
            nome: `${rl.codigo} - ${rl.nome}`,            
          })
        });
      }

      setRelacionados(prodrel);
      
      const modo = [];
      if (response.data && response.data.productmodopreparo){
        response.data.productmodopreparo.map(rm => {   
          modo.push({
            id: rm.id,
            modopreparo: rm.modopreparo.id,
            nome: rm.modopreparo.name,
            tempopreparo: rm.tempopreparo,
          })
        });
      }

      setModoPreparosel(modo);
         
      const caracteristicasSelected = [];
      response.data.caracteristicas.map(c => {
        caracteristicasSelected.push(c.id);
      });

      setCaracteristicas(caracteristicasSelected);
     
      setLoaded(true);
      setLoading(false);
      
    }
    catch(e){

      addToast(e.response.data.message ? e.response.data.message : 'Ocorreu um erro !', {
        appearance: 'error',
        autoDismiss: true,
      });      

      history.push("/admin/products");

    }
  });

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
        
          <form className={classes.form} ref={formRef} onSubmit={handleSave}>
            <CardHeader color="primary">
              <h4 className={classes.cardTitleWhite}>Dados do produto</h4>
              <p className={classes.cardCategoryWhite}>Complete os dados.</p>
            </CardHeader>
            <CardBody> 

           {loaded ?
           <>   
           <FormControl fullWidth variant="outlined" className={classes.formControl} style={{marginTop: 18}}>
                <InputLabel id="category">Tipo produto</InputLabel>             
                <Select 
                        labelId="tipo"
                        label="tipo produto"
                        variant="outlined"                  
                        name="type"
                        fullWidth
                        value={type}
                        onChange={(e) => setType(e.target.value)}              
                        >    
                        <MenuItem key="I" value="I">Individual</MenuItem> 
                        <MenuItem key="K" value="K">Kit</MenuItem>                                                                 
                </Select>
            </FormControl>

            {type === 'K' ? <div style={{marginBottom: 10}}><FormControl fullWidth variant="outlined" className={classes.formControl} style={{marginTop: 28}}>
                <p><strong>Produtos - Kit:</strong></p>  

                {produtosKit && produtosKit.length === 0 ? <Box bgcolor="text.primary" color="warning.contrastText" p={2}><p style={{color: '#FFF'}}>Nenhum produto definido.</p></Box> : null}

                {produtosKit && produtosKit.length > 0 ?   
                    <>
                      <Table
                            tableHeaderColor="primary"
                            tableHead={['Produto', 'Qtde Minima', 'Qtde Máxima', '']}
                            tableColumn={['nome', 'qtdmin', 'qtdmax', 'delete']}
                            tableColumnSize={['50%', '15%', '15%', '1%']}
                            tableData={produtosKit} 
                            onDelete={(id) => handleDeleteProdutoKit(id)}
                          
                                         
                        /><br />
                    </> 
                : null}

                <GridContainer>
                 <GridItem xs={12} sm={12} md={4} style={{marginTop: 20}}>       
                     <AutoComplete myRef={selectInputRefKit}  placeholder="Selecione" resource="products/search" handleChange={e => setProdutoKit(e)} />
                  </GridItem> 
                  <GridItem xs={12} sm={12} md={2}>

                   <InputMask mask="9999" maskPlaceholder="" value={qtdmin} onChange={(event) => setQtdMin(event.target.value)}>
                    <TextField
                          variant="outlined"
                          margin="normal"       
                          fullWidth
                          id="qtdmin" 
                          label="Qtd minima"
                          name="qtdmin"
                      />
                    </InputMask>
                   
                  </GridItem>             

                  <GridItem xs={12} sm={12} md={2}>
                  <InputMask mask="9999" maskPlaceholder="" value={qtdmax} onChange={(event) => setQtdMax(event.target.value)}>
                    <TextField
                          variant="outlined"
                          margin="normal"       
                          fullWidth
                          id="qtdmax" 
                          label="Qtd maxima"
                          name="qtdmax"
                      />
                    </InputMask>
                   
                  </GridItem>                 
                  
                  <GridItem xs={12} sm={12} md={4}>
                      <Button color="warning" style={{marginTop: 22}} onClick={() => handleAdicionaProdutoKit()}>ADICIONAR</Button>
                  </GridItem>
                </GridContainer>
                
              </FormControl><hr /></div> : null}
          
            <GridContainer>
            <GridItem xs={12} sm={12} md={12} style={{marginTop: 20}}>    
              <TextField
                  variant="outlined"              
                  fullWidth
                  id="codigo" 
                  label="Código referência do produto"
                  name="codigo"
                  value={codigo}
                  style={{marginBottom: 5}} 
                  required
                  onChange={(e) => setCodigo(e.target.value)}            
              />   
                                            
              <TextField
                  variant="outlined"
                  margin="normal"       
                  fullWidth
                  id="nome" 
                  label="Nome do produto"
                  name="nome"
                  value={nome}
                  required
                  onChange={(e) => setNome(e.target.value)}            
              />      

              <TextField
                  variant="outlined"
                  margin="normal"       
                  fullWidth
                  id="porcao" 
                  label="Porção"
                  name="porcao"
                  value={porcao}
                  required
                  onChange={(e) => setPorcao(e.target.value)}            
              /> 
      
              <Category value={category} handleChangeValue={(value) => setCategory(value)} marginTop={15} />

              <CurrencyTextField
                  style={{marginTop: 25}} 
                  label="Valor de"
                  variant="standard"
                  value={valorde}
                  currencySymbol="R$"
                  variant="outlined"
                  margin="normal"       
                  fullWidth
                  textAlign="left"              
                  outputFormat="string"
                  decimalCharacter=","
                  digitGroupSeparator=""
                  name="valorde"
                  onChange={(event, value)=> setValorde(value)}
               />

                <CurrencyTextField
                    style={{marginTop: 25}} 
                    label="Valor por"
                    variant="standard"
                    value={valorpor}
                    currencySymbol="R$"
                    variant="outlined"
                    margin="normal"       
                    fullWidth
                    textAlign="left"              
                    outputFormat="string"
                    decimalCharacter=","
                    digitGroupSeparator=""
                    name="valorpor"
                    onChange={(event, value)=> setValorpor(value)}
              />
                    
              <p style={{marginTop: 20}}>Descrição do produto</p>
             
              {!isLoading ? <CKEditor
                      editor={ Editor }
                      config={ editorConfiguration }
                      data={descricao}
                      onReady={(editor) => {       
                        editor.editing.view.change((writer) => {
                        writer.setStyle(
                            "height",
                            "400px",
                            editor.editing.view.document.getRoot()
                        );
                        });
                      }}    
                      onChange={ ( event, editor ) => {
                        const data = editor.getData();                      
                        setDescricao(data);
                    } }           
                /> : null} 

                <CurrencyTextField
                  style={{marginTop: 20}}
                    label="Peso"
                    variant="standard"
                    value={peso}
                    currencySymbol=""
                    variant="outlined"
                    margin="normal"       
                    fullWidth
                    textAlign="left"              
                    outputFormat="string"
                    decimalCharacter=","
                    digitGroupSeparator=""
                    name="peso"
                    onChange={(event, value)=> setPeso(value)}
               /> 

               <FormControl fullWidth variant="outlined" className={classes.formControl} style={{marginTop: 18}}>
                <InputLabel id="category">Ativo no site?</InputLabel>             
                <Select labelId="ativo"
                        label="ativo no site"
                        variant="outlined"                  
                        name="ativo"
                        fullWidth
                        value={ativo}
                        onChange={(e) => setAtivo(e.target.value)}              
                        >    
                        <MenuItem key="A1" value={true}>Sim</MenuItem> 
                        <MenuItem key="A2" value={false}>Não</MenuItem>                                                                 
                </Select>
                </FormControl>

                <FormControl fullWidth variant="outlined" className={classes.formControl} style={{marginTop: 24}}>
                    <InputLabel id="category">Destaque no site?</InputLabel>             
                    <Select 
                            labelId="destaque"
                            label="destaque no site"
                            variant="outlined"                  
                            name="destaque"
                            fullWidth
                            value={destaque}
                            onChange={(e) => setDestaque(e.target.value)}              
                            >    
                            <MenuItem key="D1" value={true}>Sim</MenuItem> 
                            <MenuItem key="D2" value={false}>Não</MenuItem>                                                                 
                    </Select>
                </FormControl>   

                <MultipleSelection selected={caracteristicas} resource={"caracteristicas"} handleChange={handleChangeCaracteristica} label="Selecione as caracteristicas" boxcolor marginTop={20} />
                      

              <FormControl fullWidth variant="outlined" className={classes.formControl} style={{marginTop: 24}}>
                  <p><strong>Tabela nutricional:</strong></p>  

                  {tabelanutricional && tabelanutricional.length === 0 ? <Box bgcolor="text.primary" color="warning.contrastText" p={2}><p style={{color: '#FFF'}}>Nenhuma informação registrada.</p></Box> : null}

                  {tabelanutricional && tabelanutricional.length > 0 ? 
                      <>
                        <Table
                              tableHeaderColor="primary"
                              tableHead={['Ordem', 'Informação', 'Valor', '']}
                              tableColumn={['ordem', 'titulo', 'valor',  'delete']}
                              tableColumnSize={['5%', '30%', '30%', '1%']}
                              tableData={tabelanutricional} 
                              onDelete={(id) => handleDeleteTabelaNutricional(id)}
                              handleInputChange={(id, valor) => {
                                const tab = [...tabelanutricional];                         
                                const item = tab.find(i => i.id === id);                        
                                item.ordem = valor;
                                setTabelanutricional(tab);
                              }}     
                                          
                          />
                          <GridItem xs={12} sm={12} md={4}>
                              <Button color="info" style={{marginTop: 22}} onClick={() => handleModificaOrdemTabelaNutricional()}>MODIFICAR ORDEM</Button>
                          </GridItem>
                          <br />
                      </> 
                  : null}

                  <GridContainer>
                    <GridItem xs={12} sm={12} md={4}>
                      <TextField
                          variant="outlined"
                          margin="normal"       
                          fullWidth
                          id="info" 
                          label="Informação"
                          name="info"
                          value={info}                        
                          onChange={(e) => setInfo(e.target.value)}            
                      /> 
                    </GridItem>             

                    <GridItem xs={12} sm={12} md={4}>
                      <TextField
                          variant="outlined"
                          margin="normal"       
                          fullWidth
                          id="valorinfo" 
                          label="Valor"
                          name="valorinfo"
                          value={valorinfo}                       
                          onChange={(e) => setValorinfo(e.target.value)}            
                      /> 
                    </GridItem>                 
                    
                    <GridItem xs={12} sm={12} md={4}>
                        <Button color="warning" style={{marginTop: 22}} onClick={() => handleAdicionaTabelaNutricional()}>ADICIONAR</Button>
                    </GridItem>
                  </GridContainer>
                  
              </FormControl>   
                  
              <FormControl fullWidth variant="outlined" className={classes.formControl} style={{marginTop: 24}}>
                  <p><strong>Modo Preparo:</strong></p>  

                  {modopreparosel && modopreparosel.length === 0 ? <Box bgcolor="text.primary" color="warning.contrastText" p={2} style={{marginBottom: 20}}><p style={{color: '#FFF'}}>Nenhum modo de preparo selecionado.</p></Box> : null}

                  {modopreparosel && modopreparosel.length > 0 ? 
                      <>
                        <Table
                              tableHeaderColor="primary"
                              tableHead={['Modo Preparo', 'Tempo Preparo', '']}
                              tableColumn={['nome', 'tempopreparo',  'delete']}
                              tableColumnSize={['30%', '30%', '1%']}
                              tableData={modopreparosel} 
                              onDelete={(id) => handleDeleteModoPreparo(id)}
                                          
                          /><br />
                      </> 
                  : null}

                <GridContainer>
                  <GridItem xs={12} sm={12} md={4}>
                      <Modopreparo value={modopreparo} handleChangeValue={(value) => setModoPreparo(value)} />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>     

                  <InputMask mask="99:99" value={tempopreparo} onChange={(event) => setTempopreparo(event.target.value)}>
                    <TextField 
                          style={{marginTop: 0}}
                          variant="outlined"
                          margin="normal"
                          fullWidth
                          id="tempopreparo"
                          label="Tempo preparo"
                          name="tempopreparo"                                           
                      />              
                    </InputMask>
                  </GridItem>
                  
                  <GridItem xs={12} sm={12} md={4}>
                      <Button color="warning" onClick={() => handleAdicionaModoPreparo()}>ADICIONAR</Button>
                  </GridItem>
                </GridContainer>
                
              </FormControl>   

                <FormControl fullWidth variant="outlined" className={classes.formControl} style={{marginTop: 24}}>
                    <p><strong>Produtos relacionados:</strong></p>  

                    {relacionados && relacionados.length === 0 ? <Box bgcolor="text.primary" color="warning.contrastText" p={2} style={{marginBottom: 20}}><p style={{color: '#FFF'}}>Nenhum produto selecionado.</p></Box> : null}

                    {relacionados && relacionados.length > 0 ? 
                        <>
                          <Table
                                tableHeaderColor="primary"
                                tableHead={['Produto', '']}
                                tableColumn={['nome', 'delete']}
                                tableColumnSize={['80%', '1%']}
                                tableData={relacionados} 
                                onDelete={(id) => handleDeleteProdutoRelacionado(id)}
                                            
                            /><br />
                        </> 
                    : null}

                    <GridContainer>
                      <GridItem xs={12} sm={12} md={8}>       
                        <AutoComplete myRef={selectInputRef}  placeholder="Selecione" resource="products/search" handleChange={e => setProdutoRelacionado(e)} />
                      </GridItem>                 
                      <GridItem xs={12} sm={12} md={4}>
                          <Button color="warning" onClick={() => handleAdicionaProdutoRelacionado()}>ADICIONAR</Button>
                      </GridItem>                  
                    </GridContainer>                                     
                  
                
                </FormControl>   
              </GridItem>
              </GridContainer>
            </> : isLoading ? (
                <Box mt={6}>
                <CircularProgress />
                </Box>               
              ) : null}                  
            </CardBody>

            {loaded ? <CardFooter>
                <Button color="primary" type="submit">Salvar</Button>
              </CardFooter> : null}
           </form>

          </Card>
        </GridItem>
        
      </GridContainer>
    </div>
  );
}
