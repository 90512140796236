import React from "react";
import PropTypes from "prop-types";
import { Link } from 'react-router-dom';

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TextField from '@material-ui/core/TextField';

import Button from 'components/CustomButtons/Button.js';
import Delete from '@material-ui/icons/Delete';
import Edit from '@material-ui/icons/Edit';
import Search from '@material-ui/icons/Search';
import AddAPhoto from '@material-ui/icons/AddAPhoto';
import Checkbox from '@material-ui/core/Checkbox';
import Print from '@material-ui/icons/Print';

// core components
import styles from "assets/jss/material-dashboard-react/components/tableStyle.js";

const useStyles = makeStyles(styles);

export default function CustomTable(props) {
  const classes = useStyles();
  const { tableHead, tableColumn, tableData, resource, tableHeaderColor, tableColumnSize, onView, onEdit, onDelete, onAddPhoto, handleInputChange, handleChangeOrder, onGenerateNFCe } = props;
  return (
    <div className={classes.tableResponsive} style={{ marginTop: 5 }}>
      <Table className={classes.table}>
        {tableHead !== undefined ? (
          <TableHead className={classes[tableHeaderColor + "TableHeader"]}>
            <TableRow className={classes.tableHeadRow}>
              {tableHead.map((prop, key) => {
                return (
                  <TableCell
                    className={classes.tableCell + " " + classes.tableHeadCell}
                    style={{ width: tableColumnSize[key] }}
                    key={key}
                  >
                    {prop}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
        ) : null}
        <TableBody>
          {tableData.map((data, key) => {

            return (
              <TableRow key={key} className={classes.tableBodyRow}>
                {tableColumn.map((prop, key) => {

                  return (
                    <TableCell className={classes.tableCell} key={key}>
                      {data[prop] && prop !== "ordem" && prop !== "whatsapp" ? data[prop] : null}
                      {prop === 'box_color' ? <div style={{ marginTop: 10, backgroundColor: data["color"], width: 30, height: 30 }}></div> : null}
                      {prop === 'banner' ? <img src={`${process.env.REACT_APP_IMAGENS_URL_PREFIX}/banners/th/${data["name"]}`}></img> : null}
                      {prop === 'edit' || (prop === 'edit_payment' && Number(data["sistema"]) !== 1) ? <Link to={`/admin/${resource}/${data["id"]}`}><Button color="info" round><Edit /></Button></Link> : null}
                      {prop === 'view' ? <Button color="info" round onClick={() => onView(data["id"])}><Search /></Button> : null}
                      {prop === 'delete' || (prop === 'delete_payment' && Number(data["sistema"]) !== 1) ? <Button color="danger" round onClick={() => onDelete(data["id"])}><Delete /></Button> : null}
                      {prop === 'foto' ? <Button color="primary" round onClick={() => onAddPhoto(data["id"], data["nome"])}><AddAPhoto /></Button> : null}
                      {prop === 'ordem' ? <input type="text" name="order" id="order" value={data["ordem"]} style={{ width: '60px' }} onChange={(e) => handleInputChange(data["id"], e.target.value)} /> : null}
                      {prop === 'check' ? <Checkbox name="pedidos" onChange={(e) => props.handleSelect(data["id"], e)} checked={props.selected.findIndex(sd => sd === data["id"]) !== -1} /> : null}
                      {prop === 'whatsapp' && data["whatsapp"] ? <a href={`https://api.whatsapp.com/send?phone=55${data["whatsapp"].replace(/[^0-9]/g, '')}`}>{data["whatsapp"]}</a> : null}
                      {prop === 'nfc' && data["status_pg"] === 'paid' ? <Button color="success" round onClick={() => onGenerateNFCe(data["id"])}>NFCe</Button> : null}

                    </TableCell>
                  );
                })}
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </div>
  );
}

CustomTable.defaultProps = {
  tableHeaderColor: "gray",
};

CustomTable.propTypes = {
  tableHeaderColor: PropTypes.oneOf([
    "warning",
    "primary",
    "danger",
    "success",
    "info",
    "rose",
    "gray",
  ]),
  tableHead: PropTypes.arrayOf(PropTypes.string),
};
