import React, { useEffect, useCallback, useState, useRef } from 'react';
import * as Yup from 'yup';

import { useToasts } from 'react-toast-notifications'
import { Link, useParams, useHistory } from 'react-router-dom';
import * as moment from 'moment'
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import { CircularProgress } from '@material-ui/core';
import Box from '@material-ui/core/Box';

import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Modal from '@material-ui/core/Modal';

// core components
import styles from 'assets/jss/material-dashboard-react/views/dashboardStyle.js';
import Table from 'components/Table/Table.js';
import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import Button from 'components/CustomButtons/Button.js';
import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardBody from 'components/Card/CardBody.js';
import CardFooter from 'components/Card/CardFooter.js';

import api from '../../services/api';

const useStyles = makeStyles(styles);


export default function OrderDetail({ open, handleCloseModal, order }) {

  const [status, setStatus] = useState();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState();
  const [dataStatus, setDataStatus] = useState();
  const [total, setTotal] = useState('R$ 0,00');
  const [itens, setItens] = useState([]);
  const [aprovado, setAprovado] = useState('');
  const { addToast } = useToasts()

  const classes = useStyles();

  useEffect(() => {
    if (order !== 0) {
      loadData();
      getPaymentStatusOptions();
    }
  }, [order]);


  const getPaymentStatusOptions = useCallback(async () => {
    try {
      const response = await api.get(`/orders/paymentstatus`);
      setDataStatus(response.data.status_options);
    }
    catch (e) { }
  }, []);

  const loadData = useCallback(async () => {
    try {
      setLoading(true);

      const itens = [];
      const response = await api.get(`/orders/${order}`);

      setData(response.data);
      setStatus(response.data.status?.id);
      setAprovado(response.data.retirada_aprovada);

      let totalv = 0;
      response.data.itens.map(i => {
        itens.push({
          product: `COD: ${i.product.codigo} -  ${i.product.nome}`,
          qtde: i.qtde,
          value: new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          }).format(i.value),
          subtotal: new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          }).format((i.qtde * i.value)),
          discount: i.discount > 0 ? new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          }).format((i.discount)) : 'R$ 0,00',
          coupon: i.coupon ? i.coupon.name : '-x-'
        })

        totalv += Number(i.qtde) * Number(i.value);
      })

      const fretev = Number(response.data.freteValor);
      const descontov = Number(response.data.valordesconto);

      setTotal(new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      }).format(totalv + fretev - descontov));

      setItens(itens);
      setLoading(false);

    }
    catch (e) {
      addToast('Ocorreu um erro !', {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  });

  const handleAprovarRetirada = useCallback(async (event) => {
    if (event.target.checked) {
      setAprovado('S');
    }
    else {
      setAprovado('N');
    }

    await api.patch(`/orders/${order}`, {
      status,
      aprovado: event.target.checked ? 'S' : 'N'
    });

  }, [aprovado, status])

  const updateStatus = useCallback(async () => {
    try {

      await api.patch(`/orders/${order}`, {
        status
      });

      addToast('Atualizado com sucesso', {
        appearance: 'success',
        autoDismiss: true,
      });

    }
    catch (e) {

      addToast(e.response.data.message ? e.response.data.message : 'Ocorreu um erro !', {
        appearance: 'error',
        autoDismiss: true,
      });

    }
  }, [status]);

  return (
    <Modal open={open} style={{ overflow: 'scroll' }}
      disableBackdropClick={false}
      disableEscapeKeyDown={false}
      onBackdropClick={() => handleCloseModal()}
      onEscapeKeyDown={() => handleCloseModal()}
    >
      <GridContainer style={{ padding: 50 }}>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="primary">
              <h4 className={classes.cardTitleWhite}>Dados do pedido {!loading && data && <span>#{data.id}</span>}</h4>
            </CardHeader>
            <CardBody>

              {loading ? (
                <Box mt={6}>
                  <CircularProgress />
                </Box>
              ) : (
                <>
                  <strong>Cliente: </strong> {data.customer.name}<br />
                  <strong>Email: </strong> {data.customer.email}<br />
                  <strong>Telefone: </strong> {data.customer.telefone}<br />
                  <strong>Whatsapp: </strong> {data.customer.whatsapp ? <a href={`https://api.whatsapp.com/send?phone=55${data.customer.whatsapp.replace(/[^0-9]/g, '')}`} target="_blank">{data.customer.whatsapp}</a> : 'Nao Informado'}<br />
                  <strong>Forma pagamento: </strong> {data.paymentform.name}<br />
                  <strong>Valor: </strong> {new Intl.NumberFormat('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                  }).format(data.total)}<br />
                  <strong>Cadastrado em: </strong> {new Date(data.created_at).toLocaleDateString('pt-br', {
                    day: '2-digit',
                    month: 'long',
                    year: 'numeric'
                  })}<br />


                  <h4 style={{ backgroundColor: '#fee', marginTop: 10 }}>Destino do pedido:  <strong>{data.destino === 'R' ? 'Retirada' : 'Entrega'}</strong></h4>


                  {data.destino === 'E' && <><h4 style={{ backgroundColor: '#fee', marginTop: 10, marginBottom: 10 }}>Endereço para entrega</h4>
                    <strong>{data.address?.identificacao ?? '-x-'}</strong><br />
                    {data.address?.endereco} {data.address?.numero} {data.address?.complemento} {data.address?.bairro}<br />{data.address?.cidade} {data.address?.estado} CEP: {data.address?.cep}
                  </>}

                  {data.destino === 'R' && <><h4 style={{ backgroundColor: '#fee', marginTop: 10, marginBottom: 10 }}>Endereço para retirada</h4>
                    <strong>{data.pickuplocation?.name}</strong><br />
                    {data.pickuplocation?.endereco} {data.pickuplocation?.numero} {data.pickuplocation?.complemento} {data.pickuplocation?.bairro}<br />{data.pickuplocation?.cidade} {data.pickuplocation?.estado}
                  </>}

                  <br />
                  <Checkbox name="retirada_aprovada" onChange={(e) => handleAprovarRetirada(e)} checked={aprovado === 'S'} />Aprovar retirada


                  <h4 style={{ backgroundColor: '#fee', marginTop: 10 }}>Itens do pedido</h4>

                  {itens && itens.length > 0 ? <Table
                    tableHeaderColor="primary"
                    tableHead={['Produto', 'Qtde', 'Valor', 'Subtotal']}
                    tableColumn={['product', 'qtde', 'value', 'subtotal']}
                    tableColumnSize={['35%', '20%', '15%', '15%']}
                    tableData={itens}
                    resource="customers"
                    onDelete={(id) => handleDelete(id)} /> : <p style={{ marginTop: 20 }}>Nenhum item cadastrado ao pedido.</p>}


                  <h4 style={{ backgroundColor: '#fee', marginTop: 10, marginBottom: 10 }}>Status do pedido</h4>

                  <FormControl fullWidth variant="outlined" className={classes.formControl}>
                    <InputLabel id="ordem_label">Status atual do pedido:</InputLabel>
                    <Select
                      labelId="status"
                      label="Status Atual do Pedido"
                      variant="outlined"
                      name="status"
                      fullWidth
                      value={status}
                      onChange={(e) => setStatus(e.target.value)}>
                      {dataStatus && dataStatus.map(e => (
                        <MenuItem value={e.id}>
                          <em>{e.name}</em>
                        </MenuItem>
                      ))}
                    </Select>

                  </FormControl>
                  <Button color="warning" type="button" onClick={() => updateStatus()}>Atualizar Status</Button>

                  <h4 style={{ backgroundColor: '#fee', marginTop: 10, marginBottom: 10 }}>Informações adicionais</h4>

                  {data.boleto_url ? <p><strong>Boleto: </strong> <a href={data.boleto_url} target="_blank">{data.boleto_url}</a></p> : null}
                  {data.brand ? <p><strong>Cartão: </strong> {data.brand} - {data.last_digits}</p> : null}
                  <p><strong>Id transação Pagar.Me: </strong> {data.transaction_id}</p>
                  {data.parcelas ? <p><strong>No Parcelas: </strong> {data.parcelas}</p> : null}
                  {data.nsu ? <p><strong>NSU: </strong> {data.nsu}</p> : null}
                  {data.authcode ? <p><strong>COD AUTORIZAÇÃO: </strong> {data.authcode}</p> : null}
                  {data.finished_at ? <p><strong>Pedido finalizado em: </strong> {new Date(data.finished_at).toLocaleDateString('pt-br', {
                    day: '2-digit',
                    month: 'long',
                    year: 'numeric'
                  })}</p> : null}


                  <h4 style={{ backgroundColor: '#fee', marginTop: 10, marginBottom: 10 }}>Entrega: </h4>

                  <p><strong>Data Entrega:</strong> {moment(data.deliveryDate).format("DD/MM/YYYY")}</p>
                  <p><strong>Periodo:</strong> {data.period?.name}</p>

                  {data.freteValor > 0 ? <>
                    <p><strong>Valor:</strong></p>

                    <h4>(+) {new Intl.NumberFormat('pt-BR', {
                      style: 'currency',
                      currency: 'BRL',
                    }).format(data.freteValor)}</h4></> : <p>Grátis</p>}

                  {data.tipodesconto ? <><hr /><h4>Descontos: </h4><br />
                    <p><strong>{data.tipodesconto}</strong></p>
                    <h4>(-) {new Intl.NumberFormat('pt-BR', {
                      style: 'currency',
                      currency: 'BRL',
                    }).format(data.valordesconto)}</h4><hr />
                  </> : null}
                  <h3>Valor recebido: {total}</h3>


                </>

              )}

            </CardBody>
            <CardFooter>
              <Button color="primary" type="button" onClick={() => handleCloseModal()}>Voltar</Button>
            </CardFooter>

          </Card>
        </GridItem>

      </GridContainer>
    </Modal>
  );
}

