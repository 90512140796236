import 'date-fns';
import ptBRLocale from "date-fns/locale/pt-BR";
import React, { useEffect, useCallback, useState, useRef } from 'react';
import * as Yup from 'yup';

import Editor from 'ckeditor5-custom-build/build/ckeditor';
import { CKEditor } from '@ckeditor/ckeditor5-react'

const editorConfiguration = {
  toolbar: ['bold', 'italic', 'fontFamily', 'fontSize', 'fontColor', 'fontBackgroundColor', 'blockquote', 'link']
};

import { useToasts } from 'react-toast-notifications'
import CurrencyTextField from '@unicef/material-ui-currency-textfield'
import { useParams, useHistory } from 'react-router-dom';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';

import { CircularProgress } from '@material-ui/core';
import Box from '@material-ui/core/Box';

import DateFnsUtils from '@date-io/date-fns';
import enLocale from "date-fns/locale/en-US";

import {
  DatePicker,
  TimePicker,
  DateTimePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';

import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';

// core components

import styles from 'assets/jss/material-dashboard-react/views/dashboardStyle.js';
import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import Button from 'components/CustomButtons/Button.js';
import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardBody from 'components/Card/CardBody.js';
import CardFooter from 'components/Card/CardFooter.js';



import api from '../../services/api';
import getValidationErrors from '../../utils/getValidationErrors';

const useStyles = makeStyles(styles);

export default function EditProduct() {
  const [action, setAction] = useState('create');
  const [isLoading, setLoading] = useState(true);
  const [loaded, setLoaded] = useState(false);
  const [code, setCode] = useState('');
  const [type, setType] = useState('Valor');
  const [value, setValue] = useState();
  const [minvalue, setMinValue] = useState();
  const [qtdmin, setQtdMin] = useState('');
  const [descricao, setDescricao] = useState();
  const [ativo, setAtivo] = useState(true);
  const [limiteindividual, setLimiteIndividual] = useState('');
  const [limiteglobal, setLimiteGlobal] = useState('');
  const [dataInicial, setDataInicial] = useState(new Date());
  const [dataFinal, setDataFinal] = useState(new Date());

  const formRef = useRef(null);
  const history = useHistory();
  const { addToast } = useToasts()

  const { id } = useParams();
  const classes = useStyles();



  useEffect(() => {
    if (id != "new") {
      setAction("update");
    }
  }, []);

  useEffect(() => {
    if (action === "update") {
      loadData(id);
    }
    else {
      setLoaded(true);
      setLoading(false);
    }
  }, [action]);


  const handleDataInicialChange = (date) => {
    setDataInicial(date);

  };

  const handleDataFinalChange = (date) => {
    setDataFinal(date);
  };


  const handleSave = async (e) => {

    try {

      e.preventDefault();

      const code = e.target.code.value;
      const valueInput = e.target.value.value;
      const type = e.target.type.value;
      const minvalueInput = e.target.minvalue.value;
      const qtdmin = parseInt(e.target.qtdmin.value);
      const limiteglobal = parseInt(e.target.limiteglobal.value);
      const limiteindividual = parseInt(e.target.limiteindividual.value);

      const value = valueInput.replace(",", ".");
      const minvalue = minvalueInput.replace(",", ".");

      if (type === "Percentual" && value > 100) {
        addToast('Percentual deve ser até 100%', {
          appearance: 'error',
          autoDismiss: true,
        });
        return false;
      }

      if (type === "Valor" && minvalue < 1) {
        addToast('Especifique o valor a partir de', {
          appearance: 'error',
          autoDismiss: true,
        });
        return false;

      }

      if (minvalue < value && type === "Valor") {
        addToast('Valores inválidos !', {
          appearance: 'error',
          autoDismiss: true,
        });
        return false;
      }

      const schema = Yup.object().shape({
        code: Yup.string().required('Código do produto é obrigatório'),
        value: Yup.string().required('Informe o valor'),
        type: Yup.string().required('Informe o tipo'),

      });

      await schema.validate({ code, value, type }, {
        abortEarly: false,
      });

      const postData = {
        code,
        data_inicio: dataInicial,
        data_fim: dataFinal,
        value,
        minvalue,
        qtdmin,
        limiteglobal,
        limiteindividual,
        ativo,
        descricao,
        coupon_type: type,
        ativo,
      };

      if (action === 'update') {
        postData.id = id;
      }

      await api.post(`/coupons`, postData);

      history.push('/admin/coupons');

    }
    catch (e) {


      setLoading(false);

      if (e instanceof Yup.ValidationError) {
        const errors = getValidationErrors(e);

        for (var [, value] of Object.entries(errors)) {
          addToast(value, {
            appearance: 'error',
            autoDismiss: true,
          });
        }
        return;
      }

      addToast(e.response ? e.response.data.message : 'Ocorreu um erro !', {
        appearance: 'error',
        autoDismiss: true,
      });


    }
  };

  const loadData = useCallback(async () => {

    try {

      const response = await api.get(`/coupons/${id}`);
      setCode(response.data.code);
      setValue(response.data.value);
      setMinValue(response.data.minvalue);
      setQtdMin(response.data.qtdmin);
      setLimiteIndividual(response.data.limiteindividual);
      setLimiteGlobal(response.data.limiteglobal);
      setAtivo(response.data.ativo);
      setType(response.data.coupon_type);
      setDescricao(response.data.descricao);
      setDataInicial(response.data.data_inicio);
      setDataFinal(response.data.data_fim);

      setLoaded(true);
      setLoading(false);

    }
    catch (e) {


      addToast('Ocorreu um erro !', {
        appearance: 'error',
        autoDismiss: true,
      });
      history.push("/admin/coupons");

    }
  });


  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>

            <form className={classes.form} ref={formRef} onSubmit={handleSave}>
              <CardHeader color="primary">
                <h4 className={classes.cardTitleWhite}>Dados do cupom</h4>
                <p className={classes.cardCategoryWhite}>Complete os dados.</p>
              </CardHeader>
              <CardBody>

                {loaded ?
                  <>
                    <TextField style={{ marginBottom: 18, marginTop: 18 }}
                      variant="outlined"
                      fullWidth
                      id="code"
                      label="Código"
                      name="code"
                      value={code}
                      required
                      onChange={(e) => setCode(e.target.value.toUpperCase())}
                    />

                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBRLocale}>
                      <DatePicker style={{ marginTop: 15 }} label="Periodo inicial" value={dataInicial} onChange={handleDataInicialChange} format="dd/MM/yyyy" inputVariant="outlined" fullWidth />
                    </MuiPickersUtilsProvider>

                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBRLocale}>
                      <DatePicker style={{ marginTop: 25 }} label="Periodo final" value={dataFinal} onChange={handleDataFinalChange} format="dd/MM/yyyy" inputVariant="outlined" fullWidth />
                    </MuiPickersUtilsProvider>

                    <FormControl fullWidth variant="outlined" className={classes.formControl} style={{ marginTop: 28 }}>
                      <InputLabel id="type">Tipo</InputLabel>
                      <Select
                        labelId="type"
                        label="tipo"
                        variant="outlined"
                        name="type"
                        fullWidth
                        value={type}
                        onChange={(e) => setType(e.target.value)}
                      >
                        <MenuItem key="Valor" value="V">Valor</MenuItem>
                        <MenuItem key="Percentual" value="P">Percentual</MenuItem>
                      </Select>
                    </FormControl>

                    <CurrencyTextField
                      style={{ marginTop: 25 }}
                      label={type === "V" ? "Valor R$" : "Percentual"}
                      variant="standard"
                      value={value}
                      currencySymbol={type === "V" ? "R$" : ""}
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      textAlign="left"
                      outputFormat="string"
                      decimalCharacter=","
                      digitGroupSeparator=""
                      name="value"
                      onChange={(event, value) => setValue(value)}
                    />

                    <CurrencyTextField
                      style={{ marginTop: 25 }}
                      label="Cupom aplicado em compras a partir de"
                      variant="standard"
                      value={minvalue}
                      currencySymbol=""
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      textAlign="left"
                      outputFormat="string"
                      decimalCharacter=","
                      digitGroupSeparator=""
                      name="minvalue"
                      onChange={(event, value) => setMinValue(value)}
                    />


                    <FormControl fullWidth variant="outlined" className={classes.formControl} style={{ marginTop: 18 }}>
                      <TextField
                        variant="outlined"
                        fullWidth
                        id="qtdmin"
                        label="Qtde minima"
                        name="qtdmin"
                        value={qtdmin}
                        required
                        onChange={(e) => setQtdMin(e.target.value)}
                      />
                    </FormControl>


                    <FormControl fullWidth variant="outlined" className={classes.formControl} style={{ marginTop: 28 }}>
                      <TextField
                        variant="outlined"
                        fullWidth
                        id="limiteglobal"
                        label="Limite global"
                        name="limiteglobal"
                        value={limiteglobal}
                        required
                        onChange={(e) => setLimiteGlobal(e.target.value)}
                      />
                    </FormControl>

                    <FormControl fullWidth variant="outlined" className={classes.formControl} style={{ marginTop: 28, marginBottom: 20 }}>
                      <TextField
                        variant="outlined"
                        fullWidth
                        id="limiteindividual"
                        label="Limite individual"
                        name="limiteindividual"
                        value={limiteindividual}
                        required
                        onChange={(e) => setLimiteIndividual(e.target.value)}
                      />
                    </FormControl>

                    <FormControl fullWidth variant="outlined" className={classes.formControl} style={{ marginTop: 28 }}>
                      <InputLabel id="ativo">Ativo ?</InputLabel>
                      <Select
                        labelId="ativo"
                        label="ativo no site"
                        variant="outlined"
                        name="ativo"
                        fullWidth
                        value={ativo}
                        onChange={(e) => setAtivo(e.target.value)}
                      >
                        <MenuItem key="A1" value={true}>Sim</MenuItem>
                        <MenuItem key="A2" value={false}>Não</MenuItem>
                      </Select>
                    </FormControl>

                  </> : isLoading ? (
                    <Box mt={6}>
                      <CircularProgress />
                    </Box>
                  ) : null}

              </CardBody>
              {loaded ? <CardFooter>
                <Button color="primary" type="submit">Salvar</Button>
              </CardFooter> : null}
            </form>

          </Card>
        </GridItem>

      </GridContainer>
    </div>
  );
}
