import React, {useEffect, useCallback, useState, useRef} from 'react';
import * as Yup from 'yup';
import { useToasts } from 'react-toast-notifications'
import { useParams, useHistory } from 'react-router-dom';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';

// core components
import styles from 'assets/jss/material-dashboard-react/views/dashboardStyle.js';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import TextField from '@material-ui/core/TextField';
import Button from 'components/CustomButtons/Button.js';
import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardBody from 'components/Card/CardBody.js';
import CardFooter from 'components/Card/CardFooter.js';


import api from '../../services/api';
import getValidationErrors from '../../utils/getValidationErrors';

import { CircularProgress } from '@material-ui/core';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles(styles);

export default function EditPickupLocation() {
  const [action, setAction] = useState('create');
  const [name, setName] = useState('');
  const [endereco, setEndereco] = useState('');
  const [numero, setNumero] = useState('');
  const [complemento, setComplemento] = useState('');
  const [bairro, setBairro] = useState('');
  const [cidade, setCidade] = useState('Rio de Janeiro');
  const [estado, setEstado] = useState('RJ');
  const [zone, setZone] = useState('');
  const [zones, setZones] = useState();

  const [loading, setLoading] = useState(false);

  const formRef = useRef(null);
  const history = useHistory();
  const { addToast } = useToasts();

  const { id } = useParams();   
  const classes = useStyles();

  useEffect(() => {
    if (id != "new"){
      setAction("update");
    }
    loadZones();
  },[]);

  useEffect(() => {
    if (action === "update"){
      loadData(id);
    }
  },[action]);

  const loadZones = useCallback(async () => {    
    try {
      const response = await api.get(`/shipping/zone`);
      setZones(response.data);  

    }
    catch(e){
      console.log(e.message);
    }
  });


  const handleSave = useCallback(async (e) => {

    try { 

      e.preventDefault();  
      setLoading(true);
      
      const name = e.target.name.value;
      const zone = e.target.zone.value;  

      const schema = Yup.object().shape({
        name: Yup.string().required('Nome é obrigatório !'),    
        zone: Yup.string().required('Especifique uma zona !')          
      });         

      await schema.validate({name, zone}, {
        abortEarly: false,
      });
    

      const formData = {
        name,
        endereco,
        numero,
        complemento,
        bairro,
        cidade,
        estado,
        zone
      }
     
      if (action === 'update'){
          formData.id = id;
      }
 
      await api.post('/orders/pickuplocation', formData);
      setLoading(false);
   
      history.push('/admin/pickuplocation');
   
    }
    catch(e){ 

      setLoading(false);

      if (e instanceof Yup.ValidationError) {
        const errors = getValidationErrors(e); 

        for (var [, value] of Object.entries(errors)) {
          addToast(value, {
            appearance: 'error',
            autoDismiss: true,
          });
        }
        return;
      }    
      
      addToast(e.response ? e.response.data.message : 'Ocorreu um erro !', {
        appearance: 'error',
        autoDismiss: true,
      });
            
    }
  },[action, name, endereco, numero, complemento, bairro, cidade, estado, zone]);

  const loadData = useCallback(async () => {    
    try {
      const response = await api.get(`/orders/pickuplocation/${id}`);
      setName(response.data.name);    
      setEndereco(response.data.endereco);
      setNumero(response.data.numero);
      setComplemento(response.data.complemento);
      setBairro(response.data.bairro);
      setCidade(response.data.cidade);
      setEstado(response.data.estado);
      setZone(response.data.zone?.id);
      
    }
    catch(e){

      addToast(e.response.data.message ? e.response.data.message : 'Ocorreu um erro !', {
        appearance: 'error',
        autoDismiss: true,
      });       
      
      history.push("/admin/order/pickuplocation");
    }
  });


  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
          <form className={classes.form} ref={formRef} onSubmit={handleSave}>
            <CardHeader color="primary">
              <h4 className={classes.cardTitleWhite}>Dados do ponto de retirada</h4>
              <p className={classes.cardCategoryWhite}>Complete os dados.</p>
            </CardHeader>
            <CardBody>
           
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                 <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      id="name"
                      label="Nome"
                      name="name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      autoFocus
                  />   

               </GridItem>               


               <GridItem xs={12} sm={12} md={12}>
                 <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      id="endereco"
                      label="Endereço"
                      name="endereco"
                      value={endereco}
                      onChange={(e) => setEndereco(e.target.value)}
                     
                  />   
               </GridItem>               

               <GridItem xs={12} sm={12} md={12}>
                 <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      id="numero"
                      label="Numero"
                      name="numero"
                      value={numero}
                      onChange={(e) => setNumero(e.target.value)}
                      
                  />   
               </GridItem>               

               <GridItem xs={12} sm={12} md={12}>
                 <TextField
                      variant="outlined"
                      margin="normal"                     
                      fullWidth
                      id="complemento"
                      label="Complemento"
                      name="complemento"
                      value={complemento}
                      onChange={(e) => setComplemento(e.target.value)}
                      
                  />   
               </GridItem>               


               <GridItem xs={12} sm={12} md={12}>
                 <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      id="bairro"
                      label="Bairro"
                      name="bairro"
                      value={bairro}
                      onChange={(e) => setBairro(e.target.value)}
                     
                  />   
               </GridItem>               

               <GridItem xs={12} sm={12} md={12}>
                 <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      id="cidade"
                      label="Cidade"
                      name="cidade"
                      value={cidade}
                      onChange={(e) => setCidade(e.target.value)}
                     
                  />   
               </GridItem>   

                 <GridItem xs={12} sm={12} md={12}>
                 <TextField
                      variant="outlined"
                      margin="normal"
                      maxLength={2}
                      type="text"
                      required
                      fullWidth
                      id="estado"
                      label="Estado"
                      name="estado"
                      value={estado}
                      onChange={(e) => setEstado(e.target.value.toLocaleUpperCase())}
                      
                  />   
               </GridItem>         
  

              </GridContainer>

              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
            
                     <FormControl fullWidth variant="outlined" className={classes.formControl} style={{marginTop: 18}}>
                        <InputLabel id="zona">Zona</InputLabel>             
                        <Select 
                                labelId="zona"
                                label="Zone"
                                variant="outlined"                  
                                name="zone"
                                required
                                fullWidth
                                value={zone}
                                onChange={(e) => setZone(e.target.value)}              
                                >    
                                {zones && zones.map(z => (<MenuItem key={z.id} value={z.id}>{z.name}</MenuItem>))}                                               
                        </Select>
                    </FormControl>
               </GridItem>
               </GridContainer>

              
             
            </CardBody>
            <CardFooter>
              {!loading ? <Button color="primary" type="submit">Salvar</Button> : <Box mt={6}>
             <CircularProgress />
            </Box>}
            </CardFooter>
          </form>
          </Card>
        </GridItem>
        
      </GridContainer>
    </div>
  );
}
