import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import { useToasts } from 'react-toast-notifications'
import { useParams, useHistory } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'assets/css/react-confirm-alert.css'; // Import css
import { CircularProgress } from '@material-ui/core';
import Box from '@material-ui/core/Box';

import styles from 'assets/jss/material-dashboard-react/views/dashboardStyle.js';


import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import Table from 'components/Table/Table.js';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';
import Card from 'components/Card/Card';
import Button from 'components/CustomButtons/Button.js';

import api from '../../services/api';

const useStyles = makeStyles(styles);

export default function VouchersPage() {
  const classes = useStyles();
  const [isLoading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  const { addToast } = useToasts()
  const history = useHistory();

  useEffect(() => {
      getData();
  }, []);


  const getData = useCallback(async () => {
    try {
      setLoading(true); 
      const response = await api.get("/vouchers");    
      const vouchers = [];
      response.data.map(c => {
          vouchers.push({
            id: c.id,
            code: c.code,
            pontos: c.pontos,
            value: new Intl.NumberFormat('pt-BR', { 
              style: 'currency', 
              currency: 'BRL', 
            }).format(Number(c.value)),
            ativo: c.ativo ? 'SIM' : 'NÃO'           
          })
      })

      setLoading(false);
      setData(vouchers);
    }
    catch(e){
      setLoading(false);
      addToast('Ocorreu um erro para atualizar os dados', {
        appearance: 'error',
        autoDismiss: true,
      });        
    } 
  },[]);

  const handleDelete = useCallback(async (id) => {

    confirmAlert({
      title: 'Confirmação',
      message: 'Tem certeza que deseja remover este registro ?',
      buttons: [
        {
          label: 'Sim',
          onClick: () => postDeleteAction(id)
        },
        {
          label: 'Não'         
        }
      ]
    });

  },[]);

  const postDeleteAction =  useCallback(async (id) => {
      try {
          await api.delete(`/vouchers/${id}`);       
          setData(data => data.filter(d => d.id != id));
      }
      catch(e){
        addToast(e.response.data.message, {
          appearance: 'error',
          autoDismiss: true,
        });        
      }
  },[]);

  
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card> 
          <CardHeader color="primary">
            <h4 className={classes.cardTitleWhite}>Vales Compra</h4>
            <p className={classes.cardCategoryWhite}>
              Vale compras cadastrados no sistema.
            </p>
          </CardHeader>
          <CardBody> 
                
          <Link to="/admin/vouchers/new"> <Button color="info" style={{marginTop: 20}}>Adicionar novo vale compra</Button></Link>
 
          {data && data.length > 0 ? 
            <Table
                tableHeaderColor="primary"
                tableHead={['Codigo', 'Pontos', 'Valor', 'Ativo', '', '']}
                tableColumn={['code', 'pontos', 'value', 'ativo', 'edit', 'delete']}
                tableColumnSize={['30%', '30%', '20%', '10%', '1%', '1%']}
                tableData={data} 
                resource="vouchers"            
                onDelete={(id) => handleDelete(id)}               
            /> : isLoading ? (
              <Box mt={6}>
               <CircularProgress />
              </Box>               
           ) : (           
             <p style={{marginTop: 20}}>Nenhum vale compra cadastrado no momento.</p>
           )}
          </CardBody>
        </Card>
      </GridItem>

    </GridContainer>
  );
}
