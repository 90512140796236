import { useEffect, useState, useContext, useCallback } from 'react';

export default function Enderecos({ enderecos, handleSelecionaEndereco }) {

    return (
        <>
            {enderecos && enderecos.length > 0 && <div>
                <br />
                <h5>Endereços previamente utilizados.</h5>

                {enderecos.map(a => (

                    <a href="#" onClick={() => handleSelecionaEndereco(a)}>
                        <address>
                            <strong>{a.identificacao}</strong> - {a.default} {a.endereco}, {a.numero} {a.complemento} {a.bairro} {a.cidade} / {a.estado} - {a.cep}
                        </address>
                    </a>
                ))}

            </div>}


        </>
    )
}