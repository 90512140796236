import React, { useEffect, useCallback, useState, useRef } from 'react';
import * as Yup from 'yup';
import InputMask from "react-input-mask";
import { useToasts } from 'react-toast-notifications'
import CurrencyTextField from '@unicef/material-ui-currency-textfield'
import { CircularProgress } from '@material-ui/core';

import Box from '@material-ui/core/Box';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';

import Editor from 'ckeditor5-custom-build/build/ckeditor';
import { CKEditor } from '@ckeditor/ckeditor5-react'

const editorConfiguration = {
  toolbar: ['bold', 'italic', 'fontFamily', 'fontSize', 'fontColor', 'fontBackgroundColor', 'blockquote', 'link']
};

// core components
import styles from 'assets/jss/material-dashboard-react/views/dashboardStyle.js';
import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import TextField from '@material-ui/core/TextField';
import Button from 'components/CustomButtons/Button.js';
import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardBody from 'components/Card/CardBody.js';
import CardFooter from 'components/Card/CardFooter.js';

import api from '../../services/api';
import getValidationErrors from '../../utils/getValidationErrors';

const useStyles = makeStyles(styles);

export default function Configurations() {

  const configurationID = '05fbd608-f20d-4b3c-aec6-78bbf8ea03f4';

  const [loaded, setLoaded] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [numDiasAntecedencia, setNumDiasAntecedencia] = useState('1');
  const [horaLimite, setHoraLimite] = useState('16:00');
  const [telefonewhatsapp, setTelefoneWhatsapp] = useState();
  const [telefonecontato01, setTelefoneContato01] = useState();
  const [telefonecontato02, setTelefoneContato02] = useState();
  const [telefonecontato03, setTelefoneContato03] = useState();
  const [localizacao, setLocalizacao] = useState();
  const [emailcontato, setEmailContato] = useState();
  const [qtdediasCarrinhoPerdido, setQtdeDiasCarrinhoPerdido] = useState('');
  const [textoConfirmacaoPedido, setTextoConfirmacaoPedido] = useState('');
  const [textoMudancaStatus, setTextoMudancaStatus] = useState('');
  const [textoCarrinhoPerdido, setTextoCarrinhoPerdido] = useState('');
  const [textoGanhoCupom, setTextoGanhoCupom] = useState('');

  const [carrinhoPerdido, setCarrinhoPerdido] = useState(false);

  const [valorPonto, setValorPonto] = useState();
  const [valorFreteGratis, setValorFreteGratis] = useState();
  const [valorMinimoCompra, setValorMinimoCompra] = useState();

  const [cupomamigo, setCupomamigo] = useState(false);
  const [cupomamigotipo, setCupomamigotipo] = useState('P');
  const [cupomamigovalor, setCupomamigovalor] = useState();

  const [primeiracompra, setPrimeiracompra] = useState(false);
  const [primeiracompratipo, setPrimeiracompratipo] = useState('P');
  const [primeiracompravalor, setPrimeiracompravalor] = useState();

  const [horaExpressaInicial, setHoraExpressaInicial] = useState();
  const [horaExpressaFinal, setHoraExpressaFinal] = useState();

  const formRef = useRef(null);
  const { addToast } = useToasts()
  const classes = useStyles();

  useEffect(() => {
    loadData();
  }, []);

  const handleSave = async (e) => {

    try {

      e.preventDefault();

      const txt_confirmacaopedido = textoConfirmacaoPedido;
      const txt_carrinhoperdido = textoCarrinhoPerdido;
      const txt_mudancastatus = textoMudancaStatus;
      const txt_ganhocupom = textoGanhoCupom;

      const qtdedias_carrinhoperdido = e.target.qtdediasCarrinhoPerdido.value;
      const emailcontato = e.target.emailcontato.value;
      const id = configurationID;

      const cupomamigotipoInput = e.target.cupomamigotipo ? e.target.cupomamigotipo.value : '';
      const cupomamigovalorInput = e.target.cupomamigovalor ? e.target.cupomamigovalor.value : '';

      const primeiracompratipoInput = e.target.primeiracompratipo ? e.target.primeiracompratipo.value : '';
      const primeiracompravalorInput = e.target.primeiracompravalor ? e.target.primeiracompravalor.value : '';

      const horaLimite = e.target.horaLimite ? e.target.horaLimite.value : '16:00';
      const numDiasAntecedencia = e.target.numDiasAntecedencia ? e.target.numDiasAntecedencia.value : '1';

      const horaExpressaInicial = e.target.horaExpressaInicial ? e.target.horaExpressaInicial.value : '';
      const horaExpressaFinal = e.target.horaExpressaFinal ? e.target.horaExpressaFinal.value : '';

      if (cupomamigotipo === 'P' && cupomamigovalor > 100) {
        addToast('Valor percentual do cupom amigo inválido !', {
          appearance: 'error',
          autoDismiss: true,
        });
        return;
      }

      if (primeiracompratipo === 'P' && primeiracompravalor > 100) {
        addToast('Valor percentual de primeira compra inválido !', {
          appearance: 'error',
          autoDismiss: true,
        });
        return;
      }

      const schema = Yup.object().shape({
        qtdedias_carrinhoperdido: Yup.number()
          .typeError('Informe um número de dias válido')
          .required('Informe a qtde dias para notificação de carrinho perdido')
          .min(1, 'Mínimo de 1 para o campo número de dias para notificação de carrinho perdido'),
        telefonewhatsapp: Yup.string()
          .required('Numero de telefone de whatsapp da empresa é obrigatório'),
        emailcontato: Yup.string().required('Informe um email')
      });

      await schema.validate({ qtdedias_carrinhoperdido, telefonewhatsapp, emailcontato }, {
        abortEarly: false,
      });


      const postData = {
        id,
        txt_confirmacaopedido,
        txt_carrinhoperdido,
        txt_mudancastatus,
        txt_ganhocupom,
        qtdedias_carrinhoperdido,
        valor_frete_gratis: valorFreteGratis ? valorFreteGratis.replace(",", ".") : 0,
        valor_minimo_pedido: valorMinimoCompra ? valorMinimoCompra.replace(",", ".") : 0,
        telefonewhatsapp,
        telefonecontato01,
        telefonecontato02,
        telefonecontato03,
        localizacao,
        emailcontato,
        valor_ponto: valorPonto ? valorPonto.replace(",", ".") : 0,
        cupom_amigo: cupomamigo,
        cupom_amigo_tipo: cupomamigo ? cupomamigotipoInput : '',
        cupom_amigo_valor: cupomamigo && cupomamigovalorInput ? cupomamigovalorInput.replace(",", ".") : 0,
        primeira_compra: primeiracompra,
        primeira_compra_tipo: primeiracompra ? primeiracompratipoInput : '',
        primeira_compra_valor: primeiracompra && primeiracompravalorInput ? primeiracompravalorInput.replace(",", ".") : 0,
        horaLimite,
        numDiasAntecedencia,
        horaExpressaInicial,
        horaExpressaFinal
      };

      await api.post(`/configurations`, postData);

      addToast('Dados atualizados com sucesso', {
        appearance: 'success',
        autoDismiss: true,
      });

    }
    catch (e) {

      if (e instanceof Yup.ValidationError) {
        const errors = getValidationErrors(e);

        for (var [, value] of Object.entries(errors)) {
          addToast(value, {
            appearance: 'error',
            autoDismiss: true,
          });
        }
        return;
      }

      addToast(e.response ? e.response.data.message : 'Ocorreu um erro !', {
        appearance: 'error',
        autoDismiss: true,
      });

    }
  }


  const loadData = useCallback(async () => {
    try {

      setLoading(true);
      const response = await api.get(`/configurations/${configurationID}`);

      if (response.data) {

        setEmailContato(response.data.emailcontato);
        setTelefoneContato01(response.data.telefonecontato01);
        setTelefoneContato02(response.data.telefonecontato02);
        setTelefoneContato03(response.data.telefonecontato03);
        setTelefoneWhatsapp(response.data.telefonewhatsapp);
        setLocalizacao(response.data.localizacao);

        setTextoCarrinhoPerdido(response.data.txt_carrinhoperdido);
        setTextoConfirmacaoPedido(response.data.txt_confirmacaopedido);
        setTextoMudancaStatus(response.data.txt_mudancastatus);
        setTextoGanhoCupom(response.data.txt_ganhocupom);

        setQtdeDiasCarrinhoPerdido(response.data.qtdedias_carrinhoperdido);

        setValorFreteGratis(response.data.valor_frete_gratis);
        setValorMinimoCompra(response.data.valor_minimo_pedido);
        setValorPonto(response.data.valor_ponto);

        setCupomamigo(response.data.cupom_amigo);
        setCupomamigotipo(response.data.cupom_amigo_tipo);
        setCupomamigovalor(response.data.cupom_amigo_valor);

        setPrimeiracompra(response.data.primeira_compra);
        setPrimeiracompratipo(response.data.primeira_compra_tipo);
        setPrimeiracompravalor(response.data.primeira_compra_valor);

        setHoraLimite(response.data.horaLimite);
        setNumDiasAntecedencia(String(response.data.numDiasAntecedencia));

        setHoraExpressaInicial(response.data.horaExpressaInicial);
        setHoraExpressaFinal(response.data.horaExpressaFinal);

      }

      setLoaded(true);
      setLoading(false);

    }
    catch (e) {

      addToast(e.response.data.message ? e.response.data.message : 'Ocorreu um erro !', {
        appearance: 'error',
        autoDismiss: true,
      });

    }
  }, []);


  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <form className={classes.form} ref={formRef} onSubmit={handleSave}>
              <CardHeader color="primary">
                <h4 className={classes.cardTitleWhite}>Configurações gerais</h4>
                <p className={classes.cardCategoryWhite}>Complete os dados.</p>
              </CardHeader>
              <CardBody>

                {loaded ? <div>

                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <p><strong>Dados contato da Empresa:</strong></p>
                      <InputMask mask="(99) 999999999" value={telefonewhatsapp} onChange={(event) => setTelefoneWhatsapp(event.target.value)}>
                        <TextField
                          variant="outlined"
                          margin="normal"
                          required
                          fullWidth
                          id="telefonewhatsapp"
                          label="Telefone Whatsapp"
                        />
                      </InputMask>
                    </GridItem>

                    <GridItem xs={12} sm={12} md={12}>
                      <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        type="text"
                        id="emailcontato"
                        label="Email Contato"
                        name="emailcontato"
                        value={emailcontato}
                        onChange={(event) => setEmailContato(event.target.value)}
                      />
                    </GridItem>

                    <GridItem xs={12} sm={12} md={12}>
                      <TextField
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        type="text"
                        id="localizacao"
                        label="Localização"
                        name="localizacao"
                        value={localizacao}
                        onChange={(event) => setLocalizacao(event.target.value)}

                      />
                    </GridItem>


                    <GridItem xs={12} sm={12} md={4}>

                      <InputMask mask="(99) 999999999" value={telefonecontato01} onChange={(event) => setTelefoneContato01(event.target.value)}>
                        <TextField
                          variant="outlined"
                          margin="normal"
                          type="text"
                          id="telefonecontato01"
                          label="Telefone Contato 01"
                          name="telefonecontato01"
                          fullWidth />
                      </InputMask>

                    </GridItem>

                    <GridItem xs={12} sm={12} md={4}>
                      <InputMask mask="(99) 999999999" value={telefonecontato02} onChange={(event) => setTelefoneContato02(event.target.value)}>
                        <TextField
                          variant="outlined"
                          margin="normal"
                          fullWidth
                          type="text"
                          id="telefonecontato02"
                          label="Telefone Contato 02"
                          name="telefonecontato02"

                        />
                      </InputMask>
                    </GridItem>

                    <GridItem xs={12} sm={12} md={4}>
                      <InputMask mask="(99) 999999999" value={telefonecontato03} onChange={(event) => setTelefoneContato03(event.target.value)}>
                        <TextField
                          variant="outlined"
                          margin="normal"
                          fullWidth
                          type="text"
                          id="telefonecontato03"
                          label="Telefone Contato 03"
                          name="telefonecontato03"

                        />
                      </InputMask>
                    </GridItem>


                    <GridItem xs={12} sm={12} md={12}>

                      <br />
                      <p><strong>Regras de antecedência para os pedidos:</strong></p>
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={6}>
                          <InputMask mask="99" maskPlaceholder="" value={numDiasAntecedencia} onChange={(event) => setNumDiasAntecedencia(event.target.value)}>
                            <TextField
                              variant="outlined"
                              margin="normal"
                              required
                              fullWidth
                              id="numDiasAntecedencia"
                              label="Número de dias para antecedência"
                            />
                          </InputMask>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6}>
                          <InputMask mask="99:00" maskPlaceholder="" value={horaLimite} onChange={(event) => setHoraLimite(event.target.value)}>
                            <TextField
                              variant="outlined"
                              margin="normal"
                              required
                              fullWidth
                              id="horaLimite"
                              label="Horário máximo para disponibilidade do dia"
                            />
                          </InputMask>
                        </GridItem>
                      </GridContainer>
                    </GridItem>



                    <GridItem xs={12} sm={12} md={12}>

                      <br />
                      <p><strong>Intervalo horário para entregas expressas:</strong></p>

                      <GridContainer>
                        <GridItem xs={12} sm={12} md={6}>
                          <InputMask mask="99:99" maskPlaceholder="" value={horaExpressaInicial} onChange={(event) => setHoraExpressaInicial(event.target.value)}>
                            <TextField
                              variant="outlined"
                              margin="normal"
                              fullWidth
                              id="horaExpressaInicial"
                              label="Horário inicial para entrega expressa"
                            />
                          </InputMask>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6}>
                          <InputMask mask="99:99" maskPlaceholder="" value={horaExpressaFinal} onChange={(event) => setHoraExpressaFinal(event.target.value)}>
                            <TextField
                              variant="outlined"
                              margin="normal"
                              fullWidth
                              id="horaExpressaFinal"
                              label="Horário final para entrega expressa"
                            />
                          </InputMask>
                        </GridItem>
                      </GridContainer>
                    </GridItem>


                    <GridItem xs={12} sm={12} md={12}>

                      <br />
                      <p><strong>Regras de compra:</strong></p>

                      <CurrencyTextField
                        style={{ marginTop: 25 }}
                        label="Valor minimo de compra"
                        variant="standard"
                        value={valorMinimoCompra}
                        currencySymbol="R$"
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        required
                        textAlign="left"
                        outputFormat="string"
                        decimalCharacter=","
                        digitGroupSeparator=""
                        name="valorMinimoCompra"
                        onChange={(event, value) => setValorMinimoCompra(value)}
                      />

                    </GridItem>

                    <GridItem xs={12} sm={12} md={12}>

                      <CurrencyTextField
                        style={{ marginTop: 25 }}
                        label="Frete gratis a partir de R$"
                        variant="standard"
                        value={valorFreteGratis}
                        currencySymbol="R$"
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        required
                        textAlign="left"
                        outputFormat="string"
                        decimalCharacter=","
                        digitGroupSeparator=""
                        name="valorFreteGratis"
                        onChange={(event, value) => setValorFreteGratis(value)}
                      />

                    </GridItem>

                    <GridItem xs={12} sm={12} md={12}>

                      <CurrencyTextField
                        style={{ marginTop: 25 }}
                        label="Valor R$ para 1 ponto ganho"
                        variant="standard"
                        value={valorPonto}
                        currencySymbol="R$"
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        textAlign="left"
                        outputFormat="string"
                        decimalCharacter=","
                        digitGroupSeparator=""
                        name="valorPonto"
                        onChange={(event, value) => setValorPonto(value)}
                      />

                    </GridItem>

                    <GridItem xs={12} sm={12} md={12}>
                      <FormControl fullWidth variant="outlined" className={classes.formControl} style={{ marginTop: 18 }}>
                        <InputLabel id="primeiracompra">Habilitar desconto primeira compra?</InputLabel>
                        <Select
                          labelId="primeiracompra"
                          label="Habilitar desconto primeira compra ?"
                          variant="outlined"
                          name="primeiracompra"
                          fullWidth
                          value={primeiracompra}
                          onChange={(e) => setPrimeiracompra(e.target.value)}
                        >
                          <MenuItem key="A1" value={true}>Sim</MenuItem>
                          <MenuItem key="A2" value={false}>Não</MenuItem>
                        </Select>
                      </FormControl>
                    </GridItem>


                    {primeiracompra ? <><GridItem xs={12} sm={12} md={12}>
                      <FormControl fullWidth variant="outlined" className={classes.formControl} style={{ marginTop: 28 }}>
                        <InputLabel id="primeiracompratipo">Tipo desconto primeira compra</InputLabel>
                        <Select
                          labelId="primeiracompra"
                          label="Tipo desconto primeira compra"
                          variant="outlined"
                          name="primeiracompratipo"
                          fullWidth
                          value={primeiracompratipo}
                          onChange={(e) => setPrimeiracompratipo(e.target.value)}
                        >
                          <MenuItem key="P" value={"P"}>Percentual</MenuItem>
                          <MenuItem key="V" value={"V"}>Valor</MenuItem>
                        </Select>
                      </FormControl>
                    </GridItem>
                      <GridItem xs={12} sm={12} md={12}>
                        <CurrencyTextField
                          style={{ marginTop: 25 }}
                          label={primeiracompratipo === 'V' ? 'Valor' : 'Percentual'}
                          variant="standard"
                          value={primeiracompravalor}
                          currencySymbol={primeiracompratipo === 'V' ? 'R$' : ''}
                          variant="outlined"
                          margin="normal"
                          fullWidth
                          textAlign="left"
                          outputFormat="string"
                          decimalCharacter=","
                          digitGroupSeparator=""
                          name="primeiracompravalor"
                          onChange={(event, value) => setPrimeiracompravalor(value)}
                        />

                      </GridItem></> : null}

                    <GridItem xs={12} sm={12} md={12}>
                      <FormControl fullWidth variant="outlined" className={classes.formControl} style={{ marginTop: 28 }}>
                        <InputLabel id="cupomamigo">Habilitar cupom amigo?</InputLabel>
                        <Select
                          labelId="cupomamigo"
                          label="Habilitar cupom amigo?"
                          variant="outlined"
                          name="cupomamigo"
                          fullWidth
                          value={cupomamigo}
                          onChange={(e) => setCupomamigo(e.target.value)}
                        >
                          <MenuItem key="A1" value={true}>Sim</MenuItem>
                          <MenuItem key="A2" value={false}>Não</MenuItem>
                        </Select>
                      </FormControl>
                    </GridItem>

                    {cupomamigo ? <>
                      <GridItem xs={12} sm={12} md={12}>
                        <FormControl fullWidth variant="outlined" className={classes.formControl} style={{ marginTop: 28 }}>
                          <InputLabel id="cupomamigotipo">Tipo desconto Cupom Amigo</InputLabel>
                          <Select
                            labelId="cupomamigotipo"
                            label="Tipo desconto cupom amigo"
                            variant="outlined"
                            name="cupomamigotipo"
                            fullWidth
                            value={cupomamigotipo}
                            onChange={(e) => setCupomamigotipo(e.target.value)}
                          >
                            <MenuItem key="P" value={"P"}>Percentual</MenuItem>
                            <MenuItem key="V" value={"V"}>Valor</MenuItem>
                          </Select>
                        </FormControl>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={12}>
                        <CurrencyTextField
                          style={{ marginTop: 25 }}
                          label={cupomamigotipo === 'V' ? 'Valor' : 'Percentual'}
                          variant="standard"
                          value={cupomamigovalor}
                          currencySymbol={cupomamigotipo === 'V' ? 'R$' : ''}
                          variant="outlined"
                          margin="normal"
                          fullWidth
                          textAlign="left"
                          outputFormat="string"
                          decimalCharacter=","
                          digitGroupSeparator=""
                          name="cupomamigovalor"
                          onChange={(event, value) => setCupomamigovalor(value)}
                        />

                      </GridItem></> : null}

                  </GridContainer>

                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12} style={{ marginTop: 18 }}>
                      <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="name"
                        type="number"
                        label="Qtde dias notificação carrinho perdido"
                        name="qtdediasCarrinhoPerdido"
                        value={qtdediasCarrinhoPerdido}
                        onChange={(e) => setQtdeDiasCarrinhoPerdido(e.target.value)}
                      />
                    </GridItem>
                  </GridContainer>


                  <br />
                  <h4>Texto email confirmação do pedido</h4>
                  <CKEditor
                    editor={Editor}
                    config={editorConfiguration}
                    data={textoConfirmacaoPedido}
                    onReady={(editor) => {
                      editor.editing.view.change((writer) => {
                        writer.setStyle(
                          "height",
                          "200px",
                          editor.editing.view.document.getRoot()
                        );
                      });
                    }}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      setTextoConfirmacaoPedido(data);
                    }}
                  />
                  <br />
                  <h4>Texto email mudança status do pedido</h4>
                  <CKEditor
                    editor={Editor}
                    config={editorConfiguration}
                    data={textoMudancaStatus}
                    onReady={(editor) => {
                      editor.editing.view.change((writer) => {
                        writer.setStyle(
                          "height",
                          "200px",
                          editor.editing.view.document.getRoot()
                        );
                      });
                    }}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      setTextoMudancaStatus(data);
                    }}
                  />
                  <br />
                  <h4>Texto email ganho de cupom</h4>
                  <CKEditor
                    editor={Editor}
                    config={editorConfiguration}
                    data={textoGanhoCupom}
                    onReady={(editor) => {
                      editor.editing.view.change((writer) => {
                        writer.setStyle(
                          "height",
                          "200px",
                          editor.editing.view.document.getRoot()
                        );
                      });
                    }}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      setTextoGanhoCupom(data);
                    }}
                  />

                  {carrinhoPerdido &&
                    <>
                      <h4>Texto email carrinho de compras perdido - em andamento</h4>
                      <CKEditor
                        editor={Editor}
                        config={editorConfiguration}
                        data={textoCarrinhoPerdido}
                        onReady={(editor) => {
                          editor.editing.view.change((writer) => {
                            writer.setStyle(
                              "height",
                              "200px",
                              editor.editing.view.document.getRoot()
                            );
                          });
                        }}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          setTextoCarrinhoPerdido(data);
                        }}
                      />
                    </>
                  }

                </div> : isLoading ? (
                  <Box mt={6}>
                    <CircularProgress />
                  </Box>
                ) : null}

              </CardBody>
              <CardFooter>
                {loaded ? <Button color="primary" type="submit">Salvar</Button> : null}
              </CardFooter>
            </form>
          </Card>
        </GridItem>

      </GridContainer>

    </div>
  );
}
