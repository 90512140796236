/*eslint-disable*/
import React, {useEffect, useCallback, useState} from "react";
import PropTypes from "prop-types";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';

// core components
import styles from "assets/jss/material-dashboard-react/components/footerStyle.js";

import api from '../../services/api';

const useStyles = makeStyles(styles);

export default function City({value, handleChangeValue, marginTop, state}) {

  useEffect(() => {
     loadCities();
  },[state]); 

  const [cities, setCities] = useState([]);

  const loadCities = useCallback(async() => {

    try {
      const response = await api.post("/shipping/city/search", {
        filtros: {
          state
        }
      });
      setCities(response.data);
    }
    catch(e){}

  },[state]);

  const classes = useStyles();
  return (
    <FormControl fullWidth variant="outlined" className={classes.formControl} style={{marginTop: marginTop}}>
    <InputLabel id="state">Cidade</InputLabel> 
        <Select 
            labelId="city"
            label="cidade"
            variant="outlined"                  
            name="city"
            fullWidth
            value={value ? value : ''}
            onChange={(e) => handleChangeValue(e.target.value)}              
            >  
            <MenuItem key="T" value="T">Selecione</MenuItem>                                   
            {cities.length > 0 && cities.map(ct => {
               return <MenuItem key={ct.id} value={ct.id}>{ct.name}</MenuItem>        
            })}                               
      </Select>
   </FormControl>
  );
}
