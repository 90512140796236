/*eslint-disable*/
import React, {useEffect, useCallback, useState} from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import FormControl from '@material-ui/core/FormControl';
import Checkbox from '@material-ui/core/Checkbox';
import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';

// core components
import styles from "assets/jss/material-dashboard-react/components/footerStyle.js";

import api from '../../services/api';

const useStyles = makeStyles(styles);

export default function OpcoesStatus({ handleSelect, selected }) {

  useEffect(() => {
     loadData();
  },[]); 

  const [data, setData] = useState([]);

  const loadData = useCallback(async() => {
    try {
      const response = await api.get("/orders/paymentstatus"); 
      setData(response.data.status_options);
    }
    catch(e){}
  },[]);

  const classes = useStyles();
  return (
    <FormControl fullWidth variant="outlined" className={classes.formControl}>
       <GridContainer>     
        {data && data.map(os => {

          let existe = selected.findIndex(sl => sl === os.id);
                          
          return (
            <GridItem xs={6} sm={6} md={3} key={os.id}>
              <Checkbox name={os.name}  checked={existe!==-1} onChange={(event) => handleSelect(os.id, event)} />{os.name}
            </GridItem>
          )
        })}     

        <GridItem xs={6} sm={6} md={3}>
          <Checkbox name="Pendente autorização retirada" onChange={(event) => handleSelect('autorizacao', event)} />Pendente autorização retirada
        </GridItem>
        </GridContainer> 
 
   </FormControl>
  );
}
