import React, { useEffect, useCallback, useState, useRef } from 'react';
import { useToasts } from 'react-toast-notifications'
import { makeStyles } from '@material-ui/core/styles';
import { CircularProgress } from '@material-ui/core';
import Box from '@material-ui/core/Box';

import Modal from '@material-ui/core/Modal';

// core components
import styles from 'assets/jss/material-dashboard-react/views/dashboardStyle.js';
import Button from 'components/CustomButtons/Button.js';
import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardBody from 'components/Card/CardBody.js';
import CardFooter from 'components/Card/CardFooter.js';

import api from '../../services/api';

const useStyles = makeStyles(styles);


export default function OrderNFCe({ open, handleCloseModal, order }) {

    const [loading, setLoading] = useState(true);
    const [data, setData] = useState();
    const [info, setInfo] = useState();
    const { addToast } = useToasts()

    const classes = useStyles();

    useEffect(() => {
        if (order !== 0) {
            loadData();
        }
    }, [order]);


    const loadData = useCallback(async () => {
        try {
            setLoading(true);

            const itens = [];
            const response = await api.get(`/orders/${order}`);
            setData(response.data);

            console.log(response.data.itens);

            setInfo('Atualizando dados dos produtos');


        }
        catch (e) {
            addToast('Ocorreu um erro !', {
                appearance: 'error',
                autoDismiss: true,
            });
        }
    });

    return (
        <Modal open={open} style={{ overflow: 'scroll' }}
            disableBackdropClick={false}
            disableEscapeKeyDown={false}
            onBackdropClick={() => handleCloseModal()}
            onEscapeKeyDown={() => handleCloseModal()}
        >
            <GridContainer style={{ padding: 50 }}>
                <GridItem xs={12} sm={12} md={12}>
                    <Card>
                        <CardHeader color="primary">
                            <h4 className={classes.cardTitleWhite}>Dados do pedido {!loading && data && <span>#{data.id}</span>}</h4>
                        </CardHeader>
                        <CardBody style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                            {loading ? (
                                <Box mt={3} >
                                    <CircularProgress />
                                    <h4>{info}</h4>
                                </Box>
                            ) : (
                                <>

                                </>
                            )}
                        </CardBody>
                        <CardFooter>
                            <Button color="primary" type="button" onClick={() => handleCloseModal()}>Voltar</Button>
                        </CardFooter>

                    </Card>
                </GridItem>

            </GridContainer>
        </Modal>
    );
}

