import React, { useEffect, useCallback, useState, useRef } from 'react';
import * as Yup from 'yup';
import * as moment from 'moment'
import InputMask from "react-input-mask";
import { useToasts } from 'react-toast-notifications'
import { Link, useParams, useLocation, useHistory } from 'react-router-dom';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import { CircularProgress } from '@material-ui/core';
import Box from '@material-ui/core/Box';

// core components
import styles from 'assets/jss/material-dashboard-react/views/dashboardStyle.js';
import Table from 'components/Table/Table.js';
import GridItem from 'components/Grid/GridItem.js';
import MenuItem from '@material-ui/core/MenuItem';
import GridContainer from 'components/Grid/GridContainer.js';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';

import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Button from 'components/CustomButtons/Button.js';
import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardBody from 'components/Card/CardBody.js';
import CardFooter from 'components/Card/CardFooter.js';

import api from '../../services/api';
import getValidationErrors from '../../utils/getValidationErrors';

const useStyles = makeStyles(styles);


export default function EditCustomer() {

  const [loading, setLoading] = useState(false);
  const [totalPontos, setTotalPontos] = useState(0);
  const [historicoPontos, setHistoricoPontos] = useState();
  const [data, setData] = useState();
  const [tipo, setTipo] = useState('PF');
  const [action, setAction] = useState('');

  const formRef = useRef(null);
  const history = useHistory();

  const { addToast } = useToasts()

  const { id } = useParams();

  const classes = useStyles();

  useEffect(() => {
    if (id !== 'new') {
      setLoading(true);
      loadData(id);
      setAction('edit')
    }
    else {
      setAction('new');
    }
  }, []);


  const loadData = useCallback(async () => {
    try {

      setLoading(true);

      const response = await api.get(`/customers/${id}`);
      setData(response.data);
      setTipo(response.data.type);

      setLoading(false);

      const responseP = await api.post(`/customers/rewards/admin`, {
        customer_id: id
      });

      const pontosHistorico = [];
      if (responseP.data.pontos && responseP.data.pontos.length > 0) {
        responseP.data.pontos.map(p => {
          pontosHistorico.push({
            pedido: p.order?.id,
            data: new Date(p.data_ganho).toLocaleDateString('pt-br', {
              day: '2-digit',
              month: 'long',
              year: 'numeric'
            }),
            qtde_entrada: p.qtde_entrada,
            qtde_saida: p.qtde_saida,
            description: p.description,
            expira: new Date(p.expires_at).toLocaleDateString('pt-br', {
              day: '2-digit',
              month: 'long',
              year: 'numeric'
            }),
          })
        })

        setHistoricoPontos(pontosHistorico);
      }

      setTotalPontos(responseP.data.total);


    }
    catch (e) {

      addToast(e.response ? e.response.data.message : 'Ocorreu um erro !', {
        appearance: 'error',
        autoDismiss: true,
      });

      history.push("/admin/customers");

    }

  });

  const handleSave = useCallback(async (e) => {

    try {

      e.preventDefault();

      const name = e.target.name.value;
      const email = e.target.email.value;
      const type = e.target.type.value;
      const telefone = e.target.telefone.value;
      const whatsapp = e.target.whatsapp.value;
      const data_nascimento = e.target.data_nascimento.value;
      const document = e.target.document.value;
      const password = e.target.password.value;
      const razao = e.target?.razao?.value;

      if (type === 'PF') {
        const schema = Yup.object().shape({
          name: Yup.string().required('Nome é obrigatório !'),
          email: Yup.string().required('Email é obrigatório !'),

        });

        await schema.validate({ name, email }, {
          abortEarly: false,
        });
      }
      else {
        const schema = Yup.object().shape({
          razao: Yup.string().required('Razao Social é obrigatória !'),
          email: Yup.string().required('Email é obrigatório !'),

        });

        await schema.validate({ razao, email }, {
          abortEarly: false,
        });
      }

      if (action === 'new' && !password) {
        addToast('Especifique a senha', {
          appearance: 'error',
          autoDismiss: true,
        });
        return false;
      }

      const data = {
        name,
        email,
        type,
        telefone,
        whatsapp,
        data_nascimento,
        document,
        razao
      }

      if (action === 'edit') {
        data.id = id;
      }

      if (password) {
        data.password = password;
      }

      if (action === 'new') {
        await api.post(`customers`, data);
      }
      else {
        await api.post(`customers/update`, data);
      }

      history.push('/admin/customers');

    }
    catch (e) {

      setLoading(false);
      if (e instanceof Yup.ValidationError) {
        const errors = getValidationErrors(e);

        for (var [, value] of Object.entries(errors)) {
          addToast(value, {
            appearance: 'error',
            autoDismiss: true,
          });
        }
        return;
      }

      addToast(e.response?.data?.message ? e.response.data.message : 'Ocorreu um erro !', {
        appearance: 'error',
        autoDismiss: true,
      });

    }
  }, [action]);


  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <form className={classes.form} ref={formRef} onSubmit={handleSave}>
              <CardHeader color="primary">
                <h4 className={classes.cardTitleWhite}>Dados do cliente</h4>
              </CardHeader>
              <CardBody>

                {loading ? (
                  <Box mt={6}>
                    <CircularProgress />
                  </Box>
                ) : (
                  <>

                    <GridContainer>
                      <GridItem xs={12} sm={12} md={12}>

                        <FormControl fullWidth variant="outlined" className={classes.formControl} style={{ marginTop: 20 }}>
                          <InputLabel id="type">Tipo Cliente</InputLabel>
                          <Select
                            labelId="tipo"
                            label="Tipo de cliente"
                            variant="outlined"
                            name="type"
                            fullWidth
                            defaultValue={tipo}
                            onChange={(e) => setTipo(e.target.value)}
                          >
                            <MenuItem key="PF" value="PF">Pessoa Fisica</MenuItem>
                            <MenuItem key="PJ" value="PJ">Pessoa Juridica</MenuItem>
                          </Select>
                        </FormControl>
                      </GridItem>
                    </GridContainer>

                    {tipo === 'PF' && <GridContainer>
                      <GridItem xs={12} sm={12} md={12}>
                        <TextField
                          variant="outlined"
                          margin="normal"
                          fullWidth
                          id="name"
                          label="Nome"
                          name="name"
                          defaultValue={data?.name}
                          autoFocus
                        />
                      </GridItem>
                    </GridContainer>}

                    {tipo === 'PJ' && <GridContainer>
                      <GridItem xs={12} sm={12} md={12}>
                        <TextField
                          variant="outlined"
                          margin="normal"
                          fullWidth
                          id="razao"
                          label="Razão Social"
                          name="razao"
                          defaultValue={data?.razao_social}
                          autoFocus
                        />
                      </GridItem>
                    </GridContainer>}

                    <GridContainer>
                      <GridItem xs={12} sm={12} md={12}>

                        {tipo === 'PF' && <TextField
                          variant="outlined"
                          margin="normal"
                          InputLabelProps={{ shrink: true }}
                          placeholder="CPF"
                          id="document"
                          name="document"
                          label="CPF"
                          className="form-control"
                          fullWidth
                          InputProps={{
                            inputComponent: InputMask,
                            inputProps: {
                              mask: '999.999.999-99',
                              defaultValue: data?.document
                            }
                          }}
                        />}

                        {tipo === 'PJ' && <TextField
                          variant="outlined"
                          margin="normal"
                          InputLabelProps={{ shrink: true }}
                          placeholder="CNPJ"
                          id="document"
                          name="document"
                          label="CNPJ"
                          className="form-control"
                          fullWidth
                          InputProps={{
                            inputComponent: InputMask,
                            inputProps: {
                              mask: '99.999.999/9999-99',
                              defaultValue: data?.document
                            }
                          }}
                        />}


                      </GridItem>
                    </GridContainer>


                    <GridContainer>
                      <GridItem xs={12} sm={12} md={12}>
                        <TextField
                          variant="outlined"
                          margin="normal"
                          fullWidth
                          id="email"
                          label="Email"
                          name="email"
                          defaultValue={data?.email}
                          style={{ marginTop: 15 }}
                        />
                      </GridItem>
                    </GridContainer>

                    <GridContainer>
                      <GridItem xs={12} sm={12} md={12}>

                        <TextField
                          variant="outlined"
                          margin="normal"
                          InputLabelProps={{ shrink: true }}
                          placeholder="Telefone"
                          id="telefone"
                          name="telefone"
                          label="Telefone"
                          className="form-control"
                          fullWidth
                          InputProps={{
                            inputComponent: InputMask,
                            inputProps: {
                              mask: '(99) 999999999',
                              defaultValue: data?.telefone
                            }
                          }}
                        />

                      </GridItem>
                    </GridContainer>

                    <GridContainer>
                      <GridItem xs={12} sm={12} md={12}>

                        <TextField
                          variant="outlined"
                          InputLabelProps={{ shrink: true }}
                          margin="normal"
                          placeholder="Whatsapp"
                          id="whatsapp"
                          name="whatsapp"
                          label="Whatsapp"
                          className="form-control"
                          fullWidth
                          InputProps={{
                            inputComponent: InputMask,
                            inputProps: {
                              mask: '(99) 999999999',
                              defaultValue: data?.whatsapp
                            }
                          }}
                        />

                        {data?.whatsapp && <a href={`https://api.whatsapp.com/send?phone=55${data.whatsapp.replace(/[^0-9]/g, '')}`} target="_blank">Enviar Mensagem pelo whatsapp</a>}
                      </GridItem>

                    </GridContainer>



                    <GridContainer>
                      <GridItem xs={12} sm={12} md={12}>

                        <TextField
                          variant="outlined"
                          InputLabelProps={{ shrink: true }}
                          margin="normal"
                          placeholder="Data de nascimento"
                          id="data_nascimento"
                          name="data_nascimento"
                          label="Data de nascimento"
                          className="form-control"
                          fullWidth
                          InputProps={{
                            inputComponent: InputMask,
                            inputProps: {
                              mask: '99/99/9999',
                              maskChar: '',
                              defaultValue: data?.data_nascimento ? moment(data.data_nascimento).format('DD/MM/YYYY') : null
                            }
                          }}
                        />


                      </GridItem>
                    </GridContainer>


                    <GridContainer>
                      <GridItem xs={12} sm={12} md={12}>

                        <TextField
                          variant="outlined"
                          margin="normal"
                          InputLabelProps={{ shrink: true }}
                          placeholder="Senha"
                          id="password"
                          name="password"
                          label="Senha"

                          className="form-control"
                          fullWidth
                        />

                      </GridItem>
                    </GridContainer>

                  </>)}


                {data && <>
                  {historicoPontos && historicoPontos.length > 0 ?
                    <Table
                      tableHeaderColor="primary"
                      tableHead={['Pedido N#', 'Data', 'Descrição', 'Qtde pontos ganhos', 'Qtde pontos expirados / utilizados', 'Expira em']}
                      tableColumn={['pedido', 'data', 'description', 'qtde_entrada', 'qtde_saida', 'expira']}
                      tableColumnSize={['10%', '10%', '30%', '10%', '10%', '20%']}
                      tableData={historicoPontos}
                      resource="customers"
                      onDelete={(id) => handleDelete(id)}
                    /> : <p style={{ marginTop: 20 }}>Nenhum ponto registrado no momento.</p>}
                </>}
                {data && <p>Total Pontos acumulados: {totalPontos}</p>}


              </CardBody>
              <CardFooter>
                <Button color="primary" type="submit">Salvar</Button>
                <Link to="/admin/customers"><Button color="warning" type="button">Voltar</Button></Link>
              </CardFooter>
            </form>
          </Card>
        </GridItem>

      </GridContainer>
    </div>
  );
}
