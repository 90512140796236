import DayPicker from 'react-day-picker';
import moment from 'moment';
import 'react-day-picker/lib/style.css';
import MomentLocaleUtils from 'react-day-picker/moment';
import { useEffect, useState } from 'react';

moment.defineLocale('pt-br', {
    months: 'janeiro_fevereiro_março_abril_maio_junho_julho_agosto_setembro_outubro_novembro_dezembro'.split(
        '_'
    ),
    monthsShort: 'jan_fev_mar_abr_mai_jun_jul_ago_set_out_nov_dez'.split('_'),
    weekdays: 'domingo_segunda-feira_terça-feira_quarta-feira_quinta-feira_sexta-feira_sábado'.split(
        '_'
    ),
    weekdaysShort: 'dom_seg_ter_qua_qui_sex_sáb'.split('_'),
    weekdaysMin: 'D_S_T_Q_Q_S_S'.split('_'),
    weekdaysParseExact: true,
    longDateFormat: {
        LT: 'HH:mm',
        LTS: 'HH:mm:ss',
        L: 'DD/MM/YYYY',
        LL: 'D [de] MMMM [de] YYYY',
        LLL: 'D [de] MMMM [de] YYYY [às] HH:mm',
        LLLL: 'dddd, D [de] MMMM [de] YYYY [às] HH:mm',
    },
    calendar: {
        sameDay: '[Hoje às] LT',
        nextDay: '[Amanhã às] LT',
        nextWeek: 'dddd [às] LT',
        lastDay: '[Ontem às] LT',
        lastWeek: function () {
            return this.day() === 0 || this.day() === 6
                ? '[Último] dddd [às] LT' // Saturday + Sunday
                : '[Última] dddd [às] LT'; // Monday - Friday
        },
        sameElse: 'L',
    },
    relativeTime: {
        future: 'em %s',
        past: 'há %s',
        s: 'poucos segundos',
        ss: '%d segundos',
        m: 'um minuto',
        mm: '%d minutos',
        h: 'uma hora',
        hh: '%d horas',
        d: 'um dia',
        dd: '%d dias',
        M: 'um mês',
        MM: '%d meses',
        y: 'um ano',
        yy: '%d anos',
    },
    dayOfMonthOrdinalParse: /\d{1,2}º/,
    invalidDate: 'Data inválida',
});


export default function Calendar({ disabledDays, handleSelectedDay, day }) {

    const handleClickDay = (day, modifiers: any = {}) => {
        if (modifiers.disabled) {
            return;
        }
        handleSelectedDay(day);
    }


    return (
        <>
            <DayPicker
                localeUtils={MomentLocaleUtils}
                locale={'pt-br'}
                onDayClick={handleClickDay}
                disabledDays={disabledDays}
                month={day ? new Date(day.getFullYear(), day.getMonth()) : new Date()}
                selectedDays={day ? day : null}
            />
        </>
    )

}

