import React, {useEffect, useCallback, useState, useRef} from 'react';
import * as Yup from 'yup';
import { useToasts } from 'react-toast-notifications'
import { useParams, useHistory } from 'react-router-dom';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';

// core components
import styles from 'assets/jss/material-dashboard-react/views/dashboardStyle.js';
import Checkbox from '@material-ui/core/Checkbox';
import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import TextField from '@material-ui/core/TextField';
import Button from 'components/CustomButtons/Button.js';
import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardBody from 'components/Card/CardBody.js';
import CardFooter from 'components/Card/CardFooter.js';
import CropImage from 'components/CropImage/CropImage';

import api from '../../services/api';
import getValidationErrors from '../../utils/getValidationErrors';

import { CircularProgress } from '@material-ui/core';
import Box from '@material-ui/core/Box';

import Editor from 'ckeditor5-custom-build/build/ckeditor';
import { CKEditor } from '@ckeditor/ckeditor5-react'

const editorConfiguration = { 
  toolbar: [ 'bold', 'italic', 'fontFamily', 'fontSize', 'fontColor', 'fontBackgroundColor',  'blockquote', 'link' ] 
}; 


const useStyles = makeStyles(styles);

export default function EditCategory() {
  const [action, setAction] = useState('create');
  const [name, setName] = useState('');
  const [descricao, setDescricao] = useState('');
  const [cropping, setCropping] = useState(false);
  const [loading, setLoading] = useState(false);
  const [removerImagem, setRemoverImagem] = useState(false);
  const [imagem, setImagem] = useState('');
  const [imagem2, setImagem2] = useState('');
  const [selectedFile, setSelectedFile] = useState('');
  const [selectedFile2, setSelectedFile2] = useState('');
  const formRef = useRef(null);
  const history = useHistory();
  const { addToast } = useToasts();

  const { id } = useParams();   
  const classes = useStyles();

  useEffect(() => {
    if (id != "new"){
      setAction("update");
    }
  },[]);

  useEffect(() => {
    if (action === "update"){
      loadData(id);
    }
  },[action]);


  const handleSave = useCallback(async (e) => {

    try { 

      e.preventDefault();  
      setLoading(true);
      
      const name = e.target.name.value;

      const schema = Yup.object().shape({
        name: Yup.string().required('Nome é obrigatório !'),              
      });         

      await schema.validate({name}, {
        abortEarly: false,
      });

      const formData = new FormData();
      formData.append('name', name);

      if (selectedFile){
         formData.append('image_01', selectedFile);
      }

      if (selectedFile2){
        formData.append('image_02', selectedFile2);
      }

      if (descricao){
        formData.append('descricao', descricao);
      }

      if (removerImagem){
          formData.append('removerImagem', removerImagem);
      }

      if (action === 'update'){
          formData.append('id', id);
      }
 
      await api.post('/categories', formData);

      setLoading(false);
   
      history.push('/admin/categories');
   
    }
    catch(e){ 

      setLoading(false);

      if (e instanceof Yup.ValidationError) {
        const errors = getValidationErrors(e); 

        for (var [, value] of Object.entries(errors)) {
          addToast(value, {
            appearance: 'error',
            autoDismiss: true,
          });
        }
        return;
      }    
      
      addToast(e.response ? e.response.data.message : 'Ocorreu um erro !', {
        appearance: 'error',
        autoDismiss: true,
      });
            
    }
  },[action, selectedFile, selectedFile2, descricao, removerImagem]);

  const loadData = useCallback(async () => {    
    try {
      const response = await api.get(`/categories/${id}`);

      setName(response.data.name);    
      setImagem(response.data.image);
      setImagem2(response.data.image2);
      if (response.data.descricao){
        setDescricao(response.data.descricao);
      }
    }
    catch(e){

      addToast(e.response.data.message ? e.response.data.message : 'Ocorreu um erro !', {
        appearance: 'error',
        autoDismiss: true,
      });       
      
      history.push("/admin/categories");
    }
  });

  const handleFile01 = e => {
    const fileReader = new FileReader()
    fileReader.onloadend = () => {
        setSelectedFile(fileReader.result);
    }   
    fileReader.readAsDataURL(e.target.files[0])
  }

  const handleFile02 = e => {
    const fileReader = new FileReader()
    fileReader.onloadend = () => {
        setSelectedFile2(fileReader.result);
    }   
    fileReader.readAsDataURL(e.target.files[0])
  }


  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
          <form className={classes.form} ref={formRef} onSubmit={handleSave}>
            <CardHeader color="primary">
              <h4 className={classes.cardTitleWhite}>Dados da Categoria</h4>
              <p className={classes.cardCategoryWhite}>Complete os dados.</p>
            </CardHeader>
            <CardBody>
           
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                 <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      id="name"
                      label="Nome"
                      name="name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      autoFocus
                  />      
                              
                  {imagem || imagem2 ? <><Checkbox checked={removerImagem} onChange={(event) => setRemoverImagem(!removerImagem)} name="remover" />Remover imagens</> : null}

                  <p>Imagem 01</p>  
                  <CropImage width={500} height={500} imagem={`${process.env.REACT_APP_IMAGENS_URL_PREFIX}/categories/rs/${imagem}`} onChange={(file) => setSelectedFile(file)} name="image01.jpg" uploadImage={null} /> 

                  <hr />

                  <p>Imagem 02</p>  
                  <CropImage width={500} height={166} imagem={`${process.env.REACT_APP_IMAGENS_URL_PREFIX}/categories/rs/${imagem2}`} onChange={(file) => setSelectedFile2(file)} name="imagem02.jpg" uploadImage={null}  /> 

                  <hr />
                  <p>Descrição</p>

                  <CKEditor
                      editor={ Editor }
                      config={ editorConfiguration }
                      data={descricao}
                      onReady={(editor) => {       
                        editor.editing.view.change((writer) => {
                        writer.setStyle(
                            "height",
                            "400px",
                            editor.editing.view.document.getRoot()
                        );
                        });
                      }}    
                      onChange={ ( event, editor ) => {
                        const data = editor.getData();                      
                        setDescricao(data);
                    } }           
                />
               </GridItem>               
              </GridContainer>
             
            </CardBody>
            <CardFooter>
              {!loading ? <Button color="primary" type="submit">Salvar</Button> : <Box mt={6}>
             <CircularProgress />
            </Box>}
            </CardFooter>
          </form>
          </Card>
        </GridItem>
        
      </GridContainer>
    </div>
  );
}
