import React, { useEffect, useCallback, useState, useRef } from 'react';
import * as Yup from 'yup';
import { useToasts } from 'react-toast-notifications'
import { useParams, useHistory } from 'react-router-dom';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';

// core components
import styles from 'assets/jss/material-dashboard-react/views/dashboardStyle.js';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';

import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';

import Button from 'components/CustomButtons/Button.js';
import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardBody from 'components/Card/CardBody.js';
import CardFooter from 'components/Card/CardFooter.js';
import State from 'components/State/State.js';
import City from 'components/City/City.js';
import CurrencyTextField from '@unicef/material-ui-currency-textfield'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import MultipleSelection from 'components/MultipleSelection/MultipleSelection.js';



import api from '../../services/api';
import getValidationErrors from '../../utils/getValidationErrors';

import { CircularProgress } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { set } from 'lodash-es';


const useStyles = makeStyles(styles);

export default function EditCategory() {
  const [action, setAction] = useState('create');
  const [state, setState] = useState('');
  const [city, setCity] = useState('');
  const [filtros, setFiltros] = useState('');
  const [valor, setValor] = useState('');
  const [valorExpresso, setValorExpresso] = useState('');
  const [name, setName] = useState('');
  const [neighborhoods, setNeighborhoods] = useState([]);
  const [loading, setLoading] = useState(false);
  const [weekdays, setWeekdays] = useState([]);
  const formRef = useRef(null);
  const history = useHistory();
  const { addToast } = useToasts()

  const { id } = useParams();
  const classes = useStyles();

  const periods = [
    {
      id: 1,
      name: 'MANHA'
    },
    {
      id: 2,
      name: 'TARDE'
    },
    {
      id: 3,
      name: 'NOITE'
    },
    {
      id: 6,
      name: 'HORARIO COMERCIAL'
    },
  ];

  const wdays = [
    {
      id: 1,
      name: "DOM"
    },
    {
      id: 2,
      name: "SEG"
    },
    {
      id: 3,
      name: "TER"
    },
    {
      id: 4,
      name: "QUA"
    },
    {
      id: 5,
      name: "QUI"
    },
    {
      id: 6,
      name: "SEX"
    },
    {
      id: 7,
      name: "SAB"
    },
  ];

  useEffect(() => {
    if (id != "new") {
      setAction("update");
    }
  }, []);

  useEffect(() => {
    if (action === "update") {
      loadData(id);
    }
  }, [action]);

  useEffect(() => {

    setCity('T');
    setNeighborhoods([]);
  }, [state]);

  useEffect(() => {

    if (city && state) {
      setFiltros({
        city,
        state
      })
    }



  }, [state, city]);


  const handleSelectWeekday = useCallback(async (p, w, e) => {

    if (e.target.checked) {
      setWeekdays([...weekdays, {
        sel: `${w}${p}`,
        weekday: w,
        period: p
      }]);
    }
    else {
      setWeekdays(weekdays.filter(wk => wk.sel !== `${w}${p}`));
    }


  }, [weekdays]);

  const handleSave = useCallback(async (e) => {

    try {

      e.preventDefault();

      setLoading(true);

      const nameInput = e.target.name.value;
      const stateInput = state;
      const cityInput = city;
      const valorInput = e.target.valor.value;
      const valorExpressoInput = e.target.valorExpresso.value;

      const schema = Yup.object().shape({
        nameInput: Yup.string().required('Nome é obrigatório !'),
        stateInput: Yup.string().required('Estado / UF é obrigatório !'),
        cityInput: Yup.string().required('Cidade é obrigatório !'),
        valorInput: Yup.string().required('Valor é obrigatório !'),
      });

      await schema.validate({ nameInput, stateInput, cityInput, valorInput }, {
        abortEarly: false,
      });

      const formData = {
        state: stateInput,
        city: cityInput,
        name: nameInput,
        valor: valorInput.replace(",", "."),
        valorExpresso: valorExpressoInput ? valorExpressoInput.replace(",", ".") : '0.00',
        weekdays,
      };

      formData.neighborhoods = neighborhoods;

      if (action === 'update') {
        formData.id = id;
      }

      await api.post('/shipping/zone', formData);

      setLoading(false);
      history.push('/admin/zones');



    }
    catch (e) {

      setLoading(false);

      if (e instanceof Yup.ValidationError) {
        const errors = getValidationErrors(e);

        for (var [, value] of Object.entries(errors)) {
          addToast(value, {
            appearance: 'error',
            autoDismiss: true,
          });
        }
        return;
      }

      addToast(e.response ? e.response.data.message : 'Ocorreu um erro !', {
        appearance: 'error',
        autoDismiss: true,
      });


    }
  }, [action, state, city, weekdays, neighborhoods]);

  const loadData = useCallback(async () => {
    try {

      const response = await api.get(`/shipping/zone/${id}`);

      setName(response.data.name);
      setState(response.data.state.id);
      setCity(response.data.city.id);
      setValor(response.data.valor);
      setValorExpresso(response.data.valorExpresso);

      const selectedNeighborhoods = [];

      if (response.data.neighborhoods) {
        response.data.neighborhoods.map(n => {
          selectedNeighborhoods.push(n.id);
        })
      }

      setNeighborhoods(selectedNeighborhoods);

      const week = [];

      if (response.data.weekdays) {
        response.data.weekdays.map(wp => {

          week.push({
            sel: `${wp.weekday.id}${wp.period.id}`,
            weekday: wp.weekday.id,
            period: wp.period.id
          });

        })
      }

      setWeekdays(week);

    }
    catch (e) {

      addToast(e.response.data.message ? e.response.data.message : 'Ocorreu um erro !', {
        appearance: 'error',
        autoDismiss: true,
      });

      history.push("/admin/zones");

    }
  });




  const handleChangeNeighborhoods = useCallback(async (id, checked) => {

    if (checked) {
      setNeighborhoods(neighborhoods => [...neighborhoods, id]);
    }
    else {
      setNeighborhoods(neighborhoods => neighborhoods.filter(c => c !== id));
    }

  }, [neighborhoods]);

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <form className={classes.form} ref={formRef} onSubmit={handleSave}>
              <CardHeader color="primary">
                <h4 className={classes.cardTitleWhite}>Dados da Zona de frete</h4>
                <p className={classes.cardCategoryWhite}>Complete os dados.</p>
              </CardHeader>
              <CardBody>

                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <State value={state} handleChangeValue={(value) => { setState(value); setCity(''); }} marginTop={20} />
                  </GridItem>
                </GridContainer>

                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <City value={city} state={state} handleChangeValue={(value) => setCity(value)} marginTop={20} />
                  </GridItem>
                </GridContainer>

                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      id="name"
                      label="Nome"
                      name="name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      autoFocus
                    />
                  </GridItem>
                </GridContainer>

                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <CurrencyTextField
                      style={{ marginTop: 25 }}
                      label="Valor"
                      variant="standard"
                      value={valor}
                      currencySymbol="R$"
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      textAlign="left"
                      outputFormat="string"
                      decimalCharacter=","
                      digitGroupSeparator=""
                      name="valor"
                      onChange={(event, value) => setValor(value)}
                    />
                  </GridItem>
                </GridContainer>


                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <CurrencyTextField
                      style={{ marginTop: 25 }}
                      label="Valor Entrega Expressa"
                      variant="standard"
                      value={valorExpresso}
                      currencySymbol="R$"
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      textAlign="left"
                      outputFormat="string"
                      decimalCharacter=","
                      digitGroupSeparator=""
                      name="valorExpresso"
                      onChange={(event, value) => setValorExpresso(value)}
                    />
                  </GridItem>
                </GridContainer>

                <br />

                <h4>Especifique os dias de semana e periodos</h4><br />

                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    {wdays.map(w => {
                      return (
                        <>
                          <h4><strong>{w.name}</strong></h4>
                          {periods.map(p => {

                            let existe = weekdays.findIndex(wp => wp.sel === `${w.id}${p.id}`);

                            return (
                              <><Checkbox name={p.name} checked={existe !== -1} onChange={(event) => handleSelectWeekday(p.id, w.id, event)} /> {p.name}</>
                            )
                          })}
                          <br />
                        </>
                      )
                    })}
                  </GridItem>
                </GridContainer>

                {city !== "T" && state !== "T" ? <MultipleSelection selected={neighborhoods} resource={"shipping/neighborhood/search"} filtros={filtros} handleChange={handleChangeNeighborhoods} label="Selecione os bairros" boxcolor marginTop={20} /> : null}

                {!city || !state ? <h4><br />Para seleção dos bairros, selecione o estado e a cidade.</h4> : null}


              </CardBody>
              <CardFooter>
                {!loading ? <Button color="primary" type="submit">Salvar</Button> : <Box mt={6}>
                  <CircularProgress />
                </Box>}
              </CardFooter>
            </form>
          </Card>
        </GridItem>

      </GridContainer>
    </div>
  );
}
