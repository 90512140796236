import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import { useToasts } from 'react-toast-notifications'
import { useParams, useHistory } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'assets/css/react-confirm-alert.css'; // Import css
import { CircularProgress } from '@material-ui/core';
import Box from '@material-ui/core/Box';

import styles from 'assets/jss/material-dashboard-react/views/dashboardStyle.js';

import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import Category from 'components/Category/Category.js';
import Subcategory from 'components/Subcategory/Subcategory.js';
import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import Table from 'components/Table/Table.js';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';
import Card from 'components/Card/Card';
import Button from 'components/CustomButtons/Button.js';

import api from '../../services/api';

const useStyles = makeStyles(styles);

export default function ProductsPage() {
  const classes = useStyles();
  const [isLoading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [nome, setNome] = useState('');
  const [codigo, setCodigo] = useState('');
  const [ativo, setAtivo] = useState('T');
  const [destaque, setDestaque] = useState('T');  
  const [category, setCategory] = useState('T');
  const [subcategory, setSubcategory] = useState('T');
  const [sortData, setSortData] = useState([]);
  const { addToast } = useToasts()
  const history = useHistory();

  useEffect(() => {
      getData();
  }, []);


  const getData = useCallback(async () => {
    try {
      const products = [];  
      setLoading(true); 
      setData([]);

      const response = await api.post("/products/search", {
         filtros: {
            ativo, 
            destaque,
            category_id: category,
            subcategory_id: subcategory,
            codigo,
            nome
         }       
      });

      const order = [];

      if (response.data){
        response.data.map(p => {
          products.push({
            id: p.id,
            nome: p.nome,
            codigo: p.codigo,
            tipo: p.type === 'I' ? 'Individual' : 'Kit',
            category: p.category?.name,
            valor_de: new Intl.NumberFormat('pt-BR', { 
              style: 'currency', 
              currency: 'BRL', 
              }).format(p.valor_de),
            valor_por: new Intl.NumberFormat('pt-BR', { 
              style: 'currency', 
              currency: 'BRL', 
              }).format(p.valor_por),
            ordem: p.ordem,
            ativo: p.ativo ? p.ativo = 'Sim' : 'Não',
            destaque: p.destaque ? p.destaque = 'Sim' : 'Não',
            stock: p.stock.length > 0 ? p.stock[0].quantidade : '-x-'
         })

         order.push({
           id: p.id,
           valor: p.ordem
         })
        })
      }      


      setLoading(false);
      setSortData(order);
      setData(products);
    }
    catch(e){

      addToast('Ocorreu um erro para atualizar os dados', {
        appearance: 'error',
        autoDismiss: true,
      });        
    } 
  },[ativo, destaque, category, subcategory, nome, codigo]);

  const handleDelete = useCallback(async (id) => {

    confirmAlert({
      title: 'Confirmação',
      message: 'Tem certeza que deseja remover este registro ?',
      buttons: [
        {
          label: 'Sim',
          onClick: () => postDeleteAction(id)
        },
        {
          label: 'Não'         
        }
      ]
    });

  },[]);

  const postDeleteAction =  useCallback(async (id) => {
      try {
          await api.delete(`/products/${id}`);
          setData(data => data.filter(d => d.id != id));
      }
      catch(e){
        addToast(e.response.data.message, {
          appearance: 'error',
          autoDismiss: true,
        });        
      }
  },[]);


  const addSortInfo = useCallback(async(id, valor) => {

    const sort = [...sortData];    
    const info = sort.find(s => s.id === id);
    if (info){
      info.valor = Number(valor);
    }
    else{
      sort.push({
        id,
        valor: Number(valor)
      });
    }

    setSortData(sort);

 },[sortData, data]);

 const handleChangeOrder = useCallback(async() => {
  try { 
   
    await api.post(`/products/order`, {
      sortData
    });

    getData();
    
  }
  catch(e){
    addToast('Ocorreu um erro', {
      appearance: 'error',
      autoDismiss: true,
    });   

  }
},[sortData]);
  
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card> 
          <CardHeader color="primary">
            <h4 className={classes.cardTitleWhite}>Produtos</h4>
            <p className={classes.cardCategoryWhite}>
              Produtos cadastrados no sistema.
            </p>
          </CardHeader>
          <CardBody> 
        
          <TextField
            variant="outlined"
            margin="normal"       
            fullWidth
            id="nome" 
            label="Pesquise pelo nome do produto"
            name="nome"
            value={nome}
            onChange={(e) => setNome(e.target.value)}        
         />   

         <TextField
            variant="outlined"
            style={{marginTop: 10}}     
            fullWidth
            id="codigo" 
            label="Pesquise pelo código do produto"
            name="codigo"
            value={codigo}
            onChange={(e) => setCodigo(e.target.value)}
         />     

          <Category value={category} handleChangeValue={(value) => setCategory(value)} marginTop={20} />

      
          <FormControl fullWidth variant="outlined" className={classes.formControl} style={{marginTop: 22}}>
            <InputLabel id="category">Ativo</InputLabel>             
              <Select 
                    labelId="ativo"
                    label="ativo no site"
                    variant="outlined"                  
                    name="ativo"
                    fullWidth
                    value={ativo}
                    onChange={(e) => setAtivo(e.target.value)}              
                    >    
                    <MenuItem key="T" value="T">Selecione</MenuItem> 
                    <MenuItem key="A1" value={true}>Sim</MenuItem> 
                    <MenuItem key="A2" value={false}>Não</MenuItem>                                                                 
              </Select>
          </FormControl>

          <FormControl fullWidth variant="outlined" className={classes.formControl} style={{marginTop: 22}}>
            <InputLabel id="category">Destaque</InputLabel>             
              <Select 
                    labelId="destaque no site"
                    label="destaque"
                    variant="outlined"                  
                    name="destaque"
                    fullWidth
                    value={destaque}
                    onChange={(e) => setDestaque(e.target.value)}              
                    >    
                    <MenuItem key="T" value="T">Selecione</MenuItem> 
                    <MenuItem key="D1" value={true}>Sim</MenuItem> 
                    <MenuItem key="D2" value={false}>Não</MenuItem>                                                                 
              </Select>
          </FormControl>

          <Button color="primary" type="button" onClick={getData} style={{marginTop: 20}}>Filtrar</Button>

          <Link to="/admin/products/new"> <Button color="info" style={{marginTop: 20}}>Adicionar novo produto</Button></Link>
 
          <hr />
          <Button color="warning" onClick={() => handleChangeOrder()}>Alterar ordem</Button>
 
          {data && data.length > 0 ? 
            <Table 
                tableHeaderColor="primary"
                tableHead={['Ordem', 'Codigo', 'Tipo', 'Nome', 'Categoria', 'R$ Valor de', 'R$ Valor por', 'Ativo', 'Destaque', 'Estoque', '', '', '']}
                tableColumn={['ordem', 'codigo', 'tipo', 'nome', 'category', 'valor_de', 'valor_por', 'ativo', 'destaque', 'stock', 'foto', 'edit', 'delete']}
                tableColumnSize={['8%', '8%', '5%', '20%', '20%', '15%', '15%', '10%', '5%', '5%', '5%', '1%', '1%', '1%']}
                tableData={data} 
                resource="products"            
                onDelete={(id) => handleDelete(id)}
                onAddPhoto={(id, nome) => history.push(`/admin/products/images/${id}`)}
                handleInputChange={(id, valor) => {
                  const products = [...data];
                  const product = products.find(p => p.id === id);
                  product.ordem = valor;
                  setData(products);
                  addSortInfo(id, valor);
                }}     
            /> : isLoading ? (
              <Box mt={6}>
               <CircularProgress />
              </Box>               
           ) : (           
             <p style={{marginTop: 20}}>Nenhum produto cadastrado no momento.</p>
           )}
          </CardBody>
        </Card>
      </GridItem>

    </GridContainer>
  );
}
