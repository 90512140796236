/*eslint-disable*/
import React, {useEffect, useCallback, useState} from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import FormControl from '@material-ui/core/FormControl';
import Checkbox from '@material-ui/core/Checkbox';
import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';

// core components
import styles from "assets/jss/material-dashboard-react/components/footerStyle.js";

import api from '../../services/api';

const useStyles = makeStyles(styles);

export default function OpcoesRetiradas({ handleSelect, selected }) {

  useEffect(() => {
     loadData();
  },[]); 

  const [data, setData] = useState([]);

  const loadData = useCallback(async() => {
    try {
      const response = await api.get("/orders/pickuplocation"); 
      setData(response.data);
    }
    catch(e){}
  },[]);

  const classes = useStyles();
  return (
    <FormControl fullWidth variant="outlined" className={classes.formControl}>
       <GridContainer>     
        {data && data.map(loc => {

          let existe = selected.findIndex(pl => pl === loc.id);
             
          return (
            <GridItem xs={6} sm={6} md={3} key={loc.id}>
              <Checkbox name={loc.name} checked={existe!==-1} onChange={(event) => handleSelect(loc.id, event)} />{loc.name}
            </GridItem>
          )
        })}     

        </GridContainer> 
 
   </FormControl>
  );
}
