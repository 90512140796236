/*eslint-disable*/
import React, {useEffect, useCallback, useState} from "react";
import PropTypes from "prop-types";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';

// core components
import styles from "assets/jss/material-dashboard-react/components/footerStyle.js";

import api from '../../services/api';
import EditCategory from "pages/Categories/edit";

const useStyles = makeStyles(styles);

export default function Modopreparo({value, handleChangeValue, marginTop}) {

  useEffect(() => {
       loadData();
  },[]); 

  const [modopreparo, setModoPreparo] = useState([]);

  const loadData = useCallback(async() => {
    try {
      const response = await api.get("/modopreparo");
      setModoPreparo(response.data);
    }
    catch(e){

    }
  },[]);

  const classes = useStyles();
  return (
    <FormControl fullWidth variant="outlined" className={classes.formControl} style={{marginTop: marginTop}}>
    <InputLabel id="modopreparo">Modo Preparo</InputLabel> 
         
       <Select 
            labelId="modopreparo"
            label="modopreparo"
            variant="outlined"                  
            name="modopreparo"
            fullWidth
            value={value ? value : 'T'}
            onChange={(e) => handleChangeValue(e.target.value)}              
            >    
            <MenuItem key="T" value="T">Selecione</MenuItem>                                   
            {modopreparo.map(modopreparo => {
               return <MenuItem key={modopreparo.id} value={modopreparo}>{modopreparo.name}</MenuItem>        
            })}                               
      </Select>
   </FormControl>
  );
}
