import React, { useState, useCallback } from 'react';


import Button from 'components/CustomButtons/Button.js';

import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import GridItem from 'components/Grid/GridItem.js';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import GridContainer from 'components/Grid/GridContainer.js';
import InputMask from "react-input-mask";

export default function Endereco({ setValue, register, cep, setCep }) {

    const [estado, setEstado] = useState('RJ');

    const getCep = useCallback(async (cep) => {
        try {
            if (cep != '') {
                const response = fetch(`https://viacep.com.br/ws/${cep}/json/`)
                    .then(response => response.json())
                    .then(data => {

                        if (data) {
                            setValue('cep', data.cep);
                            setValue('cidade', data.localidade);
                            setValue('bairro', data.bairro);
                            setEstado(data.uf);
                            setValue('endereco', data.logradouro);
                        }
                    })
            }
        }
        catch (e) { }
    }, []);

    return (
        <>
            <br />
            <h4 className="mb-3">Endereço para entrega</h4>

            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <TextField
                        variant="outlined"
                        fullWidth
                        placeholder="Identificação (ex: trabalho, casa)"
                        name="identificacao"
                        id="identificacao"
                        className="form-control"
                        required
                        {...register(`identificacao`)}
                    />
                </GridItem>
            </GridContainer>
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <TextField
                        style={{ marginTop: 25 }}
                        variant="outlined"
                        margin="normal"
                        InputLabelProps={{ shrink: true }}
                        id="cep"
                        name="cep"
                        label="CEP"
                        className="form-control"
                        fullWidth
                        {...register(`cep`)}
                        onChange={(e) => setCep(e.target.value)}
                        onBlur={(e) => getCep(e.target.value)}
                        InputProps={{
                            inputComponent: InputMask,
                            inputProps: {
                                mask: '99999-999'
                            }
                        }}
                    />


                </GridItem>
            </GridContainer>

            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <TextField
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        InputLabelProps={{ shrink: true }}
                        label="Endereço"
                        name="endereco"
                        id="endereco"
                        className="form-control"
                        required
                        {...register(`endereco`)}
                    />
                </GridItem>
            </GridContainer>

            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <TextField
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        InputLabelProps={{ shrink: true }}
                        label="Numero"
                        name="numero"
                        id="numero"
                        className="form-control"
                        required
                        {...register(`numero`)}
                    />
                </GridItem>
            </GridContainer>

            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <TextField
                        variant="outlined"
                        fullWidth
                        label="Complemento"
                        margin="normal"
                        InputLabelProps={{ shrink: true }}
                        name="complemento"
                        id="complemento"
                        className="form-control"
                        {...register(`complemento`)}
                    />
                </GridItem>
            </GridContainer>

            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <TextField
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        InputLabelProps={{ shrink: true }}
                        label="Bairro"
                        name="bairro"
                        id="bairro"
                        className="form-control"
                        required
                        {...register(`bairro`)}
                    />
                </GridItem>
            </GridContainer>

            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <TextField
                        variant="outlined"
                        fullWidth
                        label="Cidade"
                        margin="normal"
                        InputLabelProps={{ shrink: true }}
                        name="cidade"
                        id="cidade"
                        className="form-control"
                        required
                        {...register(`cidade`)}
                    />
                </GridItem>
            </GridContainer>
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <FormControl fullWidth variant="outlined" style={{ marginTop: 20 }}>
                        <InputLabel id="type">Estado</InputLabel>
                        <Select
                            labelId="estado"
                            label="Estado"
                            variant="outlined"
                            name="estado"
                            fullWidth
                            value={estado}
                            onChange={(e) => setEstado(e.target.value)}
                        >
                            <MenuItem value="AC">Acre</MenuItem>
                            <MenuItem value="AL">Alagoas</MenuItem>
                            <MenuItem value="AP">Amapá</MenuItem>
                            <MenuItem value="AM">Amazonas</MenuItem>
                            <MenuItem value="BA">Bahia</MenuItem>
                            <MenuItem value="CE">Ceará</MenuItem>
                            <MenuItem value="DF">Distrito Federal</MenuItem>
                            <MenuItem value="ES">Espírito Santo</MenuItem>
                            <MenuItem value="GO">Goiás</MenuItem>
                            <MenuItem value="MA">Maranhão</MenuItem>
                            <MenuItem value="MT">Mato Grosso</MenuItem>
                            <MenuItem value="MS">Mato Grosso do Sul</MenuItem>
                            <MenuItem value="MG">Minas Gerais</MenuItem>
                            <MenuItem value="PA">Pará</MenuItem>
                            <MenuItem value="PB">Paraíba</MenuItem>
                            <MenuItem value="PR">Paraná</MenuItem>
                            <MenuItem value="PE">Pernambuco</MenuItem>
                            <MenuItem value="PI">Piauí</MenuItem>
                            <MenuItem value="RJ">Rio de Janeiro</MenuItem>
                            <MenuItem value="RN">Rio Grande do Norte</MenuItem>
                            <MenuItem value="RS">Rio Grande do Sul</MenuItem>
                            <MenuItem value="RO">Rondônia</MenuItem>
                            <MenuItem value="RR">Roraima</MenuItem>
                            <MenuItem value="SC">Santa Catarina</MenuItem>
                            <MenuItem value="SP">São Paulo</MenuItem>
                            <MenuItem value="SE">Sergipe</MenuItem>
                            <MenuItem value="TO">Tocantins</MenuItem>
                        </Select>
                    </FormControl>

                    <Button color="warning" type="submit" style={{ marginTop: 20 }}>Avançar</Button>


                </GridItem>
            </GridContainer>



        </>
    )
}
