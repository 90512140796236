/*eslint-disable*/
import React, {useEffect, useCallback, useState} from "react";
import PropTypes from "prop-types";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';

// core components
import styles from "assets/jss/material-dashboard-react/components/footerStyle.js";

import api from '../../services/api';

const useStyles = makeStyles(styles);

export default function State({value, handleChangeValue, marginTop}) {

  useEffect(() => {
       loadStates();
  },[]); 

  const [state, setState] = useState([]);

  const loadStates = useCallback(async() => {
    try {
      const response = await api.get("/shipping/state");
      setState(response.data);
    }
    catch(e){

    }
  },[]);

  const classes = useStyles();
  return (
    <FormControl fullWidth variant="outlined" className={classes.formControl} style={{marginTop: marginTop}}>
    <InputLabel id="state">Estado</InputLabel> 
         
       <Select 
            labelId="state"
            label="estado"
            variant="outlined"                  
            name="state"
            fullWidth
            value={value ? value : ''}
            onChange={(e) => handleChangeValue(e.target.value)}              
            >   
            <MenuItem key="T" value="T">Selecione</MenuItem>                    
            {state.map(st => {
               return <MenuItem key={st.id} value={st.id}>{st.name}</MenuItem>        
            })}                               
      </Select>
   </FormControl>
  );
}
