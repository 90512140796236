import React, {useState, useEffect, useCallback} from 'react';
import Chart from "react-apexcharts";
import { useToasts } from 'react-toast-notifications'

import { CircularProgress } from '@material-ui/core';
import Box from '@material-ui/core/Box';

// @material-ui/core
import { makeStyles } from '@material-ui/core/styles';
import Icon from '@material-ui/core/Icon';

import Store from '@material-ui/icons/Store';
import DateRange from '@material-ui/icons/DateRange';

import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import Table from 'components/Table/Table.js';
import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import CardIcon from 'components/Card/CardIcon';
import CardBody from 'components/Card/CardBody';
import CardFooter from 'components/Card/CardFooter';

import * as moment from 'moment'
import api from '../../services/api';

import styles from 'assets/jss/material-dashboard-react/views/dashboardStyle.js';

const useStyles = makeStyles(styles);

const months = [];
let i = 0;
let date;

for (i=0; i<12; i++){
  date = moment().subtract(i, 'month').format("MM/YYYY");  
  months.push(`${date}`);
}

const options = {
  chart: {
      toolbar: {
          show: false
      },
      zoom: {
          enabled: false
      },
      foreColor: '#FFFFFF'
  },
  grid: {
      show: true
  },
  dataLabels: {
      enabled: true
  },
  tooltip: {
    enabled: true
  },
  xaxis: {
      type: 'string',
      axisBorder: {
          color: '#000000'
      },
      axisTicks: {
        color: '#FFAAAA'
      },
      categories: months
  },
  fill: {
      opacity: 0.2,
      type: 'gradient',
      gradient: {
          shade: 'dark',
          opacityFrom: 0.7,
          opacityTo: 0.3
      }
  }
}



export default function Dashboard() {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [chartData, setChartData] = useState([{ name: 'Vendas', data: []}]);
  const [salesMonth, setSalesMonth] = useState();
  const [totalProducts, setTotalProducts] = useState(0);
  const [totalCustomers, setTotalCustomers] = useState(0);
  const [totalMonthSalesAmount, setTotalMonthSalesAmount] = useState(0);
  const [totalMonthSalesTotal, setTotalMonthSalesTotal] = useState(0);
  const [orders, setOrders] = useState();
  const { addToast } = useToasts();

  useEffect(() => {    
    getData();
    getLastOrders();
  }, []);

  const getData = useCallback(async () => {
    try {
      const response = await api.get(`/dashboard`);
      setTotalProducts(response.data.totalProducts);
      setTotalCustomers(response.data.totalCustomers);
      setTotalMonthSalesAmount(new Intl.NumberFormat('pt-BR', { 
        style: 'currency', 
        currency: 'BRL', 
      }).format(response.data.totalMonthSalesAmount));
      setTotalMonthSalesTotal(response.data.totalMonthSalesTotal);

      const salesByMonth = response.data.salesMonth;

      const sales = [];
      let date;
      let i=0;
      let valor = 0;
      let month = 0;
      let year = 0;

      for (i=0; i<12; i++){
        date = moment().subtract(i, 'month').format("MM/YYYY");
        month = moment().subtract(i, 'month').format("M");
        year = moment().subtract(i, 'month').format("YYYY");

        const info = salesByMonth.find(v => v.mes === Number(month) && v.ano === Number(year));
        sales.push(info ? Number(info.total) : 0);
      }

      setChartData([{ name: 'Vendas', data: sales}]);

    }
    catch(e){

      addToast('Ocorreu um erro para atualizar os dados', {
        appearance: 'error',
        autoDismiss: true,
      });        
    } 
  },[]);

 const getLastOrders = useCallback(async () => {
    try {
      setLoading(true);

      const orders = [];
      const response = await api.post("/orders/search",{ 
        filtros: {
          ultimos: 1,
          ordem: 'mais_recentes'
        }
      });

      response.data.map(o => {
        orders.push({
          id: o.id,
          cliente: o.customer.name,
          status: o.status.name,
          formapg: o.paymentform.name,
          total: new Intl.NumberFormat('pt-BR', { 
            style: 'currency', 
            currency: 'BRL', 
          }).format(o.total)
        })
      })
      
      setOrders(orders);
      setLoading(false);
    }
    catch(e){  
       setLoading(false);
    } 
  },[]);

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="warning" stats icon>
              <CardIcon color="warning">
                <Icon>person</Icon>
              </CardIcon>
              <p className={classes.cardCategory}>Clientes</p>
              <h3 className={classes.cardTitle}>
                {totalCustomers}
                {' '}                
              </h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
               
           
               Total clientes cadastrados.
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="warning" stats icon>
              <CardIcon color="warning">
                <Icon>storefront</Icon>
              </CardIcon>
              <p className={classes.cardCategory}>Produtos</p>
              <h3 className={classes.cardTitle}>
                {totalProducts}
                {' '}                
              </h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>             
                 Total de produtos
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="success" stats icon>
              <CardIcon color="success">
                <Store />
              </CardIcon>
              <p className={classes.cardCategory}>Vendas - mes atual</p>
              <h3 className={classes.cardTitle}>{totalMonthSalesAmount}</h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <DateRange />
                Mês atual
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="danger" stats icon>
              <CardIcon color="danger">
                <Icon>receipt</Icon>
              </CardIcon>
              <p className={classes.cardCategory}>Pedidos - mes atual</p>
              <h3 className={classes.cardTitle}>{totalMonthSalesTotal}</h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
              <DateRange />
                Mes atual
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        
      </GridContainer>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card chart>
            
            {!loading && chartData ? <Chart type="area" height={260} options={options} series={chartData} /> : null}
           
            <CardBody>
              <h4 className={classes.cardTitle}>Vendas últimos 12 meses</h4>
             
            </CardBody>
          
          </Card>
        </GridItem>
     
      </GridContainer>

      <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="primary">
            <h4 className={classes.cardTitleWhite}>Últimos 10 pedidos realizados no site</h4>
           
          </CardHeader>
          <CardBody>
            {loading ? (
            <Box mt={6}>
             <CircularProgress />
            </Box>               
            ) : ( 
             <>     
              {orders && orders.length > 0 ? <Table
                  tableHeaderColor="primary"
                  tableHead={['No pedido', 'Cliente', 'Status', 'Forma Pg', 'Total', '']}
                  tableColumn={['id', 'cliente', 'status', 'formapg', 'total', 'edit']}
                  tableColumnSize={['10%', '30%', '20%', '20%', '20%', '20%', '1%']}
                  tableData={orders} 
                  resource="orders"            
                  onDelete={(id) => handleDelete(id)}              
              /> : <p style={{marginTop: 20}}>Nenhum pedido realizado no momento.</p>}
             </>
            )}
          </CardBody>
        </Card>
      </GridItem>
     
    </GridContainer>

    </div>
  );
}
