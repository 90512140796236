/*eslint-disable*/
import React, {useEffect, useCallback, useState} from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";


import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputLabel from '@material-ui/core/InputLabel';
import Checkbox from '@material-ui/core/Checkbox';

// core components
import styles from "assets/jss/material-dashboard-react/components/footerStyle.js";

import api from '../../services/api';

const useStyles = makeStyles(styles);

export default function MultipleSelection({resource, marginTop, label, handleChange, selected, filtros}) {

  useEffect(() => {
      loadData();
  },[filtros]); 

  const [opcoes, setOpcoes] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleClick = useCallback(async(data, event) => {
    handleChange(data.id, event.target.checked);
  },[]);

  const loadData = useCallback(async() => {
    try {
      

      let response = null;
      setLoading(true);

      setOpcoes([]);

      if (filtros){
          response = await api.post(`/${resource}`, {
            filtros
          });
      }
      else{     
        response = await api.get(`/${resource}`);
      }

      setLoading(false);
      setOpcoes(response.data);
    }
    catch(e){
      setLoading(false);

    }
  },[filtros]);

  const classes = useStyles();

  return (
    <FormControl fullWidth variant="outlined" className={classes.formControl} style={{marginTop: marginTop}}>
        <FormLabel component="legend">{label}:</FormLabel>
        <FormGroup>
        {opcoes.length > 0 && opcoes.map(data => {
          return (        
              <FormControlLabel key={data.id} control={<Checkbox checked={selected.includes(data.id)} 
                onChange={(event) => handleClick(data, event)} name={data.name} />} 
                label={data.name} />            
          )
        })}

        {!loading && opcoes.length === 0 ? <span>Nenhum resultado encontrado.</span> : null}
        </FormGroup> 
    </FormControl>
  );
}
