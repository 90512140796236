import React, {useEffect, useCallback, useState, useRef} from 'react';
import * as Yup from 'yup';

import { useToasts } from 'react-toast-notifications'
import { Link, useParams, useHistory } from 'react-router-dom';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import { CircularProgress } from '@material-ui/core';
import Box from '@material-ui/core/Box';

import Editor from 'ckeditor5-custom-build/build/ckeditor';
import { CKEditor } from '@ckeditor/ckeditor5-react'

const editorConfiguration = { 
    toolbar: [ 'bold', 'italic', 'fontFamily', 'fontSize', 'fontColor', 'fontBackgroundColor',  'blockquote', 'link' ] 
}; 

// core components
import styles from 'assets/jss/material-dashboard-react/views/dashboardStyle.js';

import Checkbox from '@material-ui/core/Checkbox';
import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import TextField from '@material-ui/core/TextField';
import Button from 'components/CustomButtons/Button.js';
import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardBody from 'components/Card/CardBody.js';
import CardFooter from 'components/Card/CardFooter.js';
import FileUploader from 'components/FileUploader/FileUploader';


import api from '../../services/api';
import getValidationErrors from '../../utils/getValidationErrors';

const useStyles = makeStyles(styles);

export default function EditNoticiasPage() {

  const [action, setAction] = useState('create');
  const [loading, setLoading] = useState(false);
  const [titulo, setTitulo] = useState('');
  const [descricao, setDescricao] = useState('');
  const [removerImagem, setRemoverImagem] = useState(false);
  const [imagem, setImagem] = useState('');
  const [selectedFile, setSelectedFile] = useState('');

  const [data, setData] = useState();
  const formRef = useRef(null);
  const history = useHistory();
  const { addToast } = useToasts()

  const { id } = useParams();   
  const classes = useStyles();

  useEffect(() => {
    if (id != "new"){
      setAction("update");
    }
  },[]);
  
  useEffect(() => {
    if (action === "update"){
      loadData(id);
    }
  },[action]);

  const loadData = useCallback(async () => {    
    try {
      setLoading(true);
      const response = await api.get(`/noticias/${id}`);
      setTitulo(response.data.titulo);
      setDescricao(response.data.descricao);    
      setImagem(response.data.imagem);     
      setLoading(false);     
    }
    catch(e){
      addToast(e.response.data.message ? e.response.data.message : 'Ocorreu um erro !', {
        appearance: 'error',
        autoDismiss: true,
      });      
      history.push("/admin/blog");
    }
  });

  const handleSave = useCallback(async (e) => {

    try { 

      e.preventDefault();    

    
      const schema = Yup.object().shape({
        titulo: Yup.string().required('Titulo é obrigatório !'), 
        descricao: Yup.string().required('Texto é obrigatório !'),              
      });         

      await schema.validate({titulo, descricao}, {
        abortEarly: false,
      });

      const formData = new FormData();
      formData.append('titulo', titulo);
      formData.append('descricao', descricao);

      if (selectedFile){
          formData.append('imagem', selectedFile);
      }
 
      if (removerImagem){
          formData.append('removerImagem', removerImagem);
      }

      if (action === 'update'){
          formData.append('id', id);
      }

 
 
      await api.post('/noticias', formData);
  
      history.push('/admin/blog');
   
    }
    catch(e){ 

      if (e instanceof Yup.ValidationError) {
        const errors = getValidationErrors(e); 

        for (var [, value] of Object.entries(errors)) {
          addToast(value, {
            appearance: 'error',
            autoDismiss: true,
          });
        }
        return;
      }    
      
      addToast(e.response ? e.response.data.message : 'Ocorreu um erro !', {
        appearance: 'error',
        autoDismiss: true,
      });
            
    }
  },[id, action, selectedFile, removerImagem, titulo, descricao]);


  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>         
          <form className={classes.form} ref={formRef} onSubmit={handleSave}>
            <CardHeader color="primary">
              <h4 className={classes.cardTitleWhite}>Dados da noticia</h4>            
            </CardHeader>
            <CardBody>

                {loading ? (
                  <Box mt={6}>
                    <CircularProgress />
                  </Box>               
                ) : (      
                    <>    
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={12}>
                      <TextField
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            id="titulo"
                            label="Titulo"
                            name="titulo" 
                            value={titulo}   
                            onChange={(e) => setTitulo(e.target.value)}                                            
                            autoFocus
                        />
                      </GridItem>               
                    </GridContainer> 

                    {!loading ? <CKEditor
                            editor={ Editor }
                            config={ editorConfiguration }
                            data={descricao}
                            onReady={(editor) => {       
                              editor.editing.view.change((writer) => {
                              writer.setStyle(
                                  "height",
                                  "400px",
                                  editor.editing.view.document.getRoot()
                              );
                              });
                            }}    
                            onChange={ ( event, editor ) => {
                              const data = editor.getData();                      
                              setDescricao(data);
                          } }           
                      /> : null}    


                       <p>Imagem</p>  
                        <FileUploader
                          onFileSelectSuccess={(file) => { 
                                  setSelectedFile(file);                     
                                  setImagem(null); 
                                  setRemoverImagem(false);
                          }}
                          onFileSelectError={({ error }) => addToast(error, {
                            appearance: 'error',
                            autoDismiss: true,
                          })}
                       />

                     {imagem ?
                      <>     
                       <hr />
                          <img src={`${process.env.REACT_APP_IMAGENS_URL_PREFIX}/noticias/rs/${imagem}`} width="250" height="250" />      
                        <hr />
                      </>:  null}

                      {imagem ? <><Checkbox checked={removerImagem} onChange={(event) => setRemoverImagem(!removerImagem)} name="remover" />Remover imagem</> : null}

            
                   </>                                
                  )}
                                
            </CardBody>

            <CardFooter>
              {!loading ? <Button color="primary" type="submit">Salvar</Button> : <Box mt={6}><CircularProgress /></Box>}
             
            </CardFooter>
           </form>

          
          </Card>
        </GridItem>
        
      </GridContainer>
    </div>
  );
}
