
export default function EnderecosRetirada({ enderecos, handleSelecionaEndereco }) {

    return (
        <>
            {enderecos && enderecos.length > 0 ? <div>
                <br />
                <h5>Enderecos de retirada disponíveis:</h5>

                {enderecos.map(a => (

                    <a href="#" onClick={() => handleSelecionaEndereco(a)}>
                        <address>
                            <strong>{a.name}</strong> - {a.endereco}, {a.numero} {a.complemento} {a.bairro} {a.cidade} / {a.estado}
                        </address>
                    </a>
                ))}

            </div> : null}
        </>
    )
}