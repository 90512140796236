import React, { useState, useEffect, useCallback } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { useToasts } from 'react-toast-notifications'
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'assets/css/react-confirm-alert.css'; // Import css
import { CircularProgress } from '@material-ui/core';
import Box from '@material-ui/core/Box';


import styles from 'assets/jss/material-dashboard-react/views/dashboardStyle.js';
import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import Table from 'components/Table/Table.js';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';
import Card from 'components/Card/Card';
import Button from 'components/CustomButtons/Button.js';
import State from 'components/State/State.js';
import City from 'components/City/City.js';

import api from '../../services/api';

const useStyles = makeStyles(styles);

export default function Neighborhoods() {

  const classes = useStyles();
  const [isLoading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [state, setState] = useState();
  const [city, setCity] = useState();
  const [filtros, setFiltros] = useState();
  const { addToast } = useToasts()

  useEffect(() => {
      getData();
  }, []);

  useEffect(() => { 
    setFiltros({
      city,
      state
    })

  },[city, state])


  const getData = useCallback(async () => {
    try {
      setLoading(true); 

      const neighborhoods = [];

      const response = await api.post("/shipping/neighborhood/search", {
        filtros: {
          city: filtros && filtros.city !== 'T' ? filtros.city : '',
          state: filtros && filtros.state !== 'T' ? filtros.state : '',
        }
      });
      response && response.data.map(i => {
        neighborhoods.push({
          id: i.id,
          state: i.state.name,
          city: i.city.name,
          name: i.name
        })
      })

      setData(neighborhoods);
      setLoading(false);
    }
    catch(e){
      addToast('Ocorreu um erro para atualizar os dados', {
        appearance: 'error',
        autoDismiss: true,
      });   
      setLoading(false);     
    } 
  },[filtros]);

  const handleDelete = useCallback(async (id) => {

    confirmAlert({
      title: 'Confirmação',
      message: 'Tem certeza que deseja remover este registro ?',
      buttons: [
        {
          label: 'Sim',
          onClick: () => postDeleteAction(id)
        },
        {
          label: 'Não'         
        }
      ]
    });

  },[]);

  const postDeleteAction =  useCallback(async (id) => {
      try {     
          await api.delete(`/shipping/neighborhood/${id}`);        
          setData(data => data.filter(d => d.id != id));
      }
      catch(e){
        addToast(e.response.data.message, {
          appearance: 'error',
          autoDismiss: true,
        });        
      }
  },[]);

  
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card> 
          <CardHeader color="primary">
            <h4 className={classes.cardTitleWhite}>Bairros</h4>
            <p className={classes.cardCategoryWhite}>
              Lista de bairros cadastrados no sistema.
            </p>
          </CardHeader>
          <CardBody>  

          <State value={state} handleChangeValue={(value) => setState(value)} marginTop={20} />
          <City value={city} state={state} handleChangeValue={(value) => setCity(value)} marginTop={20} />

          <Button color="primary" type="button" onClick={getData} style={{marginTop: 20}}>Filtrar</Button>

          <br /><br />

          <Link to="/admin/neighborhoods/new"> <Button color="info">Adicionar novo bairro</Button></Link>
 
          {data && data.length > 0 ? <Table
              tableHeaderColor="primary"
              tableHead={['Estado', 'Cidade', 'Nome', '', '']}
              tableColumn={['state', 'city', 'name',  'edit', 'delete']}
              tableColumnSize={['20%', '20%', '50%', '1%', '1%']}
              tableData={data} 
              resource="neighborhoods"            
              onDelete={(id) => handleDelete(id)}             
           
           /> : isLoading ? (
            <Box mt={6}>
             <CircularProgress />
            </Box>               
          ) : (           
            <p style={{marginTop: 20}}>Nenhum bairro cadastrada no momento.</p>
          )}
          </CardBody>
        </Card>
      </GridItem>

    </GridContainer>
  );
}
