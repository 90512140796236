import 'date-fns';
import ptBRLocale from "date-fns/locale/pt-BR";
import * as moment from 'moment'
import React, { useState, useEffect, useCallback } from 'react';
import { setCookie, parseCookies } from 'nookies'
import DateFnsUtils from '@date-io/date-fns';
import {
  DatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';

import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';

import { makeStyles } from '@material-ui/core/styles';
import 'assets/css/react-confirm-alert.css'; // Import css

import styles from 'assets/jss/material-dashboard-react/views/dashboardStyle.js';
import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';
import Card from 'components/Card/Card';
import Button from 'components/CustomButtons/Button.js';

const useStyles = makeStyles(styles);

export default function RelatorioProdutosVendidos() {

  let cookies = parseCookies();
  const classes = useStyles();
  const [loading, setLoading] = useState();

  const [tipofiltro, setTipoFiltro] = useState('P');
  const [dataInicial, setDataInicial] = useState(new Date(new Date() - (30 * 86400000)));
  const [dataFinal, setDataFinal] = useState(new Date());

  const handleDataInicialChange = (date) => {
    setDataInicial(date);
  };

  const handleDataFinalChange = (date) => {
    setDataFinal(date);
  };

  const getData = () => {
    window.open(`${process.env.REACT_APP_API_URL}/relatorio/produtosVendidos?dataInicial=${moment(dataInicial).format("YYYY-MM-DD")}&dataFinal=${moment(dataFinal).format("YYYY-MM-DD")}&tipofiltro=${tipofiltro}&a=${cookies['equilibrae.token']}`, '_blank');
  };

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="primary">
            <h4 className={classes.cardTitleWhite}>Relatório - Produtos X Vendidos</h4>
          </CardHeader>

          <CardBody>

            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBRLocale}>
              <DatePicker style={{ marginTop: 15 }} label="Periodo inicial" value={dataInicial} onChange={handleDataInicialChange} format="dd/MM/yyyy" inputVariant="outlined" fullWidth />
            </MuiPickersUtilsProvider>

            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBRLocale}>
              <DatePicker style={{ marginTop: 25 }} label="Periodo final" value={dataFinal} onChange={handleDataFinalChange} format="dd/MM/yyyy" inputVariant="outlined" fullWidth />
            </MuiPickersUtilsProvider>

            <FormControl fullWidth variant="outlined" className={classes.formControl} style={{ marginTop: 28 }}>
              <InputLabel id="category">Tipo Filtro?</InputLabel>
              <Select labelId="tipofiltro"
                label="Tipo Filtro"
                variant="outlined"
                name="tipofiltro"
                fullWidth
                value={tipofiltro}
                onChange={(e) => setTipoFiltro(e.target.value)}
              >
                <MenuItem key="P" value="P">Data Pedido</MenuItem>
                <MenuItem key="E" value="E">Data Entrega</MenuItem>
              </Select>
            </FormControl>

            <Button color="primary" type="button" style={{ marginTop: 20 }} onClick={getData}>Gerar</Button>


          </CardBody>
        </Card>
      </GridItem>

    </GridContainer>
  );
}
