import React, {useEffect, useCallback, useState, useRef} from 'react';
import * as Yup from 'yup';
import { useToasts } from 'react-toast-notifications'
import { useParams, useHistory } from 'react-router-dom';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';

import Editor from 'ckeditor5-custom-build/build/ckeditor';
import { CKEditor } from '@ckeditor/ckeditor5-react'

const editorConfiguration = { 
  toolbar: [ 'bold', 'italic', 'fontFamily', 'fontSize', 'fontColor', 'fontBackgroundColor',  'blockquote', 'link' ] 
}; 

// core components
import styles from 'assets/jss/material-dashboard-react/views/dashboardStyle.js';
import Checkbox from '@material-ui/core/Checkbox';
import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import TextField from '@material-ui/core/TextField';
import Button from 'components/CustomButtons/Button.js';
import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardBody from 'components/Card/CardBody.js';
import CardFooter from 'components/Card/CardFooter.js';
import FileUploader from 'components/FileUploader/FileUploader';


import api from '../../services/api';
import getValidationErrors from '../../utils/getValidationErrors';

const useStyles = makeStyles(styles);

export default function EditSubcategory() {
  const [action, setAction] = useState('create');
  const [name, setName] = useState('');
  const [imagem, setImagem] = useState('');
  const [descricao, setDescricao] = useState('');
  const [selectedFile, setSelectedFile] = useState('');
  const [removerImagem, setRemoverImagem] = useState(false);
  const formRef = useRef(null);
  const history = useHistory();
  const { addToast } = useToasts()

  const { id } = useParams();   
  const classes = useStyles();

  useEffect(() => {



    if (id != "new"){
      setAction("update");
    }
  },[]);

  useEffect(() => {
    if (action === "update"){
      loadData(id);
    }
  },[action]);

  const handleSave = useCallback(async (e) => {

    try { 

      e.preventDefault();
     
      const name = e.target.name.value;
 
      const schema = Yup.object().shape({
        name: Yup.string().required('Nome é obrigatório !'),                    
      });   
           
      await schema.validate({name}, {
        abortEarly: false,
      });

      const formData = new FormData();
      formData.append('name', name);
      formData.append('descricao', descricao);

      if (selectedFile){
         formData.append('image_01', selectedFile);
      }
  
      if (removerImagem){
          formData.append('removerImagem', removerImagem);
      }

      if (action === 'update'){
          formData.append('id', id);
      }
     
      await api.post("/modopreparo", formData);

      history.push('/admin/modopreparo');
   
    }
    catch(e){ 

      setLoading(false);
      if (e instanceof Yup.ValidationError) {
        const errors = getValidationErrors(e); 

        for (var [, value] of Object.entries(errors)) {
          addToast(value, {
            appearance: 'error',
            autoDismiss: true,
          });
        }
        return;
      }    
            
      addToast(e.response.data.message ? e.response.data.message : 'Ocorreu um erro !', {
        appearance: 'error',
        autoDismiss: true,
      });     
  
    }
  },[action, name, descricao, selectedFile, removerImagem]);

  const loadData = useCallback(async () => {    
    try {

      const response = await api.get(`/modopreparo/${id}`);
      
      setName(response.data.name);    
      setImagem(response.data.image);
      if (response.data.descricao){
      setDescricao(response.data.descricao);
      }

    }
    catch(e){
 
      addToast(e.response.data.message ? e.response.data.message : 'Ocorreu um erro !', {
        appearance: 'error',
        autoDismiss: true,
      });       
      
      history.push("/admin/modopreparo");
    }
  });

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
          <form className={classes.form} ref={formRef} onSubmit={handleSave}>
            <CardHeader color="primary">
              <h4 className={classes.cardTitleWhite}>Dados do Modo de Preparo</h4>
              <p className={classes.cardCategoryWhite}>Complete os dados.</p>
            </CardHeader>
            <CardBody>
           
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>  
              
                  <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      id="name" 
                      label="Nome"
                      name="name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      autoFocus
                  />  

                  <CKEditor
                      editor={ Editor }
                      config={ editorConfiguration }
                      data={descricao}
                      onReady={(editor) => {       
                        editor.editing.view.change((writer) => {
                        writer.setStyle(
                            "height",
                            "400px",
                            editor.editing.view.document.getRoot()
                        );
                        });
                      }}    
                      onChange={ ( event, editor ) => {
                        const data = editor.getData();                      
                        setDescricao(data);
                    } }           
                />      


                  {imagem ?
                  <>     
                    <hr />
                    <img src={`${process.env.REACT_APP_IMAGENS_URL_PREFIX}/modopreparo/th/${imagem}`} width="50" height="50" />          
                    <hr />
                  </>:  null}

        
                  {imagem ? <><Checkbox checked={removerImagem} onChange={(event) => setRemoverImagem(!removerImagem)} name="remover" />Remover imagens</> : null}

                    <p>Imagem 01</p>  
                    <FileUploader
                      onFileSelectSuccess={(file) => { 
                              setSelectedFile(file);                     
                              setImagem(null); 
                              setRemoverImagem(false);
                      }}
                      onFileSelectError={({ error }) => addToast(error, {
                        appearance: 'error',
                        autoDismiss: true,
                      })}
                    />

                   
                </GridItem>               
              </GridContainer>
             
            </CardBody>
            <CardFooter>
              <Button color="primary" type="submit">Salvar</Button>
            </CardFooter>
          </form>
          </Card>
        </GridItem>
        
      </GridContainer>
    </div>
  );
}
