/*eslint-disable*/
import React, {useEffect, useCallback, useState, useRef} from "react";
import Select from 'react-select';
import api from '../../services/api';

export default function AutoComplete({resource, placeholder, handleChange, myRef}) {

  const [data, setData] = useState();
    
  useEffect(() => {
       loadData();
  },[]); 

  const autocompleteStyle = {
    control: base => ({
      ...base,
      height: 52,
      minHeight: 52,
    })
  };


  const loadData = useCallback(async() => {

    try {
      const products = [];
      const response = await api.post(`/${resource}`);
      response.data.map(p => {
          products.push({
              id: p.id,
              label: `${p.codigo} - ${p.nome}`
          })
      })
      setData(products);
    }
    catch(e){}
  },[]);

  return (
    <Select ref={myRef} menuPortalTarget={document.body}
    menuPosition={'fixed'}  noOptionsMessage={() => 'Nenhum produto encontrado'}  placeholder={placeholder} onChange={(e) => { handleChange(e); }} styles={autocompleteStyle} cacheOptions defaultOptions options={data} />
  );
}
